<template>
  <v-container
    class="rounded-lg"
    :style="
      paymentField === true
        ? 'max-height: 90vh; overflow-y: scroll !important'
        : 'max-height: 90vh; overflow-y: scroll !important;'
    "
  >
    <!-- Invoices Details -->
    <!-- Invoices get from draft -->
    <div v-if="editableForm">
      <div
        v-for="(invoiceItem, index) in dataDraft?.invoices"
        :key="index + 'A'"
      >
        <!-- Top Form Header Fields -->
        <v-row class="ma-0 pa-0 rounded-lg">
          <v-col class="ma-0 pa-0" cols="7">
            <v-row class="ma-0 pa-0">
              <v-col
                class="ma-0 pa-0 px-1"
                v-for="(item, index) in topHeaderData"
                :key="index + 'B'"
                :cols="item.cols"
              >
                <div class="mt-2">
                  <span class="fs-12 f-inter fw-600 my-1">{{
                    item.label
                  }}</span>

                  <!-- v-model="selectedItem" -->
                  <!-- <span v-if="item.model == 'name'">{{ invoiceItem[item.model] }}</span> -->
                  <v-combobox
                    v-if="item.model == 'name'"
                    :placeholder="item.placeholder"
                    :disabled="
                      item.disabled
                        ? true
                        : globalDisableFieldsValue
                        ? true
                        : currentWorkspace.rewriteInvoice
                        ? true
                        : false
                    "
                    @update:search-input="setContactName"
                    v-model="invoiceItem[item.model]"
                    filled
                    flat
                    persistent-placeholder
                    class="text-no-wrap rounded-md fs-12"
                    background-color="white"
                    hide-details
                    dense
                    depressed
                    item-text="name"
                    item-value="name"
                    :items="nameItems"
                    :loading="nameLoading"
                  >
                    <template v-slot:append-item>
                      <div
                        v-if="showLoadMoreInContacts.key == true"
                        class="d-flex justify-center align-center"
                      >
                        <v-btn
                          text
                          color="primary"
                          @click="getContactsLoadMore(true)"
                        >
                          Load More ...
                        </v-btn>
                      </div>
                    </template>
                  </v-combobox>
                  <v-text-field
                    v-else
                    :type="item.type"
                    :placeholder="item.placeholder"
                    :disabled="
                      item.disabled
                        ? true
                        : globalDisableFieldsValue
                        ? true
                        : currentWorkspace.rewriteInvoice
                        ? true
                        : false
                    "
                    ref="test"
                    id="testID"
                    v-model="invoiceItem[item.model]"
                    filled
                    flat
                    persistent-placeholder
                    class="text-no-wrap rounded-md fs-12"
                    background-color="white"
                    hide-details
                    dense
                    depressed
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="ma-0 pa-0 d-flex justify-end align-end mt-5" cols="5">
            <div>
              <p class="ma-0 pa-0 fs-12 f-inter fw-600 d-flex justify-end">
                Total Amount
              </p>
              <p class="ma-0 pa-0 f-inter fs-40 fw-600 d-flex justify-end">
                {{
                  invoiceItem["total"]
                    ? invoiceItem["total"].toLocaleString()
                    : 0.0
                }}
              </p>
              <CurrencyBox
                v-if="invoiceItem"
                @set-currency="setCurrency"
                @fetch-currency="fetchCurrency"
                :currencyLoader="currencyLoader"
                :currencies="currencies"
                :invoiceItem="invoiceItem"
              />
            </div>
          </v-col>
        </v-row>

        <v-divider class="mt-4" />

        <!-- Form Header Fields -->
        <v-row class="ma-0 pa-0 rounded-lg">
          <v-col
            class="ma-0 pa-0 mt-4 px-1"
            cols="2"
            v-for="(item, index) in headerData"
            :key="index + 'C'"
          >
            <div class="">
              <span class="fs-12 f-inter fw-600 my-1">{{ item.label }}</span>
              <v-text-field
                v-if="item.model == 'brandingThemes'"
                filled
                flat
                persistent-placeholder
                class="text-no-wrap rounded-md fs-12"
                background-color="white"
                hide-details
                dense
                depressed
                :type="item.type"
                :placeholder="item.placeholder"
                :disabled="
                  item.disabled
                    ? true
                    : globalDisableFieldsValue
                    ? true
                    : currentWorkspace.rewriteInvoice
                    ? true
                    : false
                "
                :value="
                  typeof invoiceItem['brandingThemeID'] == 'object' &&
                  invoiceItem['brandingThemeID']?.name
                    ? invoiceItem['brandingThemeID']?.name
                    : ''
                "
              ></v-text-field>
              <v-select
                v-else-if="item.model == 'lineAmountTypes'"
                class="text-no-wrap rounded-md fs-12"
                background-color="white"
                hide-details
                @input="unSelectTax"
                dense
                flat
                solo
                color="appColor"
                :placeholder="item.placeholder"
                :items="item.lineAmountArray"
                :disabled="
                  item.disabled
                    ? true
                    : globalDisableFieldsValue
                    ? true
                    : currentWorkspace.rewriteInvoice
                    ? true
                    : false
                "
                v-model="invoiceItem[item.model]"
              >
              </v-select>
              <v-text-field
                v-else
                :type="item.type"
                filled
                flat
                persistent-placeholder
                class="text-no-wrap rounded-md fs-12"
                background-color="white"
                hide-details
                dense
                depressed
                :placeholder="item.placeholder"
                :disabled="
                  item.disabled
                    ? true
                    : globalDisableFieldsValue
                    ? true
                    : currentWorkspace.rewriteInvoice
                    ? true
                    : false
                "
                v-model="invoiceItem[item.model]"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>

        <!-- Billign Details Footer-->
        <v-row class="ma-0 mt-3 pa-0">
          <v-col
            lg="2"
            md="8"
            sm="12"
            class="ma-0 pa-0 mt-1 px-1"
            v-for="(item, index3) in footerFields"
            :key="index3 + 'D'"
          >
            <div class="">
              <span class="fs-12 f-inter fw-600 my-1">{{ item.label }}</span>
              <v-select
                v-if="item.key == 'type'"
                class="text-no-wrap rounded-md fs-12"
                background-color="white"
                hide-details
                dense
                flat
                solo
                color="appColor"
                :placeholder="item.placeholder"
                :items="item.typeArray"
                :disabled="
                  currentWorkspace.rewriteInvoice || user.role == 'employee'
                "
                v-model="invoiceItem[item.key]"
                item-text="text"
                item-value="value"
              >
                <!-- v-model="invoiceItem[item.key]" -->
              </v-select>
              <v-text-field
                v-else
                :type="item.type"
                filled
                flat
                persistent-placeholder
                class="text-no-wrap rounded-md fs-12"
                background-color="numberField"
                hide-details
                dense
                depressed
                disabled
                :placeholder="item.placeholder"
                readonly
                :autocomplete="false"
                :value="
                  item.key == 'subTotal'
                    ? calcSubTotal(invoiceItem)
                    : item.key == 'totalTax'
                    ? calcTotalTax(invoiceItem)
                    : item.key == 'total'
                    ? calcTotal(invoiceItem)
                    : ''
                "
              ></v-text-field>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <div class="d-flex align-end">
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="rounded-lg elevation-0 pa-0"
                  style="min-width: 36px"
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-img
                    src="@/assets/svg-icons/xero-form-sections.svg"
                  ></v-img>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group
                  v-model="selectedValues"
                  multiple
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, index2) in lineItems"
                    :key="index2 + 'C'"
                    @click="addAndRemoveLineItems(item, index, invoiceItem)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </v-row>

        <v-divider class="mt-4" />

        <!-- Billing Details Header-->
        <div
          class="pb-3 overflow-x-auto"
          style="max-height: 30vh; overflow-y: auto"
        >
          <v-row class="ma-0 pa-0 pt-2 d-flex flex-nowrap">
            <v-col
              v-for="(item, index2) in headerFields"
              v-show="item.index == index || item.index == 'default'"
              :key="index2 + '' + item.key"
              :cols="$vuetify.breakpoint.lgAndUp ? item.colSize : '3'"
              class="ma-0 pa-0 lightPurple"
              :class="
                item.key == 'action' || item.key == 'description'
                  ? 'border-dim-heading'
                  : 'custom-width border-dim-heading'
              "
              :style="{
                display:
                  (item.key === 'taxAmount' &&
                    invoiceItem.lineAmountTypes !== 'Exclusive') ||
                  (item.key === 'disc' && invoiceItem.type === 'ACCPAY')
                    ? 'none !important'
                    : '',
              }"
            >
              <p
                class="ma-0 pa-0 fs-12 justify-start d-flex align-end overflow-hidden fw-600 white--text f-inter ml-3"
              >
                {{ item.heading }}
              </p>
            </v-col>
            <template v-for="(item, index) in trackingCategories">
              <v-col
                v-if="invoiceItem.lineItems[0].tracking"
                :key="index + 'F'"
                cols="2"
                class="ma-0 pa-0 lightPurple border-dim-heading"
              >
                <p
                  class="ma-0 pa-0 fs-12 justify-start d-flex align-end overflow-hidden fw-600 white--text f-inter ml-3"
                >
                  {{ item.name }}
                </p>
              </v-col>
            </template>
            <v-col
              v-if="!currentWorkspace.rewriteInvoice && user.role == 'client'"
              :key="index"
              cols="1"
              class="ma-0 pa-0 lightPurple border-dim-heading"
            >
            </v-col>
          </v-row>
          <!-- Billign Details -->

          <v-row
            class="ma-0 pa-0 d-flex flex-nowrap"
            v-for="(item, index3) in invoiceItem.lineItems"
            :key="index3 + 'G'"
          >
            <v-col
              v-for="(item1, index1) in headerFields"
              v-show="item1.index == index || item1.index == 'default'"
              :key="index1 + '' + item1.key"
              :cols="$vuetify.breakpoint.lgAndUp ? item1.colSize : '3'"
              class="ma-0 pa-0 border-dim-fields"
              :class="
                item1.key == 'action' || item1.key == 'description'
                  ? ''
                  : 'custom-width'
              "
              :style="{
                display:
                  (item1.key === 'taxAmount' &&
                    invoiceItem.lineAmountTypes !== 'Exclusive') ||
                  (item1.key === 'disc' && invoiceItem.type === 'ACCPAY')
                    ? 'none !important'
                    : '',
              }"
            >
              <!-- number text-fields -->

              <v-text-field
                v-if="item1.selectionType == 'textfield'"
                filled
                flat
                persistent-placeholder
                class="text-no-wrap rounded-md fs-12"
                background-color="white"
                hide-details
                dense
                depressed
                :type="item1.type"
                :placeholder="item1.placeholder"
                :min="item1.type == 'number' ? '0' : ''"
                :max="item1.key == 'disc' ? '100' : ''"
                :disabled="
                  item.disabled
                    ? true
                    : globalDisableFieldsValue
                    ? true
                    : currentWorkspace.rewriteInvoice
                    ? true
                    : item1.key == 'lineAmount'
                    ? true
                    : false
                "
                v-model.number="item[item1.key]"
                :value="
                  item1.key == 'lineAmount' ? calcTotalAmount(invoiceItem) : ''
                "
              ></v-text-field>

              <!-- Dropdown all items  -->
              <v-autocomplete
                class="text-no-wrap rounded-md fs-12"
                background-color="white"
                hide-details
                dense
                flat
                solo
                color="appColor"
                v-else-if="
                  item1.selectionType == 'select' && item1.key == 'itemCode'
                "
                :disabled="
                  currentWorkspace.rewriteInvoice || user.role == 'employee'
                "
                :placeholder="item1.placeholder"
                :items="items"
                :value="item[item1.key]"
                @input="selectItem($event, item1.key, item)"
                return-object
                item-text="name"
              >
                <template #item="{ item }">
                  <span class="fs-12"> {{ item.name }}</span>
                </template>
                <template #selection="{ item }">
                  <span class="fs-12">
                    {{ item.name.substr(0, 11)
                    }}{{ item.name.length > 11 ? ".." : "" }}
                  </span>
                </template>
              </v-autocomplete>

              <!-- Dropdown all account head  -->
              <v-autocomplete
                class="text-no-wrap rounded-md fs-12"
                :label="item1.label"
                background-color="white"
                hide-details
                dense
                flat
                solo
                color="appColor"
                v-else-if="
                  item1.selectionType == 'select' && item1.key == 'accountCode'
                "
                :disabled="
                  currentWorkspace.rewriteInvoice || user.role == 'employee'
                "
                :placeholder="item1.placeholder"
                :items="accounts.accounts"
                return-object
                @input="selectAccountType($event, item1.key, item)"
                item-text="name"
                :value="item[item1.key]"
              >
                <template #item="{ item }">
                  <span class="fs-12"> {{ item.name }}</span>
                </template>
                <template #selection="{ item }">
                  <span class="fs-12">
                    {{ item.name.substr(0, 12)
                    }}{{ item.name.length > 12 ? ".." : "" }}
                  </span>
                </template>
              </v-autocomplete>

              <!-- Dropdown Tax Rates  -->
              <v-select
                v-else-if="
                  item1.selectionType == 'select' && item1.key == 'taxType'
                "
                class="text-no-wrap rounded-md fs-12"
                :label="item1.label"
                background-color="white"
                hide-details
                dense
                flat
                solo
                color="appColor"
                :placeholder="item1.placeholder"
                :items="taxRateValues"
                :disabled="
                  currentWorkspace.rewriteInvoice || user.role == 'employee'
                "
                v-model="item[item1.key]"
                item-value="taxType"
                return-object
              >
                <template #item="{ item }">
                  <span
                    class="fs-12 font-style-paragraph mainTextColor--text ma-0 pa-0"
                  >
                    {{ item.name }}({{
                      item.taxComponents
                        ? item.taxComponents[0].rate + "%"
                        : "0%"
                    }})</span
                  >
                </template>
                <template #selection="{ item }">
                  <span
                    class="fs-12 font-style-paragraph mainTextColor--text ma-0 pa-0"
                  >
                    {{ item.name.substr(0, 11)
                    }}{{ item.name.length > 11 ? ".." : "" }}
                  </span>
                </template>
              </v-select>
            </v-col>
            <!-- Tracking categories -->
            <template v-for="(item1, index) in trackingCategories">
              <v-col
                :key="index + 'I'"
                cols="2"
                class="ma-0 pa-1 border-dim-fields"
                v-if="item.tracking && item.tracking[index]"
              >
                <v-select
                  id="column-fields"
                  :disabled="
                    currentWorkspace.rewriteInvoice || user.role == 'employee'
                  "
                  class="text-no-wrap rounded-md fs-12"
                  background-color="white"
                  hide-details
                  dense
                  flat
                  solo
                  color="appColor"
                  :items="item1.options"
                  placeholder="Enter tracking category"
                  item-value="trackingCategoryID"
                  item-text="name"
                  return-object
                  v-model="item.tracking[index].trackingOptionID"
                >
                  <template #item="{ item }">
                    <span
                      class="fs-12 font-style-paragraph mainTextColor--text ma-0 pa-0"
                    >
                      {{ item.name }}</span
                    >
                  </template>
                  <template #selection="{ item }">
                    <span
                      class="fs-12 font-style-paragraph mainTextColor--text ma-0 pa-0"
                    >
                      {{ item.name.substr(0, 11)
                      }}{{ item.name.length > 11 ? ".." : "" }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </template>

            <v-col
              v-if="!currentWorkspace.rewriteInvoice && user.role == 'client'"
              cols="1"
              class="pa-0 pt-1 border-dim-fields"
            >
              <div
                @click="deleteField(invoiceItem, item, index3)"
                style="cursor: pointer"
                class="px-4 py-2"
              >
                <v-icon small color="red">mdi-trash-can-outline</v-icon>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- Add new Product -->
        <v-col cols="3" class="ma-0 ml-2 mb-6 pa-0 rounded-lg">
          <div
            class="d-flex justify-start"
            v-if="user.role == 'client' && !currentWorkspace.rewriteInvoice"
          >
            <div
              class="mr-2 fs-13 fw-500 f-inter cursor-pointer primary--text"
              @click="addNewLineitem(invoiceItem)"
            >
              + Add New Line
            </div>
          </div>
        </v-col>
        <!-- Billign Details Footer-->
      </div>
    </div>

    <!-- When no invoice get from draft -->
    <div v-else>
      <!-- Top Form Header Fields -->
      <v-row class="ma-0 pa-0 rounded-lg">
        <v-col class="ma-0 pa-0" cols="7">
          <v-row class="ma-0 pa-0">
            <v-col
              class="ma-0 pa-0 px-1"
              v-for="(item, index) in topHeaderData"
              :key="index + 'J'"
              :cols="item.cols"
            >
              <div class="">
                <span class="fs-12 f-inter fw-600 my-1">{{ item.label }}</span>
                <v-text-field
                  :type="item.type"
                  filled
                  flat
                  persistent-placeholder
                  class="text-no-wrap rounded-md fs-12"
                  :placeholder="'Extracting...'"
                  background-color="white"
                  hide-details
                  dense
                  depressed
                  disabled
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ma-0 pa-0 d-flex justify-end align-end mt-5" cols="5">
          <div>
            <p class="ma-0 pa-0 fs-12 f-inter fw-600 d-flex justify-end">
              Total Amount
            </p>
            <p class="ma-0 pa-0 fs-40 f-inter fw-600 d-flex justify-end">0.0</p>
            <p class="ma-0 pa-0 fs-12 f-inter fw-600 d-flex justify-end">USD</p>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-4" />

      <!-- Form Header Fields -->
      <v-row class="ma-0 pa-0 rounded-lg">
        <v-col
          class="ma-0 pa-0 mt-4 px-1"
          cols="2"
          v-for="(item, index) in headerData"
          :key="index + 'K'"
        >
          <div class="">
            <span class="fs-12 f-inter fw-600 my-1">{{ item.label }}</span>
            <v-text-field
              :type="item.type"
              filled
              flat
              persistent-placeholder
              class="text-no-wrap rounded-md fs-12"
              :placeholder="'Extracting...'"
              background-color="white"
              hide-details
              dense
              depressed
              disabled
            ></v-text-field>
          </div>
        </v-col>
      </v-row>

      <!-- Billign Details Footer-->
      <v-row class="ma-0 mt-3 pa-0">
        <v-col
          lg="2"
          md="8"
          sm="12"
          class="ma-0 pa-0 mt-1 px-1"
          v-for="(item, index) in footerFields"
          :key="index + 'M'"
        >
          <div class="">
            <span class="fs-12 f-inter fw-600 my-1">{{ item.label }}</span>
            <v-text-field
              :type="item.type"
              filled
              flat
              persistent-placeholder
              class="text-no-wrap rounded-md fs-12"
              :placeholder="'Extracting...'"
              background-color="numberField"
              hide-details
              dense
              depressed
              disabled
            ></v-text-field>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <div class="d-flex align-end">
          <v-btn
            class="rounded-lg elevation-0 pa-0"
            style="min-width: 36px"
            color="white"
          >
            <v-img src="@/assets/svg-icons/xero-form-sections.svg"></v-img>
          </v-btn>
        </div>
      </v-row>

      <v-divider class="mt-4" />

      <!-- Billing Details Header -->
      <div class="pb-3 overflow-x-auto mt-3">
        <v-row class="ma-0 pa-0 d-flex flex-nowrap">
          <v-col
            v-for="(item, index) in headerFields"
            :key="index + 'N'"
            :cols="$vuetify.breakpoint.lgAndUp ? item.colSize : '3'"
            class="ma-0 pa-0 lightPurple"
            :class="
              item.key == 'action' || item.key == 'description'
                ? 'border-dim-heading'
                : 'custom-width border-dim-heading'
            "
          >
            <p
              v-if="item.heading !== 'Action'"
              class="ma-0 pa-0 fs-12 justify-start d-flex align-end overflow-hidden fw-600 white--text f-inter ml-4"
            >
              {{ item.heading }}
            </p>
          </v-col>
        </v-row>
        <!-- Billign Details -->
        <v-row class="ma-0 pa-0">
          <v-col
            v-for="(item1, index1) in headerFields"
            :key="index1 + 'O'"
            :cols="$vuetify.breakpoint.lgAndUp ? item1.colSize : '3'"
            class="ma-0 pa-0 mb-2 border-dim-fields"
            :class="
              item1.key == 'action' || item1.key == 'description'
                ? ''
                : 'custom-width'
            "
          >
            <!-- number text-fields -->

            <v-text-field
              v-if="item1.selectionType == 'textfield'"
              filled
              flat
              persistent-placeholder
              class="text-no-wrap rounded-md fs-12"
              :placeholder="'Extracting...'"
              background-color="white"
              hide-details
              dense
              depressed
              disabled
              :type="item1.type"
            ></v-text-field>

            <!-- Dropdown all items  -->
            <v-autocomplete
              v-else-if="
                item1.selectionType == 'select' && item1.key == 'itemCode'
              "
              disabled
              class="text-no-wrap rounded-md fs-12"
              :label="item1.label"
              :placeholder="'Extracting...'"
              background-color="white"
              hide-details
              dense
              flat
              solo
              color="appColor"
            >
            </v-autocomplete>

            <!-- Dropdown all account head  -->
            <v-autocomplete
              v-else-if="
                item1.selectionType == 'select' && item1.key == 'accountCode'
              "
              disabled
              class="text-no-wrap rounded-md fs-12"
              :label="item1.label"
              :placeholder="'Extracting...'"
              background-color="white"
              hide-details
              dense
              flat
              solo
              color="appColor"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "../../common/button";
import ApiService from "@/services/Api.js";
import UrlServices from "@/services/Url";
import EventBus from "../../../js/EventBus";
import { nanoid } from "nanoid";
import CurrencyBox from "./CurrencyBox";
import { setAccountItemsHashMaps } from "../../../services/AccountsItemsHashmap";
import { debounce } from "debounce";
export default {
  name: "Form",
  components: { Button, CurrencyBox },
  data() {
    return {
      focus: false,
      selectedItem: "",
      /*
       **  Fetch Contact Variables
       */
      showLoadMoreInContacts: {
        key: false,
        search: "",
      },
      pageInContacts: 1,
      nameItems: [],
      nameLoading: false,
      /*
       **  End
       */

      selectedValues: [],
      // accounts: {
      //   tenantId: null,
      //   accounts: [],
      // },
      lineItems: [
        { text: "Disc %", key: "disc" },
        { text: "Tax", key: "taxAmount" },
        { text: "Tax Rate", key: "taxType" },
      ],
      selectedTrackingItems: [],
      lineItemsColWidth: "13% !important",
      dataDraft: null,
      oldDataDraft: null,
      editableForm: false,
      tableIndex: {},
      itemArr: [],
      itemArrDif: [],
      taxRateValues: [],
      selected: "",
      invoiceIndex: null,
      currencyLoader: false,
      currencies: [],
      brandingTheme: "",
      brandingLoader: false,
      currencyList: ["USD", "PKR", "EUR", "GBP"],
      allAccountsItems: [],
      xeroHashMaps: {
        accountsHashmap: { ids: {}, all: [] },
        itemsHashmap: { ids: {}, all: [] },
      },
      ticketData: {},
      newInvoiceForm: {
        invoices: [
          {
            address: "",
            balanceDue: "",
            currencyCode: "",
            date: "",
            discount: "",
            dueDate: "",
            email: "",
            invoiceNumber: "",
            lineItems: [
              {
                description: "",
                disc: 0,
                lineAmount: 0,
                quantity: 0,
                taxAmount: 0,
                unitAmount: 0,
                itemCode: "",
                accountCode: "",
                taxType: "",
              },
            ],
            name: "",
            subTotal: 0,
            totalTax: 0,
            total: 0,
            status: "DRAFT",
            type: "ACCREC",
            lineAmountTypes: "Exclusive",
          },
        ],
      },
      topHeaderData: [
        {
          title: "Name",
          placeholder: "Enter contact",
          label: "Contact",
          requiredField: [(v) => !!v || "Field is required"],
          type: "combobox",
          maxLength: "50",
          model: "name",
          disabled: false,
          cols: 6,
        },
        {
          title: "Email",
          placeholder: "Enter email",
          label: "Email",
          requiredField: [(v) => !!v || "Field is required"],
          type: "text-field",
          maxLength: "50",
          model: "email",
          disabled: false,
          cols: 6,
        },
        {
          title: "Address",
          placeholder: "Enter address",
          label: "Address",
          requiredField: [(v) => !!v || "Field is required"],
          type: "text-field",
          maxLength: "200",
          model: "address",
          disabled: false,
          cols: 6,
        },
        {
          title: "City",
          placeholder: "City",
          label: "City/State",
          requiredField: [(v) => !!v || "Field is required"],
          type: "text-field",
          maxLength: "200",
          model: "city",
          disabled: false,
          cols: 3,
        },
        {
          title: "Country",
          placeholder: "Country",
          label: "Country",
          requiredField: [(v) => !!v || "Field is required"],
          type: "text-field",
          maxLength: "200",
          model: "country",
          disabled: false,
          cols: 3,
        },
      ],
      headerData: [
        {
          title: "Date",
          placeholder: "Enter date",
          label: "Date",
          requiredField: [(v) => !!v || "Field is required"],
          type: "date",
          maxLength: "50",
          model: "date",
          disabled: false,
        },
        {
          title: "Due Date",
          placeholder: "Enter due date",
          label: "Due Date",
          requiredField: [(v) => !!v || "Field is required"],
          type: "date",
          maxLength: "50",
          model: "dueDate",
          disabled: false,
        },
        {
          title: "Line Amount Type",
          placeholder: "Enter line amount type",
          label: "Line Amount Type",
          requiredField: [(v) => !!v || "Field is required"],
          type: "text-field",
          maxLength: "50",
          model: "lineAmountTypes",
          lineAmountArray: ["Exclusive", "Inclusive", "NoTax"],
          disabled: false,
        },
        {
          title: "Invoice Number",
          placeholder: "Enter number",
          label: "Invoice Number",
          requiredField: [(v) => !!v || "Field is required"],
          type: "text-field",
          maxLength: "50",
          model: "invoiceNumber",
          disabled: false,
        },
        {
          title: "Reference",
          placeholder: "Enter reference",
          label: "Reference",
          requiredField: [(v) => !!v || "Field is required"],
          type: "text-field",
          maxLength: "50",
          model: "reference",
          disabled: false,
        },
      ],
      fieldsColSize: {
        description: 3,
        quantity: 2,
        unitAmount: 2,
        discount: 2,
        tax: 2,
        total: 2,
      },
      headerFields: [
        {
          selectionType: "select",
          heading: "Item",
          key: "itemCode",
          index: "default",
          colSize: 2,
          placeholder: "Enter Item",
          type: "string",
          disabled: false,
        },
        {
          selectionType: "textfield",
          heading: "Description",
          key: "description",
          placeholder: "Enter description",
          index: "default",
          colSize: 3,
          type: "string",
          disabled: false,
        },
        {
          selectionType: "textfield",
          heading: "Quantity",
          key: "quantity",
          placeholder: "0",
          index: "default",
          colSize: 2,
          type: "number",
          disabled: false,
        },
        {
          selectionType: "textfield",
          heading: "Unit Price",
          key: "unitAmount",
          placeholder: "0",
          index: "default",
          colSize: 2,
          type: "number",
          disabled: false,
        },
        {
          selectionType: "select",
          heading: "Account Head",
          key: "accountCode",
          placeholder: "Enter account",
          index: "default",
          colSize: 2,
          type: "string",
          disabled: false,
        },
        {
          selectionType: "textfield",
          heading: "Total",
          key: "lineAmount",
          index: "default",
          colSize: 2,
          type: "number",
          disabled: true,
        },
      ],
      footerFields: [
        {
          label: "Sub Total",
          placeholder: "Sub Total",
          type: "number",
          key: "subTotal",
        },
        {
          label: "Tax Amount",
          placeholder: "Tax Amount",
          type: "number",
          key: "totalTax",
        },
        {
          label: "Total",
          placeholder: "Total",
          type: "number",
          key: "total",
        },
      ],
      accountLoading: false,
      userAccountsNameHashMap: {},
      userAccountsCodeHashMap: {},
      userAccounts: [],
      defaultTaxItems: [
        {
          name: "Sales Tax on Imports",
          taxType: "GSTONIMPORTS",
          displayTaxRate: 0,
        },
        { name: "Tax Exempt", taxType: "NONE", displayTaxRate: 0 },
        { name: "Tax on Purchases", taxType: "INPUT", displayTaxRate: 0 },
        { name: "Tax on Sales", taxType: "OUTPUT", displayTaxRate: 0 },
      ],
    };
  },
  props: {
    draft: Object,
    ticket: Object,
    trackingCategories: Array,
    accounts: Object,
    items: Array,
    paymentField: Boolean,
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      user: "auth/getUser",
    }),

    globalDisableFieldsValue() {
      if (this.user.role === "employee") return true;
      else return false;
    },
  },
  watch: {
    newInvoiceForm: {
      handler() {
        this.$emit("new-invoice-object", this.newInvoiceForm);
      },
      // immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.ticketData = this.ticket;
    this.dataDraft = this.draft ? this.draft : {};

    if (this.dataDraft || !this.currentWorkspace.rewriteInvoice)
      this.editableForm = true;

    // newInvoiceForm case handled
    if (this.editableForm) {
      if (
        (!this.draft &&
          !this.currentWorkspace.rewriteInvoice &&
          this.ticketData.status === "Complete") ||
        this.ticketData.status === "PendingApproval"
      ) {
        this.dataDraft = this.newInvoiceForm;
      } else if (this.draft) {
        this.editableForm = true;
      } else if (!this.draft) {
        this.editableForm = false;
      }
    }

    if (this.dataDraft)
      this.oldDataDraft = JSON.parse(JSON.stringify(this.dataDraft));

    // set calculations for copied duplicate draft data
    if (this.oldDataDraft) {
      // set old duplicate draft data calculations for totalLineAmount
      this.calcTotalAmountForOldData();
      // set old duplicate data calculations for subTotal
      this.calcSubTotalForOldData();
      // set old duplicate data calculations for totalTax
      this.calcTotalTaxForOldData();
      // set old duplicate data calculations for final Total
      this.calcTotalForOldData();
      // get tax rates
      if (this.currentWorkspace.isAccountingSoftConnected) {
        this.getTaxRates();

        this.getContacts("");
      } else {
        this.taxRateValues = this.defaultTaxItems;
      }
    }
  },

  // this.fetchBrandingThemes();
  methods: {
    async getContactsLoadMore() {
      this.pageInContacts = this.pageInContacts + 1;
      this.showLoadMoreInContacts.key = false;
      this.nameLoading = true;
      ApiService.GetRequest(
        `${UrlServices.getContacts}${this.ticket.workspaceId}?search=${this.showLoadMoreInContacts.search}&page=${this.pageInContacts}`
      )
        .then((response) => {
          this.nameItems = this.nameItems.concat(response.data.contacts);
          if (response.data.contacts.length == 100)
            this.showLoadMoreInContacts.key = true;
          this.showLoadMoreInContacts.search = search;
        })
        .catch((error) => {
          console.log("show notification bar");
        })
        .finally(() => {
          this.nameLoading = false;
        });
    },
    // Method to get contacts from Xero
    getContacts: debounce(function (search) {
      this.nameLoading = true;
      ApiService.GetRequest(
        `${UrlServices.getContacts}${this.ticket.workspaceId}?search=${search}`
      )
        .then((response) => {
          this.nameItems = response.data.contacts;
          console.log("Names data", this.nameItems);
          this.showLoadMoreInContacts.key = false;
          this.showLoadMoreInContacts.search = "";
          if (response.data.contacts.length == 100) {
            this.showLoadMoreInContacts.key = true;
            this.showLoadMoreInContacts.search = search;
          }
        })
        .catch((error) => {
          console.log("show notification bar");
        })
        .finally(() => {
          this.nameLoading = false;
        });
    }, 1200),

    // Method to set searched contact name and calling contacts fetching function
    setContactName(event) {
      if (event === null) return;
      // if (event === null || this.invoice.name == event) return;
      if (this.currentWorkspace?.isAccountingSoftConnected) {
        if (event === "") {
          this.nameLoading = true;
          this.pageInContacts = 1;
          this.getContacts(event);
        } else {
          const regex = new RegExp(event, "i");
          if (
            // this.invoice.name !== event &&
            this.nameItems.findIndex((contact) => regex.test(contact.name)) < 0
          ) {
            this.nameLoading = true;
            this.pageInContacts = 1;
            this.getContacts(event);
          }
        }
      }

      // set through emit
      this.$emit("change-data", { key: "name", value: event });
      // this.invoice.name = event;
    },
    // get tax rates
    getTaxRates() {
      ApiService.GetRequest(
        `xero/get-tax-rates/${this.currentWorkspace.workspaceId}`
      )
        .then((res) => {
          this.taxRateValues = res.data.taxRates;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * This function calculates the total lineAmount in every lineitem
     * @param {*} invoiceItem
     */
    calcTotalAmount(invoiceItem) {
      let total = 0;
      invoiceItem.lineItems.forEach((el) => {
        if (isNaN(el.quantity)) el.quantity = 0;
        if (isNaN(el.unitAmount)) el.unitAmount = 0;
        if (isNaN(el.disc)) el.disc = 0;
        if (isNaN(el.taxAmount)) el.taxAmount = 0;
        if (typeof el.disc === "string")
          el.disc = el.disc.replace(/^%|%$/g, "");
        if (typeof el.taxAmount === "string")
          el.taxAmount = el.taxAmount.replace(/^%|%$/g, "");
        let x =
          invoiceItem.type === "ACCPAY"
            ? 1
            : (100 - Math.round(el.disc * 100) / 100) / 100;
        total = +el.quantity * +el.unitAmount * x;
        el.lineAmount = Math.round(total * 100) / 100;
      });

      // get new and old draft data to compare the field values
      this.getDraftData();
      return Math.round(total * 100) / 100;
    },

    /**
     * This function calculates every LineAmount total value for old duplicate data draft
     * so that one can compare old and new draft data
     */
    calcTotalAmountForOldData() {
      let total = 0;
      if (!this.oldDataDraft.invoices) return;
      this.oldDataDraft?.invoices.forEach((invoiceItem) => {
        invoiceItem?.lineItems.forEach((el) => {
          if (isNaN(el.quantity)) el.quantity = 0;
          if (isNaN(el.unitAmount)) el.unitAmount = 0;
          if (isNaN(el.disc)) el.disc = 0;
          if (isNaN(el.taxAmount)) el.taxAmount = 0;
          if (typeof el.disc === "string")
            el.disc = el.disc.replace(/^%|%$/g, "");
          if (typeof el.taxAmount === "string")
            el.taxAmount = el.taxAmount.replace(/^%|%$/g, "");
          let x =
            invoiceItem.type === "ACCPAY"
              ? 1
              : (100 - Math.round(el.disc * 100) / 100) / 100;
          total = +el.quantity * +el.unitAmount * x;
          el.lineAmount = Math.round(total * 100) / 100;
        });
      });
    },

    /**
     * This function calculates the subTotal in footer
     * @param {*} invoiceItem
     */
    calcSubTotal(invoiceItem) {
      let invoiceSubTotal = 0;
      invoiceItem.lineItems.forEach((el) => {
        let totalLineAmount = Number(el.lineAmount);
        invoiceSubTotal += totalLineAmount;
      });
      invoiceItem["subTotal"] = Math.round(invoiceSubTotal * 100) / 100;
      return Math.round(invoiceSubTotal * 100) / 100;
    },

    /**
     * This function calculates subTotal value for old duplicate data draft
     * so that one can compare old and new draft data
     */
    calcSubTotalForOldData() {
      if (!this.oldDataDraft.invoices) return;
      this.oldDataDraft?.invoices.forEach((invoiceItem) => {
        let invoiceSubTotal = 0;
        invoiceItem.lineItems.forEach((el) => {
          let totalLineAmount = Number(el.lineAmount);
          invoiceSubTotal += totalLineAmount;
        });
        invoiceItem["subTotal"] = Math.round(invoiceSubTotal * 100) / 100;
      });
    },

    /**
     * This function calculates the totalTax in footer
     * @param {*} invoiceItem
     */
    calcTotalTax(invoiceItem) {
      let invoiceTotalTax = 0;
      if (invoiceItem.lineAmountTypes == "Exclusive") {
        invoiceItem.lineItems.forEach((el) => {
          if (!el.taxAmount && el.taxAmount == undefined) el.taxAmount = 0;
          let totalTaxAmount = Number(el.taxAmount);
          invoiceTotalTax += totalTaxAmount;
        });
        invoiceItem["totalTax"] = Math.round(invoiceTotalTax * 100) / 100;
      }
      // This condition sets tax amount to zero when No Tax is selected from dropdown.
      else if (invoiceItem.lineAmountTypes == "NoTax") {
        invoiceItem.lineItems.forEach((el) => {
          el.taxAmount = 0;
        });
      } else {
        invoiceItem["totalTax"] = Math.round(invoiceTotalTax * 100) / 100;
      }
      return Math.round(invoiceTotalTax * 100) / 100;
    },

    unSelectTax() {
      if (this.dataDraft.invoices[0].lineAmountTypes == "NoTax") {
        const filteredData = this.selectedValues;
        const filterHeaders = this.headerFields;
        this.selectedValues = filteredData.filter((item) => {
          if (typeof item === "object") {
            return item.key !== "taxAmount";
          } else {
            return item !== 1;
          }
        });
        // Removing tax header field from table
        this.headerFields = filterHeaders.filter(
          (item) => item.key !== "taxAmount"
        );
        console.log("HEADERS", this.headerFields);
      }
    },

    /**
     * This function calculates totalTax value for old duplicate data draft
     * so that one can compare old and new draft data
     */
    calcTotalTaxForOldData() {
      if (!this.oldDataDraft.invoices) return;
      this.oldDataDraft?.invoices.forEach((invoiceItem) => {
        let invoiceTotalTax = 0;
        if (invoiceItem.lineAmountTypes == "Exclusive") {
          invoiceItem.lineItems.forEach((el) => {
            if (!el.taxAmount && el.taxAmount == undefined) el.taxAmount = 0;
            let totalTaxAmount = Number(el.taxAmount);
            invoiceTotalTax += totalTaxAmount;
          });
          invoiceItem["totalTax"] = Math.round(invoiceTotalTax * 100) / 100;
        } else {
          invoiceItem["totalTax"] = Math.round(invoiceTotalTax * 100) / 100;
        }
      });
    },

    /**
     * This function calculates final total(subTotal+totalTax) in footer
     * @param {*} invoiceItem
     */
    calcTotal(invoiceItem) {
      let total =
        Number(this.calcSubTotal(invoiceItem)) +
        Number(this.calcTotalTax(invoiceItem));
      invoiceItem["total"] = Math.round(total * 100) / 100;
      return Math.round(total * 100) / 100;
    },

    /**
     * This function calculates final total value for old duplicate data draft
     * so that one can compare old and new draft data
     */
    calcTotalForOldData() {
      if (!this.oldDataDraft.invoices) return;
      this.oldDataDraft?.invoices.forEach((invoiceItem) => {
        let total =
          Number(invoiceItem["subTotal"]) + Number(invoiceItem["totalTax"]);
        invoiceItem["total"] = Math.round(total * 100) / 100;
      });
    },

    /**
     * This function adds new lineitem for the specific invoice
     */
    addNewLineitem(invoiceItem) {
      let sampleObject = {
        itemCode: "",
        description: "",
        unitAmount: 0,
        quantity: 0,
        taxAmount: 0,
        lineAmount: 0,
        discountRate: 0,
        accountCode: "",
        taxType: "",
      };
      let Arr = [];
      if (this.currentWorkspace.isAccountingSoftConnected) {
        this.trackingCategories.forEach((element) => {
          let obj = {
            trackingCategoryID: element.trackingCategoryID,
            trackingOptionID: "",
          };
          Arr.push(obj);
        });
      }
      if (Arr.length > 0) sampleObject["tracking"] = Arr;

      invoiceItem.lineItems.push(sampleObject);
    },

    /**
     * This function fetch all the currencies
     */
    fetchCurrency() {
      if (this.currentWorkspace.isAccountingSoftConnected) {
        this.currencyLoader = true;
        ApiService.GetRequest(
          `${UrlServices.xeroCurrencies}/${this.currentWorkspace.workspaceId}`
        )
          .then((res) => {
            this.currencyLoader = false;
            this.currencies = res.data.currencies;
          })
          .catch((err) => {
            this.currencyLoader = false;
          });
      } else this.currencies = this.currencyList;
    },

    /**
     * This function inputs the currency field
     */
    setCurrency(e) {
      if (e != null) {
        this.dataDraft.invoices.forEach((inv) => {
          inv.currencyCode = e.toUpperCase();
        });
      }
    },

    /**
     * Method to fetch Branding themes from Xero
     */
    fetchBrandingThemes() {
      if (this.currentWorkspace.isAccountingSoftConnected) {
        this.brandingLoader = true;
        ApiService.GetRequest(
          `${UrlServices.getBrandingThemes}/${this.currentWorkspace.workspaceId}`
        )
          .then((res) => {
            this.brandingLoader = false;
            this.brandingTheme = res.data.brandingThemes.map((e) => e.name);
            console.log(this.brandingTheme);
            // this.brandingThemes = res.data.brandingThemes.map(e => e.name);
          })
          .catch((err) => {
            this.brandingLoader = false;
            console.log("err", err);
          });
      }
    },

    /**
     * this function select account type
     */
    selectAccountType(e, key, invoiceItem) {
      if (e?.code) invoiceItem[key] = { name: e.name, code: e.code };
    },
    /**
     * this function select Item
     */
    selectItem(e, key, invoiceItem) {
      if (e?.id) invoiceItem[key] = { id: e.id, name: e.name };
      // const accounts =
      //   this.xeroHashMaps.itemsHashmap.ids[invoiceItem.itemCode.id]?.accounts;
      // if (Array.isArray(accounts) && accounts.length)
      //   invoiceItem.accountCode = accounts[0];
      // else invoiceItem.accountCode = null;
    },

    /**
     * This function delete the lineitem with emit method
     * @param {*} invoiceItem
     * @param {*} index
     */
    deleteField(invoiceItem, item, index) {
      invoiceItem.lineItems.splice(index, 1);
    },

    /**
     *  This function selects the lineitem
     */
    createHeader(
      selectionType,
      heading,
      text,
      index,
      key,
      placeholder,
      colSize,
      type,
      disabled
    ) {
      return {
        selectionType: selectionType,
        heading: heading,
        text: text,
        index: index,
        key: key,
        placeholder: placeholder,
        colSize: colSize,
        type: type,
        disabled: disabled,
      };
    },

    /**
     * This function adds and remove the columns in the lineitems
     * @param {*} e (input element)
     * @param {*} index
     */

    addAndRemoveLineItems(selectedField, invIndex, invoiceItem) {
      if (!this.selectedValues.includes(selectedField)) {
        //checking weather array contain the id
        this.selectedValues.push(selectedField); //adding to array because value doesnt exists
        if (selectedField.key == "disc")
          this.headerFields.splice(
            this.headerFields.length - 1,
            0,
            this.createHeader(
              "textfield",
              "Disc %",
              "Discount",
              invIndex,
              "disc",
              "0",
              2,
              "number",
              false
            )
          );
        else if (selectedField.key == "taxAmount")
          this.headerFields.splice(
            this.headerFields.length - 1,
            0,
            this.createHeader(
              "textfield",
              "Tax",
              "Tax",
              invIndex,
              "taxAmount",
              "0",
              2,
              "number",
              false
            )
          );
        else if (selectedField.key == "taxType")
          this.headerFields.splice(
            this.headerFields.length - 1,
            0,
            this.createHeader(
              "select",
              "Tax Rate",
              "Tax Rate",
              invIndex,
              "taxType",
              "Enter tax rate",
              2,
              "string",
              false
            )
          );
      } else {
        this.selectedValues.splice(
          this.selectedValues.indexOf(selectedField),
          1
        );
        const index = this.headerFields.findIndex(
          (item) => item.key === selectedField.key
        );
        this.headerFields.splice(index, 1);
      }
    },

    /**
     * This function gets new draft data of all the invoices
     */
    getDraftData() {
      EventBus.$emit("new-draft-data", this.dataDraft.invoices);
      EventBus.$emit("old-draft-data", this.oldDataDraft?.invoices);
    },
  },

  // component beforeDestroy hook
  beforeDestroy() {
    this.allAccountsItems = [];
    this.xeroHashMaps = {};
  },
};
</script>

<style scoped>
.col-width {
  width: 150px;
}
:deep .col-width .v-label {
  font-size: 14px;
  color: var(--v-headerColor-base) !important;
}
:deep .v-list-item__action {
  margin-right: 16px !important;
}
:deep .v-text-field__slot > .v-label {
  font-weight: 600;
}
:deep .currency-field .v-select__slot > .v-label {
  font-weight: 600;
  margin-top: -6px;
}
:deep .currency-field .v-select__selections {
  padding-top: 30px !important;
}
:deep .v-text-field--outlined fieldset {
  color: rgb(243, 241, 241) !important;
}
:deep .custom .v-text-field__slot {
  height: 63px !important;
}

:deep .custom .v-select__slot {
  height: 63px !important;
}
:deep .v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 30px;
}
:deep .v-select.v-text-field:not(.v-text-field--single-line) input {
  margin-top: 0px !important;
}
:deep .v-text-field .v-input__slot {
  border-radius: 6px !important;
}
.custom-delete-btn {
  width: 36px;
  height: 36px;
  cursor: pointer;
}
:deep .custom-footer input {
  color: var(--v-primary-base) !important;
}
.custom-width {
  max-width: 13.99% !important;
}
.border-dim-heading {
  border-style: solid !important;
  border-color: white !important;
  border-width: thin;
  height: 40px !important;
  display: flex;
  align-items: center;
}

.border-dim-fields {
  border-style: solid !important;
  border-color: #f1f3f8 !important;
  border-width: thin;
  background-color: white;
}
.custom-padding {
  padding-left: 22px !important;
}
:deep .col-1 {
  max-width: 5% !important;
}
::v-deep .v-image__image--cover {
  background-size: auto !important;
}
</style>
