var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"buttonBg rounded-lg",staticStyle:{"max-height":"80vh","overflow-y":"scroll !important"}},_vm._l((_vm.newDataDraft?.invoices),function(invoiceItem,index){return _c('div',{key:index},[_c('v-row',{staticClass:"ma-0 pa-0 rounded-lg"},[_c('v-col',{staticClass:"ma-0 pa-0 secondary rounded-lg",attrs:{"cols":"12"}},[_c('span',{staticClass:"d-flex justify-center fs-18 strong"},[_vm._v("Invoice No: "+_vm._s(invoiceItem.invoiceNumber))])]),_vm._l((_vm.headerData),function(item,index){return _c('v-col',{key:index,staticClass:"ma-0 pa-0 d-flex align-stretch",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"ma-0 pa-1"},[_c('v-col',{staticClass:"ma-0 pa-1 d-flex justify-left align-end",attrs:{"cols":"3"}},[_c('span',{staticClass:"ma-0 pa-0 fs-10"},[_c('strong',[_vm._v(_vm._s(item.title))])])]),(item.model !== 'brandingThemes')?_c('v-col',{staticClass:"ma-0 pa-1 d-flex justify-center align-end",attrs:{"cols":"9"}},[((item.type = 'text-field'))?_c('v-text-field',{staticClass:"text-no-wrap rounded-md custom",attrs:{"outlined":"","placeholder":item.placeholder,"background-color":"inputBackground","hide-details":"","dense":"","depressed":"","disabled":item.disabled
                  ? true
                  : _vm.globalDisableFieldsValue
                  ? true
                  : !_vm.currentWorkspace.rewriteInvoice
                  ? true
                  : false},model:{value:(invoiceItem[item.model]),callback:function ($$v) {_vm.$set(invoiceItem, item.model, $$v)},expression:"invoiceItem[item.model]"}}):_vm._e()],1):(item.model == 'brandingThemes')?_c('v-col',{staticClass:"ma-0 pa-1 d-flex justify-center align-end",attrs:{"cols":"9"}},[((item.type = 'text-field'))?_c('v-text-field',{staticClass:"text-no-wrap rounded-md custom",attrs:{"outlined":"","placeholder":item.placeholder,"background-color":"inputBackground","hide-details":"","dense":"","depressed":"","disabled":item.disabled
                  ? true
                  : _vm.globalDisableFieldsValue
                  ? true
                  : !_vm.currentWorkspace.rewriteInvoice
                  ? true
                  : false,"value":_vm.brandingTheme}}):_vm._e()],1):_vm._e()],1)],1)}),_c('v-col',{staticClass:"ma-0 pa-0 rounded-lg",attrs:{"cols":"12"}},[_c('span',{staticClass:"d-flex justify-center fs-18 strong secondary--text"},[_vm._v("Billing Details")])]),_c('v-col',{staticClass:"ma-0 pa-0 rounded-lg",attrs:{"cols":"12"}},[(_vm.currentWorkspace.rewriteInvoice && _vm.user.role == 'client')?_c('Button',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"button-clicked":function($event){return _vm.addNewLineitem(invoiceItem)}},scopedSlots:_vm._u([{key:"buttonHeading",fn:function(){return [_c('p',{staticClass:"ma-0 pa-0 white--text"},[_vm._v("Add new Product")])]},proxy:true}],null,true)}):_vm._e()],1)],2),_c('div',{staticClass:"pb-3 overflow-x-auto"},[_c('v-row',{staticClass:"ma-0 pa-0 pt-2 d-flex flex-nowrap"},_vm._l((_vm.headerFields),function(item,index){return _c('v-col',{key:index,staticClass:"ma-0 pa-0",style:({
            border: `thin solid #6634E9`,
            display:
              (item.key === 'taxAmount' &&
                invoiceItem.lineAmountTypes !== 'Exclusive') ||
              (item.key === 'disc' && invoiceItem.type === 'ACCPAY')
                ? 'none !important'
                : '',
          }),attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp ? item.colSize : '3'}},[_c('p',{staticClass:"ma-0 pa-0 fs-14 justify-center d-flex align-end overflow-hidden"},[_c('strong',[_vm._v(_vm._s(item.heading))])])])}),1),_vm._l((invoiceItem.lineItems),function(item,index){return _c('v-row',{key:index,staticClass:"ma-0 pa-0 d-flex flex-nowrap"},_vm._l((_vm.headerFields),function(item1,index1){return _c('v-col',{key:index1,staticClass:"ma-0 pa-0",class:item1.key == 'action' ? '' : 'rounded-xl',style:({
            border: `thin solid #6634E9`,
            display:
              (item1.key === 'taxAmount' &&
                invoiceItem.lineAmountTypes !== 'Exclusive') ||
              (item1.key === 'disc' && invoiceItem.type === 'ACCPAY')
                ? 'none !important'
                : '',
          }),attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp ? item1.colSize : '3'}},[(item1.selectionType == 'textfield')?_c('v-text-field',{staticClass:"text-no-wrap rounded-md custom",attrs:{"outlined":"","placeholder":item.placeholder,"background-color":"inputBackground","hide-details":"","dense":"","min":item1.type == 'number' ? '0' : '',"max":item1.key == 'disc' ? '100' : '',"depressed":"","disabled":item.disabled
                ? true
                : _vm.globalDisableFieldsValue
                ? true
                : !_vm.currentWorkspace.rewriteInvoice
                ? true
                : item1.key == 'lineAmount'
                ? true
                : false,"value":item1.key == 'lineAmount' ? _vm.calcTotalAmount(invoiceItem) : '',"type":item1.type},model:{value:(item[item1.key]),callback:function ($$v) {_vm.$set(item, item1.key, _vm._n($$v))},expression:"item[item1.key]"}}):(
              item1.selectionType == 'select' && item1.key == 'itemCode'
            )?_c('v-select',{staticClass:"text-no-wrap rounded-md custom",attrs:{"disabled":!_vm.currentWorkspace.rewriteInvoice || _vm.user.role == 'employee',"outlined":"","placeholder":item.placeholder,"background-color":"inputBackground","hide-details":"","dense":"","solo":"","items":item['accountCode'] &&
              _vm.xeroHashMaps.accountsHashmap.ids[item['accountCode']]
                ? _vm.xeroHashMaps.accountsHashmap.ids[item['accountCode']].items
                : _vm.xeroHashMaps.itemsHashmap.all,"color":"appColor","no-data-text":_vm.xeroHashMaps.itemsHashmap.all
                ? 'Loading...'
                : 'No Items available',"value":item[item1.key],"item-value":"id","item-text":"name"},on:{"input":function($event){return _vm.selectItem($event, item1.key, item)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"fs-10"},[_vm._v(" "+_vm._s(item.name))])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"fs-10"},[_vm._v(" "+_vm._s(item.name.substr(0, 4))+_vm._s(item.name.length > 4 ? ".." : "")+" ")])]}}],null,true)}):(
              item1.selectionType == 'select' && item1.key == 'accountCode'
            )?_c('v-select',{staticClass:"text-no-wrap rounded-md custom",attrs:{"disabled":!_vm.currentWorkspace.rewriteInvoice || _vm.user.role == 'employee',"outlined":"","placeholder":item.placeholder,"background-color":"inputBackground","hide-details":"","dense":"","solo":"","items":item['itemCode'] &&
              _vm.xeroHashMaps.itemsHashmap.ids[item['itemCode']]
                ? _vm.xeroHashMaps.itemsHashmap.ids[item['itemCode']].accounts
                : _vm.xeroHashMaps.accountsHashmap.all,"no-data-text":_vm.xeroHashMaps.accountsHashmap.all
                ? 'Loading...'
                : 'No accounts available',"color":"appColor","return-object":"","item-text":"name","value":item[item1.key]},on:{"input":function($event){return _vm.selectAccountType($event, item1.key, item)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"fs-10"},[_vm._v(" "+_vm._s(item.name))])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"fs-10"},[_vm._v(" "+_vm._s(item.name.substr(0, 13))+_vm._s(item.name.length > 13 ? ".." : "")+" ")])]}}],null,true)}):(
              item1.selectionType == 'select' && item1.key == 'taxType'
            )?_c('v-select',{staticClass:"text-no-wrap",attrs:{"outlined":"","placeholder":item1.placeholder,"background-color":"inputBackground","hide-details":"","dense":"","solo":"","height":"1","items":_vm.taxRateValues,"color":"appColor","disabled":!_vm.currentWorkspace.rewriteInvoice || _vm.user.role == 'employee',"item-value":"taxType"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',{staticClass:"fs-10 font-style-paragraph mainTextColor--text ma-0 pa-0"},[_vm._v(" "+_vm._s(item.name))])]}},{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"fs-10 font-style-paragraph mainTextColor--text ma-0 pa-0"},[_vm._v(" "+_vm._s(item.name.substr(0, 5))+_vm._s(item.name.length > 5 ? ".." : "")+" ")])]}}],null,true),model:{value:(item[item1.key]),callback:function ($$v) {_vm.$set(item, item1.key, $$v)},expression:"item[item1.key]"}}):(
              item1.selectionType == 'button' && item1.key == 'action'
            )?_c('span',{class:_vm.$vuetify.breakpoint.lgAndUp ? 'mx-3' : 'd-flex justify-center'},[_c('v-icon',{staticClass:"fs-20 mt-2 red--text delete-btn",staticStyle:{"cursor":"pointer"},attrs:{"disabled":!_vm.currentWorkspace.rewriteInvoice || _vm.user.role == 'employee'},on:{"click":function($event){return _vm.deleteField(invoiceItem, item, index)}}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1)}),1)})],2),_c('v-row',{staticClass:"ma-0 pa-0 d-flex justify-end align-center"},[_c('v-col',{attrs:{"lg":"6","md":"8","sm":"12"}},[_c('div',_vm._l((_vm.footerFields),function(item,index){return _c('v-row',{key:index,staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0 d-flex justify-center align-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"fs-10"},[_c('strong',[_vm._v(_vm._s(item.label))])])]),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"9"}},[(item.key != 'currencyCode')?_c('v-text-field',{staticClass:"ma-1 pa-0",attrs:{"autocomplete":false,"flat":"","solo":"","hide-details":"","outlined":"","color":"appColor","placeholder":item.placeholder,"type":item.type,"dense":"","value":item.key == 'subTotal'
                    ? _vm.calcSubTotal(invoiceItem)
                    : item.key == 'totalTax'
                    ? _vm.calcTotalTax(invoiceItem)
                    : item.key == 'total'
                    ? _vm.calcTotal(invoiceItem)
                    : '',"disabled":""}}):_vm._e(),(item.key == 'currencyCode')?_c('v-combobox',{staticClass:"ma-1 pa-0",attrs:{"flat":"","outlined":"","solo":"","hide-details":"","items":_vm.currencies,"disabled":!_vm.currentWorkspace.rewriteInvoice,"value":invoiceItem.currencyCode,"placeholder":"Currency","color":"primary","dense":"","item-value":"code","return-object":false},on:{"input":function($event){return _vm.setCurrency($event)},"focus":function($event){return _vm.fetchCurrency()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"fs-10 font-style-paragraph mainTextColor--text ma-0 pa-0"},[_vm._v(" "+_vm._s(item))])]}},{key:"item",fn:function({ item }){return [(_vm.currentWorkspace.isAccountingSoftConnected)?_c('span',{staticClass:"fs-10 font-style-paragraph mainTextColor--text ma-0 pa-0"},[_vm._v(" "+_vm._s(item.code + " - " + item.description))]):_vm._e(),(!_vm.currentWorkspace.isAccountingSoftConnected)?_c('span',{staticClass:"fs-10 font-style-paragraph mainTextColor--text ma-0 pa-0"},[_vm._v(" "+_vm._s(item))]):_vm._e()]}},{key:"append-item",fn:function(){return [(_vm.currencyLoader)?_c('div',{staticClass:"fs-10 text-center"},[_c('span',[_vm._v("Loading...")])]):_vm._e()]},proxy:true}],null,true)}):_vm._e()],1)],1)}),1)])],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }