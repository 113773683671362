<template>
  <div class="d-flex justify-end">
    <v-combobox
      style="max-width: 79px"
      filled
      hide-details
      dense
      flat
      solo
      persistent-placeholder
      class="text-no-wrap rounded-md fs-12"
      background-color="lightPurple"
      depressed
      :items="currencies"
      :disabled="currentWorkspace.rewriteInvoice || user.role != 'client'"
      :value="invoiceItem.currencyCode"
      @input="$emit('set-currency', $event)"
      @focus="$emit('fetch-currency')"
      placeholder="Curr."
      color="white"
      item-value="code"
      :return-object="false"
      :menu-props="{
        closeOnContentClick: false,
        bottom: true,
        offsetY: true,
      }"
    >
      <template v-slot:selection="{ item }">
        <span class="fs-14 font-style-paragraph white--text ma-0 pa-0">
          {{ item }}</span
        >
      </template>
      <template v-slot:item="{ item }">
        <span
          v-if="currentWorkspace.isAccountingSoftConnected"
          class="fs-12 font-style-paragraph mainTextColor--text ma-0 pa-0"
        >
          {{ item.code + " - " + item.description }}</span
        >
        <span
          v-if="!currentWorkspace.isAccountingSoftConnected"
          class="fs-12 font-style-paragraph mainTextColor--text ma-0 pa-0"
        >
          {{ item }}</span
        >
      </template>
      <template v-slot:append-item>
        <div class="fs-10 text-center" v-if="currencyLoader">
          <span>Loading...</span>
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CurrencyBox",
  components: {},
  data() {
    return {};
  },
  props: {
    currencyLoader: Boolean,
    currencies: Array,
    invoiceItem: Object,
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      user: "auth/getUser",
    }),
  },
  watch: {},
  mounted() {},
  methods: {},
  beforeDestroy() {},
};
</script>

<style scoped>
::v-deep
  .v-text-field--filled.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  min-height: 16px !important;
}

::v-deep
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 6px !important;
}

::v-deep .v-autocomplete.v-select.v-input--is-focused input {
  max-width: 16px !important;
  min-width: 16px !important;
}
</style>
