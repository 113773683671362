var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row d-flex"},[(_vm.roleArray.includes(_vm.message.userRole) == false)?_c('div',[_c('v-avatar',{attrs:{"color":"greyColor","size":"35"}},[_c('v-icon',{attrs:{"color":"white","size":"regular"}},[_vm._v("mdi-robot")])],1)],1):_vm._e(),(_vm.message.file && _vm.message.fileUrl.includes('audio.wav'))?_c('div',[_c('div',{staticClass:"rounded-lg mx-2 px-2 mt-2",staticStyle:{"background-color":"#faf9ff"}},[(_vm.url)?_c('AudioWaveform',{attrs:{"audio":_vm.url,"id":_vm.message._id}}):_vm._e()],1)]):_c('div',{class:[
        _vm.roleArray.includes(_vm.message.userRole) == true
          ? 'msg send py-2 mr-2'
          : 'msg receive py-2 ml-2',
        _vm.message.fileUrl ? 'pa-1' : '',
      ]},[_vm._v(" "+_vm._s(_vm.message.text)+" "),(_vm.message.file)?_c('div',{staticClass:"d-flex justify-end",class:{ 'pt-2': !!_vm.message.text }},[_c('div',{staticClass:"documents",on:{"click":function($event){return _vm.openPrivateUrl(_vm.message.fileUrl)}}},[(
              _vm.message.previewFileName &&
              _vm.message.previewFileName !== 'type not supported'
            )?_c('v-img',{staticClass:"preview-image",attrs:{"src":_vm.backendURL + _vm.message.previewFileName,"width":"204px","height":"111px","cover":""}}):_c('v-img',{staticClass:"preview-image",attrs:{"src":require("@/assets/general-format.jpeg"),"width":"204px","height":"111px","contain":""}}),_c('div',{staticClass:"description"},[(
                [
                  'jpg',
                  'png',
                  'jpeg',
                  'doc',
                  'xls',
                  'xlsx',
                  'pdf',
                  'csv',
                  'zip',
                  'docx',
                ].includes(_vm.message.file.originalname.split('.').pop())
              )?_c('v-img',{staticClass:"file-type",attrs:{"max-width":"26","max-height":"33","src":require(`@/assets/dashboard/${_vm.message.file.originalname
                  .split('.')
                  .pop()}.svg`)}}):_c('v-img',{staticClass:"file-types",attrs:{"max-width":"26","max-height":"33","src":require("@/assets/dashboard/other.svg")}}),_c('div',{staticClass:"detail"},[_c('p',{staticClass:"file-name text-left"},[_vm._v(" "+_vm._s(_vm.message.file.originalname)+" ")]),_c('p',{staticClass:"file-date text-left"},[_vm._v(" Shared Date "+_vm._s(_vm._f("formatDate")(_vm.message.createdAt))+" ")])])],1)],1)]):_vm._e()]),(_vm.roleArray.includes(_vm.message.userRole) == true)?_c('div',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"subHeading","size":"35"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(_vm.message.userDetails.name.charAt(0)))])])]}}],null,false,2586688052)},[_c('span',[_vm._v(_vm._s(_vm.message.userDetails.name))])])],1):_vm._e()]),_c('div',{class:_vm.roleArray.includes(_vm.message.userRole) == true
        ? 'time text-right mr-7'
        : 'time ml-8'},[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.message.createdAt))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }