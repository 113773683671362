var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-viewer-side overflow-x-scroll overflow-y-scroll"},[(
      ['jpg', 'png', 'jpeg', 'svg'].includes(
        _vm.selectedFile?.file?.originalname.split('.').pop()
      ) && _vm.data
    )?_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.file}}):_vm._e(),(
      ['doc', 'docx', 'pptx', 'ppt', 'xlsx', 'xls'].includes(
        _vm.selectedFile?.file?.originalname.split('.').pop()
      ) && _vm.data
    )?_c('VueDocPreview',{attrs:{"value":_vm.file,"type":"office"}}):_vm._e(),(
      ['pdf'].includes(_vm.selectedFile?.file?.originalname.split('.').pop()) &&
      _vm.data
    )?_c('VuePdfEmbed',{attrs:{"source":_vm.file}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }