/* 
** default values needs to be true in order to show in data table at first
** show in List check defines that is customizable or not 
*/
let requestHistoryHeaderList = [
    /*
    ** Default Values at first
    */
    {
        text: "Ticket No",
        value: "ticketNo",
        sortable: false,
        default: true,
        showInList: true,
        showInDefault: true,
    },
    {
        text: "Created At",
        value: "createdAt",
        sortable: false,
        default: true,
        showInList: true,
        showInDefault: true,
    },
    {
        text: "Status",
        value: "status",
        sortable: false,
        id: "v-step-5",
        default: true,
        showInList: true,
        showInDefault: true,
    },
    {
        text: "Contact",
        sortable: false,
        value: "draft.invoices[0].name",
        default: true,
        showInList: true,
        showInDefault: true,
    },
    {
        text: "Total",
        sortable: false,
        value: "draft.invoices[0].total",
        default: true,
        showInList: true,
        showInDefault: true,
    },
    {
        text: "Currency Code",
        sortable: false,
        value: "draft.invoices[0].currencyCode",
        default: true,
        showInList: true,
        showInDefault: true,
    },
    {
        text: "Action",
        value: "action",
        sortable: false,
        default: true,
        showInList: true,
        showInDefault: true,
    },
    /*
    ** Customizable
    */

    // by default shown in list
    {
        text: "Type",
        value: "requestType",
        sortable: false,
        default: true,
        showInList: true,
        showInDefault: true,
    },
    // by default hidden in list and needs to be updated
    {
        text: "Due Date",
        value: "draft.invoices[0].dueDate",
        sortable: false,
        default: false,
        showInList: true,
        showInDefault: false,
    },
    {
        text: "Line Amount Types",
        value: "draft.invoices[0].type",
        sortable: false,
        default: false,
        showInList: true,
        showInDefault: false,
    },
    {
        text: "Sub Total",
        value: "draft.invoices[0].subTotal",
        sortable: false,
        default: false,
        showInList: true,
        showInDefault: false,
    },
    {
        text: "Total Tax",
        value: "draft.invoices[0].totalTax",
        sortable: false,
        default: false,
        showInList: true,
        showInDefault: false,
    },
    // {
    //     text: "Total Discount",
    //     value: "draft.invoices[0].type",
    //     sortable: false,
    //     default: false,
    //     showInList: true,
    // },
    // {
    //     text: "Currency Rate",
    //     value: "draft.invoices[0].currencyRate",
    //     sortable: false,
    //     default: false,
    //     showInList: true,
    // },
    {
        text: "Transaction ID",
        value: "draft.invoices[0].invoiceNumber",
        sortable: false,
        default: false,
        showInList: true,
        showInDefault: false,
    },
    {
        text: "Reference",
        value: "draft.invoices[0].reference",
        sortable: false,
        default: false,
        showInList: true,
        showInDefault: false,
    },
    {
        text: "Branding Theme ID",
        value: "draft.invoices[0].brandingThemeID",
        sortable: false,
        default: false,
        showInList: true,
        showInDefault: false,
    },
    // {
    //     text: "Payments",
    //     value: "draft.invoices[0].type",
    //     sortable: false,
    //     default: false,
    //     showInList: true,
    // },
    // {
    //     text: "Amount Due",
    //     value: "draft.invoices[0].type",
    //     sortable: false,
    //     default: false,
    //     showInList: true,
    // },
    // end
    /*
    ** Not Required but handled by the system
    */
    {
        text: "Description",
        align: "start",
        sortable: false,
        value: "message",
        default: false,
        showInList: false,
        showInDefault: false,
    },
    /*
    ** Not Required and not handled by the system
    */
]

export default requestHistoryHeaderList
