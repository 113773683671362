<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="550px">
      <v-card max-height="380">
        <v-card-title class="text-heading pb-0">
          <p
            class="fs-18 f-inter font-weight-medium heading-text mb-0"
            style="letter-spacing: -0.3px"
          >
            Chart Of Accounts
          </p>
        </v-card-title>
        <v-card-text>
          <v-data-table
            class="map-accounts-table"
            :headers="headers"
            :items="accountsToBeReplaced"
            :hide-default-footer="true"
            fixed-header
          >
            <template v-slot:[`item.description`]="{ item }">
              <v-text-field
                type="text"
                placeholder="Description"
                hide-details
                class="text-no-wrap custom rounded-md fs-12 f-inter fw-400 custom-width"
                background-color="inputBackground"
                filled
                dense
                persistent-placeholder
                :value="item.description"
                disabled
              ></v-text-field>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <v-text-field
                type="text"
                placeholder="Previous COA"
                hide-details
                class="text-no-wrap custom rounded-md fs-12 f-inter fw-400 custom-width"
                background-color="inputBackground"
                filled
                dense
                persistent-placeholder
                :value="item.name"
                disabled
              ></v-text-field>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-autocomplete
                class="text-no-wrap rounded-md fs-12 custom-width"
                placeholder="Update account"
                background-color="inputBackground"
                hide-details
                dense
                flat
                solo
                :items="accounts"
                color="appColor"
                return-object
                @input="setHashMapKey(item.name, $event)"
                item-text="name"
              >
                <template #item="{ item }">
                  <span class="fs-12"> {{ item.name }}</span>
                </template>
                <template #selection="{ item }">
                  <span class="fs-12">
                    {{ item.name.substr(0, 12)
                    }}{{ item.name.length > 12 ? ".." : "" }}
                  </span>
                </template>
              </v-autocomplete>
            </template>
          </v-data-table>
          <!-- <v-row v-for="(account, ind) in accountsToBeReplaced" :key="ind">
              <v-col cols="12" sm="4">
                <v-text-field
                  :type="text"
                  placeholder="Description"
                  hide-details
                  class="text-no-wrap custom rounded-md fs-12 f-inter fw-400"
                  background-color="inputBackground"
                  filled
                  dense
                  persistent-placeholder
                  :value="account.description"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  :type="text"
                  placeholder="Previous COA"
                  hide-details
                  class="text-no-wrap custom rounded-md fs-12 f-inter fw-400"
                  background-color="inputBackground"
                  filled
                  dense
                  persistent-placeholder
                  :value="account.name"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  class="text-no-wrap rounded-md fs-12"
                  placeholder="Update account"
                  background-color="inputBackground"
                  hide-details
                  dense
                  flat
                  solo
                  :items="accounts"
                  color="appColor"
                  return-object
                  @input="setHashMapKey(account.name, $event)"
                  item-text="name"
                >
                  <template #item="{ item }">
                    <span class="fs-12"> {{ item.name }}</span>
                  </template>
                  <template #selection="{ item }">
                    <span class="fs-12">
                      {{ item.name.substr(0, 12)
                      }}{{ item.name.length > 12 ? ".." : "" }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button @button-clicked="clicked('save')" class="mr-2">
            <!-- <template v-if="hasButtonHeadingSlot" v-slot:buttonHeading>
              <p class="ma-0 pa-0 primary--text">{{ hasButtonHeadingSlot }}</p>
            </template> -->
            <template v-slot:buttonHeading>
              <p class="ma-0 pa-0 primary--text">Save</p>
            </template>
          </Button>
          <!-- <Button @button-clicked="clicked('post')" class="mr-2">
            <template v-if="hasButtonHeadingSlot" v-slot:buttonHeading>
              <p class="ma-0 pa-0 primary--text">{{ hasButtonHeadingSlot }}</p>
            </template>
            <template v-slot:buttonHeading>
              <p class="ma-0 pa-0 primary--text">Post</p>
            </template>
          </Button> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
let accountsMappingHashMap = {};
import Button from "../common/button/index.vue";
export default {
  data: () => ({
    headers: [
      {
        text: "Description",
        align: "start",
        sortable: false,
        value: "description",
      },
      { text: "Previous COA", value: "name", sortable: false },
      { text: "Update COA", value: "action", sortable: false },
    ],
  }),
  props: {
    dialog: Boolean,
    accountsToBeReplaced: Array,
    accounts: Array,
  },
  components: {
    Button,
  },
  methods: {
    setHashMapKey(key, account) {
      accountsMappingHashMap[key] = { name: account.name, code: account.code };
    },
    clicked(action) {
      for (let index = 0; index < this.accountsToBeReplaced.length; index++) {
        const account = this.accountsToBeReplaced[index];
        console.log(accountsMappingHashMap, account.name);
        if (!accountsMappingHashMap[account.name])
          return this.$showNotificationBar(
            "error",
            "Please map all the accounts"
          );
      }
      this.$emit("state-changed", { action, accountsMappingHashMap });
    },
  },
  beforeMount() {
    accountsMappingHashMap = {};
  },
};
</script>

<style scoped>
.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
:deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none !important;
}
.custom-width {
  width: 150px;
}
:deep .map-accounts-table td {
  padding: 6px 0px !important;
}
:deep .map-accounts-table .v-data-table__wrapper {
  max-height: 250px !important;
}
:deep .map-accounts-table tbody td {
  border-bottom: none !important;
}
</style>
