var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentWorkspace.rewriteInvoice || _vm.user.role == 'client')?_c('Dialog',{attrs:{"modal":_vm.modalValue},on:{"close-clicked":function($event){_vm.modalValue = false},"save-clicked":_vm.closeChatMiniFunctionForXeroForm},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Confirmation ")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" Are you sure you want to leave, all unsaved changes will be lost ")]},proxy:true},{key:"buttonHeading",fn:function(){return [_vm._v(" Ok ")]},proxy:true}],null,false,1699168206)}):_vm._e(),_c('v-row',{staticClass:"ma-0 pa-0"},[(_vm.$vuetify.breakpoint.lgAndUp && _vm.ticket?.attachmentName)?_c('v-col',{staticClass:"ma-0 pa-0 documents-view",attrs:{"cols":"4"}},[_c('XeroFormFilePreview',{attrs:{"data":_vm.data,"file":_vm.file,"selectedFile":_vm.selectedFile}})],1):_vm._e(),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp && _vm.ticket?.attachmentName ? 8 : 12}},[(_vm.dataLoader)?_c('Loader'):_c('div',[(_vm.dataDraft)?_c('div',{staticClass:"d-flex justify-end mb-2"},[(
              _vm.currentWorkspace.isAccountingSoftConnected &&
              !_vm.currentWorkspace.rewriteInvoice &&
              _vm.user.role == 'client'
            )?_c('Button',{staticClass:"mr-2",attrs:{"loader":_vm.loader,"color":"primary"},on:{"button-clicked":_vm.submitInvoices},scopedSlots:_vm._u([{key:"buttonHeading",fn:function(){return [_c('p',{staticClass:"ma-0 pa-0 white--text text-capitalize"},[_vm._v(" Submit Invoice"),_c('span',{staticClass:"text-lowercase"},[_vm._v("(s)")])])]},proxy:true}],null,false,3417299144)}):_vm._e(),(
              !_vm.currentWorkspace.isAccountingSoftConnected &&
              _vm.user.role == 'client'
            )?_c('Button',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"button-clicked":function($event){_vm.modalValue = true}},scopedSlots:_vm._u([{key:"buttonHeading",fn:function(){return [_c('p',{staticClass:"ma-0 pa-0 white--text"},[_vm._v("Connect Xero")])]},proxy:true}],null,false,702559003)}):_vm._e()],1):_vm._e(),(_vm.dataDraft)?_c('Form',{attrs:{"draft":_vm.dataDraft}}):_c('NoDraft',{attrs:{"ticket":_vm.ticketData}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }