var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.singleFileDialog),callback:function ($$v) {_vm.singleFileDialog=$$v},expression:"singleFileDialog"}},[_c('v-card',{staticClass:"ma-0 pa-0 border-radius-0",attrs:{"flat":""}},[_c('v-app-bar',{attrs:{"flat":"","fixed":"","color":"#2b2442","dense":"","dark":""}},[_c('v-toolbar-title',[_vm._v("File")]),_c('v-spacer'),_c('v-btn',{staticClass:"pr-0 mr-0",attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.selectedFile)?_c('div',{staticClass:"file-viewer-side"},[(
  ['jpg', 'png', 'jpeg'].includes(
    _vm.selectedFile.file.originalname.split('.').pop()
  ) && _vm.data
)?_c('img',{staticClass:"single-img",attrs:{"width":"100%","src":_vm.file}}):_vm._e(),(
  ['doc', 'docx', 'pptx', 'ppt', 'xlsx', 'xls'].includes(
    _vm.selectedFile.file.originalname.split('.').pop()
  ) && _vm.data
)?_c('VueDocPreview',{attrs:{"value":_vm.file,"type":"office"}}):_vm._e(),(
  ['pdf'].includes(_vm.selectedFile.file.originalname.split('.').pop()) &&
  _vm.data
)?_c('VuePdfEmbed',{attrs:{"source":_vm.file}}):_vm._e()],1):_c('div',{staticClass:"file-not-found-document"},[_c('v-img',{attrs:{"src":require("@/assets/dashboard/file.svg"),"width":"200"}}),_c('div',{staticClass:"mt-5 fw-400 fs-20 lh-27 f-avenir fs-normal"},[_vm._v(" No File found ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }