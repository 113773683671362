<template>
  <Dialog
    :modal="publishDialog"
    :buttonLoader="loader"
    @close-clicked="publishDialog = false"
    @save-clicked="publish"
  >
    <template v-slot:header> Confirmation </template>
    <template v-slot:content>
      Are you sure you want to post the selected tickets to Xero?
    </template>
    <template v-slot:buttonHeading> Ok </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../services/Api";
import Dialog from "../../common/dialog-box/index";
export default {
  data() {
    return {
      publishDialog: false,
      loader: false,
      invoices: [],
      savedTickets: [],
    };
  },
  components: {
    Dialog,
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
  },
  methods: {
    openDialog(selectedTickets) {
      console.log(selectedTickets.length);
      if (!this.currentWorkspace.isAccountingSoftConnected) {
        return this.$showNotificationBar(
          "error",
          "Please connect your accounting software"
        );
      }
      let count = 0;
      selectedTickets.forEach((filteredRows) => {
        if (filteredRows.status !== "Saved") {
          return this.$showNotificationBar(
            "error",
            "Please select Saved tickets only"
          );
        }
        if (filteredRows.status === "Saved") {
          this.savedTickets.push({
            ticketId: filteredRows._id,
            invoice: [filteredRows?.draft.invoices[0]],
          });
          count = count + 1;
        }
      });
      if (count === selectedTickets.length) this.publishDialog = true;
      else this.savedTickets = [];
      console.log(this.savedTickets, "INV");
      return;
    },

    // Method to post invoices/bills on Xero
    publish() {
      this.loader = true;
      ApiService.PostRequest("/xero/submit-xero", {
        workspaceId: this.currentWorkspace.workspaceId,
        tickets: this.savedTickets,
      })
        .then((res) => {
          console.log(res.data, "response");
          this.loader = false;
          this.publishDialog = false;
          this.savedTickets = [];
          this.$emit("resetSelected", true);
          // this.$showNotificationBar(
          //   "success",
          //   "Invoice has been published on Xero"
          // );
        })
        .catch((err) => {
          console.log(err.response.data, "ERR");
          this.loader = false;
          this.publishDialog = false;
          this.savedTickets = [];
          this.$emit("resetSelected", true);
        });
    },
  },
};
</script>

<style></style>
