<template>
  <!-- Form Header Fields -->
  <div>
    <div style="max-height: 83vh; min-height: 83vh">
      <v-row class="ma-0 pa-0 py-7 rounded-lg">
        <v-col
          class="ma-0 pa-3"
          v-for="(item, index) in paymentData"
          :key="index + 'A'"
          :cols="item.cols"
        >
          <div>
            <span class="fs-12 f-inter fw-600 d-flex justify-start">{{
              item.label
            }}</span>
            <v-select
              v-if="item.label === 'Bank'"
              class="text-no-wrap rounded-md fs-12"
              flat
              solo
              hide-details
              :items="paidAccounts"
              :no-data-text="
                loadingPaidAccounts ? 'Loading..' : 'No banks available'
              "
              :placeholder="item.placeholder"
              color="appColor"
              dense
              return-object
              @click.stop="getPaidAccounts()"
              @input="selectAccountType($event)"
              item-value="code"
              item-text="label"
            >
              <template #item="{ item }">
                <span
                  class="fs-12 font-style-paragraph mainTextColor--text ma-0 pa-0"
                >
                  {{ item.code }} - {{ item.label }}</span
                >
              </template>
              <template #selection="{ item }">
                <span
                  class="fs-12 font-style-paragraph mainTextColor--text ma-0 pa-0"
                >
                  {{
                    item.code + " - " + item.label.substr(0, 13) + ".."
                  }}</span
                >
              </template>
            </v-select>
            <v-select
              v-else-if="item.key == 'type'"
              class="text-no-wrap rounded-md fs-12"
              background-color="white"
              hide-details
              dense
              flat
              solo
              color="appColor"
              :placeholder="item.placeholder"
              :items="item.typeArray"
              :disabled="
                currentWorkspace.rewriteInvoice || user.role == 'employee'
              "
              v-model="invoiceItem.invoices[0][item.key]"
              item-text="text"
              item-value="value"
            >
              <!-- v-model="invoiceItem[item.key]" -->
            </v-select>
            <v-text-field
              v-else
              filled
              flat
              persistent-placeholder
              class="text-no-wrap rounded-md fs-12"
              background-color="white"
              hide-details
              dense
              depressed
              :type="item.type"
              :placeholder="item.placeholder"
              :min="item.min"
              :max="item.max"
              v-model="payment[item.model]"
              ></v-text-field>
              <!-- @input="item.model === 'amount' ? textFieldOnChange() : ''" -->
          </div>
        </v-col>
      </v-row>
      <v-data-table
        fixed-header
        dense
        :headers="mainHeaders"
        :items="invoices"
        :hide-default-footer="true"
        :loading="invoiceLoader"
        show-select
        item-key="invoiceNumber"
        :single-select="true"
        v-model="selected"
        :checkbox-color="'lightPurple'"
        class="textPrimary--text f-inter fw-400 fs-12 overflow-y-auto"
        style="max-height: 61vh"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:[`item.date`]="{ item }">
          <span>
            {{ item.date | formatISODate }}
          </span>
        </template>
      </v-data-table>
      <!-- <div v-if="moreBtnLoader" class="d-flex justify-center">
        <span
          class="f-inter fs-12 fw-500 pa-3 primary--text underline"
          style="cursor: pointer"
          @click="getMoreInvoice()"
          >Load More ....</span
        >
      </div> -->
    </div>
    <div class="static-div">
      <div class="d-flex justify-end">
        <v-btn
          class="fs-12 cursor-pointer primary--text text-capitalize fw-600 custom-btn"
          style="letter-spacing: -0.2px"
          @click="submitPayment()"
          :loading="paymentLoader"
        >
          Submit
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/Api.js";
import UrlServices from "@/services/Url";
export default {
  name: "CurrencyBox",
  components: {},
  data() {
    return {
      paymentData: [
        {
          title: "Date",
          placeholder: "Enter date",
          label: "Date",
          requiredField: [(v) => !!v || "Field is required"],
          type: "date",
          maxLength: "50",
          model: "date",
          disabled: false,
          min: 0,
          max: "9999-12-31",
          cols: 2,
        },
        {
          title: "Bank",
          placeholder: "Enter Bank",
          label: "Bank",
          requiredField: [(v) => !!v || "Field is required"],
          type: "text-field",
          disabled: false,
          cols: 3,
        },
        {
          title: "Amount",
          placeholder: "Enter amount",
          label: "Amount",
          requiredField: [(v) => !!v || "Field is required"],
          type: "number",
          maxLength: "50",
          model: "amount",
          disabled: false,
          min: 0,
          max: null,
          cols: 2,
        },
        // {
        //   label: "Type",
        //   placeholder: "Type",
        //   type: "string",
        //   key: "type",
        //   model: "type",
        //   typeArray: [
        //     {
        //       text: "Invoice",
        //       value: "ACCREC",
        //     },
        //     {
        //       text: "Bill",
        //       value: "ACCPAY",
        //     },
        //     {
        //       text: "Payment",
        //       value: "PAID",
        //     },
        //     ,
        //   ],
        //   cols: 2,
        // },
      ],
      payment: {
        invoice: { invoiceID: null },
        account: { code: null },
        date: "",
        amount: "",
      },
      datePaid: false,
      paymentLoader: false,
      invoices: [],
      paidAccounts: [],
      invoiceLoader: false,
      loadingPaidAccounts: false,
      moreBtnLoader: false,
      responseSuccessOfPaidAccounts: false,

      selected: [],
      mainHeaders: [
        {
          text: "Contact",
          value: "contact.name",
          sortable: false,
          default: true,
          showInList: true,
          showInDefault: true,
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
          default: true,
          showInList: true,
          showInDefault: true,
        },
        {
          text: "Invoice #",
          value: "invoiceNumber",
          sortable: false,
          default: true,
          showInList: true,
          showInDefault: true,
        },
        {
          text: "Amount Due",
          value: "amountDue",
          sortable: false,
          default: true,
          showInList: true,
          showInDefault: true,
        },
      ],
      timer: null,
      itemsPerPage: -1,
    };
  },
  props: {
    ticket: Object,
    invoiceItem: Object,
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      user: "auth/getUser",
    }),
  },
  watch: {},
  mounted() {
    this.getInvoices();
  },
  beforeDestroy() {},
  methods: {
    textFieldOnChange() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        // Perform your search logic or function call here
        this.getInvoices();
      }, 400); // Adjust the debounce delay as per your requirements
    },
    /**
     * Method to get Paid Accounts from xero
     */
    getPaidAccounts() {
      if (this.responseSuccessOfPaidAccounts) return;
      this.loadingPaidAccounts = true;
      ApiService.GetRequest(
        `${UrlServices.getBankAccounts}/${this.ticket.workspaceId}?enablePaymentsToAccount=true`
      )
        .then((res) => {
          let paidAccounts = [];
          this.responseSuccessOfPaidAccounts = true;
          res.data?.accounts.forEach((item) => {
            paidAccounts.push({
              label: item.name,
              code: item.type !== "BANK" ? item.code : item.bankAccountNumber,
              accountID: item.accountID,
            });
          });
          this.paidAccounts = paidAccounts;
          this.loadingPaidAccounts = false;
        })
        .catch((err) => {
          console.log(err);
          this.$showNotificationBar("error", err);
        });
    },
    /**
     * Method to get invoices onload data in dropdown
     */
    getInvoices() {
      this.invoiceLoader = true;
      this.moreBtnLoader = false;
      this.invoices = [];
      ApiService.GetRequest(
        `${UrlServices.getPaymentApprovalInvoices}/${this.ticket.workspaceId}?amountDue=${this.payment.amount}`
      )
        .then((res) => {
          let invoices = [];
          res.data?.data.forEach((item) => {
            invoices.push({
              label: item.invoiceNumber,
              reference: item.reference,
              amountDue: item.amountDue,
              invoiceID: item.invoiceID,
              contact: item.contact,
              invoiceNumber: item.invoiceNumber,
              dueDate: item.dueDate,
              date: item.date,
            });
          });
          this.invoices = invoices;
          if (this.invoices.length < 100) {
            // Don't show load more button
            this.moreBtnLoader = false;
          } else {
            // Show load more button
            this.moreBtnLoader = true;
          }
          this.invoiceLoader = false;
        })
        .catch((err) => {
          this.invoiceLoader = false;
          this.$showNotificationBar("error", err);
        });
    },
    /**
     * Method to get more invoices onclick Load More
     */
    getMoreInvoice() {
      this.getInvoices();
    },
    /**
     * This function calls when select 'cancel' button from date-menu
     */
    noDateSelected() {
      if (!this.date) this.date = "";
      this.datePaid = false;
    },
    /**
     * Method to set account code
     */
    selectAccountType(e) {
      this.payment.account.accountID = e.accountID;
    },
    /**
     * Method to set invoice code
     */
    selectInvoiceType(e) {
      this.payment.invoice.invoiceID = e.invoiceID;
    },
    /**
     * Method to submit Payment on Xero
     * Here check Validations first, before submitting payment amount
     */
    submitPayment() {
      if (this.selected.length === 0) {
        return this.$showNotificationBar("error", "Invoice is required");
      }
      this.payment.invoice.invoiceID = this.selected[0].invoiceID;
      const payment = JSON.parse(JSON.stringify(this.payment));
      if (!payment.account.accountID) {
        return this.$showNotificationBar("error", "Account Code is required");
      }
      if (!payment.date) {
        return this.$showNotificationBar("error", "Date is required");
      }
      if (!payment.amount) {
        return this.$showNotificationBar("error", "Amount is required");
      }
      if (payment.amount <= 0) {
        return this.$showNotificationBar(
          "error",
          "Payment amounts must be positive amounts"
        );
      }

      //   if (!payment.invoice.invoiceID) {
      //     return this.$showNotificationBar("error", "Invoice is required");
      //   }
      this.paymentLoader = true;
      delete payment.account.code;
      ApiService.PostRequest(
        `${UrlServices.submitInvoicePayment}/${this.ticket.workspaceId}`,
        { payment: payment, ticketId: this.ticket._id }
      )
        .then((res) => {
          this.paymentLoader = false;
          this.$showNotificationBar("success", "Payment has been submitted");
          this.$emit("payment-submitted", true);
        })
        .catch((err) => {
          this.paymentLoader = false;
          this.$showNotificationBar("error", err);
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
::v-deep
  .v-data-table--fixed-header
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  background-color: var(--v-lightPurple-base) !important;
  color: var(--v-offWhite-base) !important;
  border: 1px solid var(--v-offWhite-base);
}

::v-deep
  .v-data-table--dense
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  border: 0.1px solid rgba(0, 0, 0, 0.12);
}

.static-div {
  /* background-color: white; */
  text-align: center; /* Optional centering content */
  border-top: 1px solid #dbdadf;
  overflow-y: scroll;
  padding: 10px;
}
.custom-btn {
  background-color: rgba(53, 16, 167, 0.05) !important;
  border-radius: 8px;
  min-height: 35px;
  line-height: 19px;
  color: var(--v-lightPurple-base) !important;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  margin-left: 10px;
}
</style>
