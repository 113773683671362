var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.mobileFilesDialog),callback:function ($$v) {_vm.mobileFilesDialog=$$v},expression:"mobileFilesDialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-app-bar',{attrs:{"flat":"","fixed":"","color":"#2b2442","dense":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Ticket ID "+_vm._s(_vm.ticket.ticketNo))]),_c('v-spacer'),_c('v-btn',{staticClass:"pr-0 mr-0",attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.ticket.files?.length >= 1 || _vm.ticket.attachmentUrl)?_c('v-row',{staticClass:"ma-2 mt-15",attrs:{"justify":"center"}},[_c('v-expansion-panels',{staticClass:"documents-list",attrs:{"accordion":"","flat":""}},[(_vm.ticket.attachmentUrl)?_c('v-expansion-panel',{staticClass:"documents"},[_c('v-expansion-panel-header',{class:_vm.selectedFile._id == _vm.ticket._id
                ? 'active cursor-pointer border-1 mb-1'
                : 'cursor-pointer border-1 mb-1',attrs:{"expand-icon":""},on:{"click":function($event){return _vm.openSingleFile(_vm.ticket)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(
                    _vm.ticket.previewFileName &&
                    _vm.ticket.previewFileName !== 'type not supported'
                  )?_c('v-img',{staticClass:"preview-image",attrs:{"src":_vm.backendURL + _vm.ticket.previewFileName,"width":"304px","height":"111px","cover":""}}):_c('v-img',{staticClass:"preview-image",attrs:{"src":require("@/assets/general-format.jpeg"),"width":"304px","height":"111px","contain":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[(
                    [
                      'jpg',
                      'png',
                      'jpeg',
                      'pdf',
                      'docs',
                      'doc',
                      'xls',
                      'xlsx',
                      'csv',
                      'docx',
                      'zip',
                    ].includes(_vm.ticket.attachmentUrl.split('.').pop())
                  )?_c('v-img',{staticClass:"file-type",attrs:{"max-width":"26","max-height":"33","src":require(`@/assets/dashboard/${_vm.ticket.attachmentUrl
                      .split('.')
                      .pop()}.svg`)}}):_c('v-img',{staticClass:"file-types",attrs:{"max-width":"26","max-height":"33","src":require("@/assets/dashboard/other.svg")}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"file-name mt-1"},[_vm._v(" "+_vm._s(_vm.ticket.attachmentName ? _vm.ticket.attachmentName : "Attached File")+" ")]),_c('p',{staticClass:"secondary--text"},[_vm._v(" Shared Date "+_vm._s(_vm._f("formatDate")(_vm.ticket.createdAt))+" ")])])],1)],1)],1):_vm._e(),_vm._l((_vm.ticket.files),function(item,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{class:_vm.selectedFile._id == item._id
                ? 'active cursor-pointer border-1 mb-1'
                : 'cursor-pointer border-1 mb-1',attrs:{"expand-icon":""},on:{"click":function($event){return _vm.openSingleFile(item)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(
                    item.previewFileName &&
                    item.previewFileName !== 'type not supported'
                  )?_c('v-img',{staticClass:"preview-image",attrs:{"src":_vm.backendURL + item.previewFileName,"width":"304px","height":"111px","cover":""}}):_c('v-img',{staticClass:"preview-image",attrs:{"src":require("@/assets/general-format.jpeg"),"width":"304px","height":"111px","contain":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[(
                    [
                      'jpg',
                      'png',
                      'jpeg',
                      'pdf',
                      'docs',
                      'doc',
                      'xls',
                      'xlsx',
                      'csv',
                      'docx',
                      'zip',
                    ].includes(item.file.originalname.split('.').pop())
                  )?_c('v-img',{staticClass:"file-type",attrs:{"width":"45","src":require(`@/assets/dashboard/${item.file.originalname
                      .split('.')
                      .pop()}.svg`)}}):_c('v-img',{staticClass:"file-types",attrs:{"max-width":"26","max-height":"33","src":require("@/assets/dashboard/other.svg")}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"file-name mt-1"},[_vm._v(_vm._s(item.file.originalname))]),_c('p',{staticClass:"secondary--text"},[_vm._v(" Shared Date "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")])])],1)],1)],1)})],2)],1):_c('div',{staticClass:"file-not-found-document"},[_c('v-img',{attrs:{"src":require("@/assets/dashboard/file.svg"),"width":"200"}}),_c('div',{staticClass:"mt-5 fw-400 fs-20 lh-27 f-avenir fs-normal"},[_vm._v(" No File Found ")])],1)],1),(_vm.FileDialog)?_c('SingleFile',{attrs:{"showSingleFile":_vm.showSingleFile},on:{"close-single-file-dialog":() => {
        (_vm.FileDialog = false), (_vm.showSingleFile = '');
      }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }