import { render, staticRenderFns } from "./MessageFormat.vue?vue&type=template&id=219f9f74&scoped=true&"
import script from "./MessageFormat.vue?vue&type=script&lang=js&"
export * from "./MessageFormat.vue?vue&type=script&lang=js&"
import style0 from "./MessageFormat.vue?vue&type=style&index=0&id=219f9f74&prod&lang=scss&scoped=true&"
import style1 from "./MessageFormat.vue?vue&type=style&index=1&id=219f9f74&prod&lang=css&"
import style2 from "./MessageFormat.vue?vue&type=style&index=2&id=219f9f74&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219f9f74",
  null
  
)

export default component.exports