var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentWorkspace.rewriteInvoice || _vm.user.role == 'client')?_c('Dialog',{attrs:{"modal":_vm.modalValue},on:{"close-clicked":function($event){_vm.modalValue = false},"save-clicked":_vm.closeChatMiniFunctionForXeroForm},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Confirmation ")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" Are you sure you want to leave, all unsaved changes will be lost ")]},proxy:true},{key:"buttonHeading",fn:function(){return [_vm._v(" Ok ")]},proxy:true}],null,false,1699168206)}):_vm._e(),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":12}},[(!_vm.dataLoader && _vm.dataDraft?.invoices[0]?.type !== 'PAID')?_c('div',{staticClass:"container",style:(_vm.paymentField === true
            ? 'min-height: 90vh; max-height: 90vh'
            : 'min-height: 90vh; max-height: 90vh')},[_c('div',{staticClass:"scrollable-div"},[_c('Form',{ref:"invoiceForm",attrs:{"paymentField":_vm.paymentField,"draft":_vm.dataDraft,"ticket":_vm.ticketData,"accounts":_vm.accounts,"trackingCategories":_vm.trackingCategories,"items":_vm.items},on:{"new-invoice-object":_vm.setNewInvoiceObject}})],1),(
            !_vm.currentWorkspace.rewriteInvoice &&
            _vm.user.role == 'client' &&
            (_vm.ticket.draft || _vm.ticket.status === 'Complete') &&
            _vm.paymentField === false
          )?_c('div',{staticClass:"static-div"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[(
                  _vm.currentWorkspace.isAccountingSoftConnected &&
                  _vm.user.role == 'client' &&
                  (_vm.ticket.draft || _vm.ticket.status === 'Complete')
                )?_c('v-btn',{staticClass:"fs-12 mr-2 cursor-pointer primary--text text-capitalize fw-600 custom-btn",staticStyle:{"letter-spacing":"-0.2px"},on:{"click":function($event){return _vm.openPaymentField()}}},[_vm._v(" Add Payment ")]):_vm._e()],1),_c('div',[(
                  _vm.user.role == 'client' &&
                  (_vm.ticket.draft || _vm.ticket.status === 'Complete')
                )?_c('v-btn',{staticClass:"fs-12 cursor-pointer primary--text text-capitalize fw-600 custom-btn",staticStyle:{"letter-spacing":"-0.2px"},attrs:{"loading":_vm.draftLoader},on:{"click":function($event){return _vm.submitDraft()}}},[_vm._v(" Save As Draft ")]):_vm._e(),(
                  _vm.currentWorkspace.isAccountingSoftConnected &&
                  _vm.user.role == 'client' &&
                  (_vm.ticket.draft || _vm.ticket.status === 'Complete')
                )?_c('v-btn',{staticClass:"fs-12 mr-2 cursor-pointer primary--text text-capitalize fw-600 custom-btn",staticStyle:{"letter-spacing":"-0.2px"},attrs:{"loading":_vm.loader},on:{"click":function($event){return _vm.submitInvoices()}}},[_vm._v(" Approve "),_c('v-tooltip',{attrs:{"top":"","color":"numberField","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,1587192233)},[_c('span',{staticClass:"black--text fs-10 fw-400"},[_vm._v("This will directly approve the transaction and record it in the accounting software")])])],1):_vm._e()],1)])]):_vm._e(),(_vm.paymentField === true)?_c('div',{staticClass:"static-div-payment"},[_c('PaymentBar',{attrs:{"ticket":_vm.ticketData,"paymentLoader":_vm.paymentLoader},on:{"payment-bar-submission":_vm.invoiceSubmissionWithPayment}})],1):_vm._e()]):(_vm.dataDraft?.invoices[0]?.type === 'PAID')?_c('div',[_c('PaymentForm',{attrs:{"ticket":_vm.ticketData,"invoiceItem":_vm.dataDraft},on:{"payment-submitted":_vm.closePaymentForm}})],1):_vm._e()])],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('AccountsComparisonDialog',{attrs:{"accountsToBeReplaced":_vm.accountsToBeReplaced,"accounts":_vm.accounts.accounts,"dialog":_vm.accountComparisonDialog},on:{"state-changed":_vm.actionPerformed}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }