<template>
  <!-- Form Header Fields -->
  <v-row class="ma-0 pa-0 rounded-lg">
    <v-col
      class="ma-0 pa-3"
      cols="3"
      v-for="(item, index) in paymentData"
      :key="index + 'A'"
    >
      <div>
        <span class="fs-12 f-inter fw-600 d-flex justify-start">{{
          item.label
        }}</span>
        <v-select
          v-if="item.label === 'Bank'"
          :menu-props="{ maxHeight: '90px', top: true, offsetY: true }"
          class="text-no-wrap rounded-md fs-12"
          flat
          solo
          hide-details
          :items="paidAccounts"
          :no-data-text="
            loadingPaidAccounts ? 'Loading..' : 'No banks available'
          "
          :placeholder="item.placeholder"
          color="appColor"
          dense
          return-object
          @click.stop="getPaidAccounts()"
          @input="selectAccountType($event)"
          item-value="code"
          item-text="label"
        >
          <template #item="{ item }">
            <span
              class="fs-12 font-style-paragraph mainTextColor--text ma-0 pa-0"
            >
              {{ item.code }} - {{ item.label }}</span
            >
          </template>
          <template #selection="{ item }">
            <span
              class="fs-12 font-style-paragraph mainTextColor--text ma-0 pa-0"
            >
              {{ item.code + " - " + item.label.substr(0, 13) + ".." }}</span
            >
          </template>
        </v-select>
        <v-text-field
          v-else
          filled
          flat
          persistent-placeholder
          class="text-no-wrap rounded-md fs-12"
          background-color="white"
          hide-details
          dense
          depressed
          :type="item.type"
          :placeholder="item.placeholder"
          :min="item.min"
          :max="item.max"
          v-model="payment[item.model]"
        ></v-text-field>
      </div>
    </v-col>
    <v-col cols="3" class="d-flex align-end justify-end ma-0 pa-3">
      <v-btn
        class="fs-12 mr-2 cursor-pointer primary--text text-capitalize fw-600 custom-btn"
        style="letter-spacing: -0.2px"
        @click="submitPayment()"
        :loading="paymentLoader"
      >
        Add Payment
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/Api.js";
import UrlServices from "@/services/Url";
export default {
  name: "CurrencyBox",
  components: {},
  data() {
    return {
      paymentData: [
        {
          title: "Date",
          placeholder: "Enter date",
          label: "Date",
          requiredField: [(v) => !!v || "Field is required"],
          type: "date",
          maxLength: "50",
          model: "date",
          disabled: false,
          min: 0,
          max: "9999-12-31",
        },
        {
          title: "Bank",
          placeholder: "Enter Bank",
          label: "Bank",
          requiredField: [(v) => !!v || "Field is required"],
          type: "text-field",
          disabled: false,
        },
        {
          title: "Amount",
          placeholder: "Enter amount",
          label: "Amount",
          requiredField: [(v) => !!v || "Field is required"],
          type: "number",
          maxLength: "50",
          model: "amount",
          disabled: false,
          min: 0,
          max: null,
        },
      ],
      payment: {
        invoice: { invoiceID: null },
        account: { code: null },
        date: "",
        amount: "",
      },
      datePaid: false,
      // paymentLoader: false,
      invoices: [],
      paidAccounts: [],
      invoiceLoader: false,
      loadingPaidAccounts: false,
      moreBtnLoader: false,
      responseSuccessOfPaidAccounts: false,
    };
  },
  props: {
    ticket: Object,
    paymentLoader: Boolean,
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      user: "auth/getUser",
    }),
  },
  watch: {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    /**
     * Method to get Paid Accounts from xero
     */
    getPaidAccounts() {
      if (this.responseSuccessOfPaidAccounts) return;
      this.loadingPaidAccounts = true;
      ApiService.GetRequest(
        `${UrlServices.getBankAccounts}/${this.ticket.workspaceId}?enablePaymentsToAccount=true`
      )
        .then((res) => {
          let paidAccounts = [];
          this.responseSuccessOfPaidAccounts = true;
          res.data?.accounts.forEach((item) => {
            paidAccounts.push({
              label: item.name,
              code: item.type !== "BANK" ? item.code : item.bankAccountNumber,
              accountID: item.accountID,
            });
          });
          this.paidAccounts = paidAccounts;
          this.loadingPaidAccounts = false;
        })
        .catch((err) => {
          console.log(err);
          this.$showNotificationBar("error", err);
        });
    },
    // /**
    //  * Method to get invoices onload data in dropdown
    //  */
    // getInvoices() {
    //   this.invoiceLoader = true;
    //   this.moreBtnLoader = false;
    //   this.invoices = [];
    //   ApiService.GetRequest(
    //     `${UrlServices.getPaymentApprovalInvoices}/${this.ticket.workspaceId}?amountDue=${this.payment.amount}`
    //   )
    //     .then((res) => {
    //       let invoices = [];
    //       res.data?.data.forEach((item) => {
    //         invoices.push({
    //           label: item.invoiceNumber,
    //           reference: item.reference,
    //           amountDue: item.amountDue,
    //           invoiceID: item.invoiceID,
    //         });
    //       });
    //       this.invoices = invoices;
    //       if (this.invoices.length < 100) {
    //         // Don't show load more button
    //         this.moreBtnLoader = false;
    //       } else {
    //         // Show load more button
    //         this.moreBtnLoader = true;
    //       }
    //       this.invoiceLoader = false;
    //     })
    //     .catch((err) => {
    //       this.invoiceLoader = false;
    //       this.$showNotificationBarWithError(err);
    //     });
    // },
    /**
     * Method to get more invoices onclick Load More
     */
    getMoreInvoice() {
      this.getInvoices();
    },
    /**
     * This function calls when select 'cancel' button from date-menu
     */
    noDateSelected() {
      if (!this.date) this.date = "";
      this.datePaid = false;
    },
    /**
     * Method to set account code
     */
    selectAccountType(e) {
      this.payment.account.accountID = e.accountID;
    },
    /**
     * Method to set invoice code
     */
    selectInvoiceType(e) {
      this.payment.invoice.invoiceID = e.invoiceID;
    },
    /**
     * Method to submit Payment on Xero
     * Here check Validations first, before submitting payment amount
     */
    submitPayment() {
      const payment = JSON.parse(JSON.stringify(this.payment));
      if (!payment.account.accountID) {
        return this.$showNotificationBar("error", "Account Code is required");
      }
      if (!payment.date) {
        return this.$showNotificationBar("error", "Date is required");
      }
      if (!payment.amount) {
        return this.$showNotificationBar("error", "Amount is required");
      }
      if (payment.amount <= 0) {
        return this.$showNotificationBar(
          "error",
          "Payment amounts must be positive amounts"
        );
      }
      this.$emit("payment-bar-submission", payment);
      // if (!payment.invoice.invoiceID) {
      //   return this.$showNotificationBar("error", "Invoice is required");
      // }

      // this.paymentLoader = true;
      // delete payment.account.code;
      // ApiService.PostRequest(
      //   `${UrlServices.submitInvoicePayment}/${this.ticket.workspaceId}`,
      //   { payment: payment, ticketId: this.ticket._id }
      // )
      //   .then((res) => {
      //     this.paymentLoader = false;
      //     this.$showNotificationBar("success", "Payment has been submitted");
      //   })
      //   .catch((err) => {
      //     this.paymentLoader = false;
      //     this.$showNotificationBar("error", err);
      //     console.log(err);
      //   });
    },
  },
};
</script>

<style scoped>
.custom-btn {
  background-color: rgba(53, 16, 167, 0.05) !important;
  border-radius: 8px;
  min-height: 35px;
  line-height: 19px;
  color: var(--v-lightPurple-base) !important;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  margin-left: 10px;
}
</style>
