import { render, staticRenderFns } from "./FilesDetail.vue?vue&type=template&id=28565208&scoped=true&"
import script from "./FilesDetail.vue?vue&type=script&lang=js&"
export * from "./FilesDetail.vue?vue&type=script&lang=js&"
import style0 from "./FilesDetail.vue?vue&type=style&index=0&id=28565208&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28565208",
  null
  
)

export default component.exports