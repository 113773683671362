<template>
  <Dialog
    :modal="confirmDialog"
    :buttonLoader="approvedLoader"
    @close-clicked="confirmDialog = false"
    @save-clicked="acceptOrReject"
  >
    <template v-slot:header> Confirmation </template>
    <template v-slot:content>
      <span v-if="approved">
        Are you sure you want to approve the selected tickets?
      </span>
      <span v-else-if="reject">
        Are you sure you want to reject the selected tickets?
      </span>
    </template>
    <template v-slot:buttonHeading> Ok </template>
  </Dialog>
</template>

<script>
import ApiService from "../../../services/Api";
import UrlServices from "../../../services/Url";
import Dialog from "../../common/dialog-box/index";
export default {
  data() {
    return {
      confirmDialog: false,
      approved: false,
      reject: false,
      approvedLoader: false,
      auditStatus: "",
      selectedTicketIds: [],
    };
  },
  components: {
    Dialog,
  },

  methods: {
    openDialog(status, selectedTickets) {
      let count = 0;
      selectedTickets.forEach((filteredRows) => {
        if (filteredRows.status !== "Audit") {
          return this.$showNotificationBar(
            "error",
            "Please select tickets with audit status only"
          );
        }
        if (filteredRows.status === "Audit") {
          this.selectedTicketIds.push(filteredRows._id);

          this.auditStatus = status;
          if (status == "approved") {
            this.approved = true;
            this.reject = false;
          } else if (status == "rejected") {
            this.reject = true;
            this.approved = false;
          }
          count = count + 1;
        }
      });
      if (count === selectedTickets.length) this.confirmDialog = true;
      else this.selectedTicketIds = [];
      console.log(this.selectedTicketIds, "IDS");
      return;
    },

    // Method to approve or reject tickets
    acceptOrReject() {
      this.approvedLoader = true;
      ApiService.PostRequest(`${UrlServices.updatedAuditTicketStatus}`, {
        status: this.auditStatus,
        ticketIds: this.selectedTicketIds,
      })
        .then((res) => {
          this.approvedLoader = false;
          this.confirmDialog = false;
          this.auditStatus = "";
          this.selectedTicketIds = [];
          this.$emit("resetSelected", true);
          console.log(res.data, "response");
        })
        .catch((err) => {
          this.$showNotificationBar("error", err.response.data.message);
          this.approvedLoader = false;
          this.confirmDialog = false;
          this.auditStatus = "";
          this.selectedTicketIds = [];
          this.$emit("resetSelected", true);
          console.log(err.response, "err");
        });
    },
  },
};
</script>

<style></style>
