<template>
  <div class="text-center">
    <Dialog
      :modal="reviewRequestDialog"
      @close-clicked="reviewRequestDialog = false"
      @save-clicked="reviewRequest()"
    >
      <template v-slot:header> Confirmation </template>
      <template v-slot:content>
        Are you sure you want to review this ticket ?
      </template>
    </Dialog>
    <Dialog
      :modal="previewFileDialog.dialog"
      @close-clicked="closePreviewFileDialog()"
      width="1000px"
    >
      <template v-slot:header>
        Ticket ID {{ previewFileDialog.headingName }}
      </template>
      <template v-slot:content>
        <iframe
          :src="previewFileDialog.url"
          width="100%"
          height="800px"
          style="border: none"
        />
      </template>
      <template v-slot:doNotShowButtonHeadingSlot>true</template>
    </Dialog>
    <Dialog
      :modal="modalValue"
      @close-clicked="closeClickedDialogXeroForm"
      @save-clicked="saveClickedDialogXeroForm"
    >
      <template v-slot:header> Confirmation </template>
      <template v-slot:content>
        Are you sure you want to leave, all unsaved changes will be lost
      </template>
      <template v-slot:buttonHeading> Ok </template>
    </Dialog>
    <v-dialog v-model="showpopup" content-class="messagebox" persistent>
      <!-- width="100%" -->
      <v-row class="ma-0 pa-0">
        <!-- class="messagebox-parent" -->
        <v-col class="ma-0 pa-0" cols="12">
          <v-card-title class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0 left-side-message-box">
                <div class="d-flex justify-space-between">
                  <div class="d-flex justify-center align-center">
                    <v-select
                      style="width: 150px"
                      class="fs-16 fw-600 f-inter text-no-wrap rounded-md"
                      background-color="white"
                      hide-details
                      dense
                      flat
                      solo
                      color="appColor"
                      placeholder="Invoice Type"
                      :items="invoiceTypeArray"
                      :disabled="
                        currentWorkspace.rewriteInvoice ||
                        user.role == 'employee'
                      "
                      item-text="text"
                      item-value="value"
                      v-on:change="changeInvoiceType"
                      v-model="invoiceTypeSelectedValue"
                    >
                    </v-select>
                    <p
                      class="ma-0 pa-0 fs-16 fw-600 f-inter d-flex align-center"
                    >
                      <span class="fs-16 fw-600">{{
                        newDraftData && newDraftData.length > 0
                          ? newDraftData[0]?.invoiceNumber
                          : ticket?.draft?.invoices[0]?.invoiceNumber
                      }}</span>
                    </p>
                  </div>

                  <!-- class="material-icons custom-close-icon" -->
                  <v-btn
                    icon
                    fab
                    x-small
                    color="textPrimary"
                    class="mt-2 mx-4"
                    @click="
                      $emit('close-popup', {
                        key1: !currentWorkspace.rewriteInvoice
                          ? openXeroForm
                          : false,
                        key2: compareDraftData(),
                        key3: submittedData,
                      })
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-col>
        <v-col
          class="reportTotal"
          md="8"
          sm="12"
          style="max-width: 70%; flex: 70%; height: 100vh"
        >
          <ChatBox
            v-if="!openXeroForm"
            :ticket="ticket"
            @file-sent="fileSent()"
          ></ChatBox>

          <!-- <XeroForm :ticket="ticket" v-if="openXeroForm" /> -->
          <XeroFormNew
            :ticket="ticket"
            v-if="openXeroForm"
            @submitted-data="submittedData = true"
          />
        </v-col>
        <v-col
          class="detail-tabs"
          md="4"
          sm="12"
          style="max-width: 30%; height: 100vh"
        >
          <v-tabs fixed-tabs v-model="detailtab" class="">
            <!-- custom-tab-margin -->
            <v-tab
              @click="openXeroForm = true"
              key="Attachments"
              class="fs-13 fw-500 f-inter ml-1 mt-1 mb-1"
              style="letter-spacing: -0.3px"
              >Images</v-tab
            >
            <v-tab
              @click="openXeroForm = true"
              key="Messages"
              class="fs-13 fw-500 f-inter ml-1 mt-1 mb-1"
              style="letter-spacing: -0.3px"
              >Messages</v-tab
            >
            <v-tab
              @click="openXeroForm = true"
              key="Details"
              class="fs-13 fw-500 f-inter mt-1 mb-1 ml-1"
              style="letter-spacing: -0.3px"
              >Details</v-tab
            >
            <v-tabs-slider color="white"></v-tabs-slider>
          </v-tabs>

          <v-tabs-items v-model="detailtab">
            <!-- Attachments -->
            <v-tab-item key="Attachments">
              <v-card flat class="file-tab-row">
                <div v-if="attachment">
                  <XeroFormFilePreview
                    class="custom-border mt-2"
                    :data="data"
                    :file="file"
                    :selectedFile="selectedFile"
                  />
                </div>
                <div
                  v-else
                  style="min-height: 70vh"
                  class="d-flex justify-center align-center"
                >
                  <div class="d-flex flex-column">
                    <NoAttachmentIcon />
                    <span class="mt-4 ml-4"> No attachment </span>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
            <!-- Messages -->
            <v-tab-item key="Messages">
              <chat-box :ticket="ticket" @file-sent="fileSent()"></chat-box>
            </v-tab-item>
            <!-- Details -->
            <v-tab-item key="Details">
              <v-card flat style="overflow-y: scroll !important">
                <v-container>
                  <div class="row mt-1">
                    <div
                      class="col-md-5 fs-normal fw-500 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      Invoice ID
                    </div>
                    <div
                      class="col-md-7 fs-normal fw-400 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      {{ ticket.ticketNo }}
                    </div>
                    <div
                      class="col-md-5 fs-normal fw-500 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      Status
                    </div>
                    <div
                      class="col-md-7 fs-normal fw-400 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      <v-chip
                        v-for="status in statusOptions"
                        :key="status.value"
                        :class="`status-chip px-2 f-inter fw-400 fs-12 ${status.class}`"
                        small
                        v-show="ticket.status == status.value"
                      >
                        {{ status.label }}
                      </v-chip>
                    </div>

                    <div
                      class="col-md-5 fs-normal fw-500 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      Organisation
                    </div>
                    <div
                      class="col-md-7 fs-normal fw-400 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      {{
                        ticket.workspaceName
                          ? ticket.workspaceName
                          : currentWorkspace.workspaceName
                      }}
                    </div>
                    <div
                      class="col-md-5 fs-normal fw-500 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      Type
                    </div>
                    <div
                      class="col-md-7 fs-normal fw-400 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      {{ ticket.ticketType }}
                    </div>
                    <div
                      class="col-md-5 fs-normal fw-500 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      Currency
                    </div>
                    <div
                      class="col-md-7 fs-normal fw-400 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      {{ ticket.currency }}
                    </div>
                    <div
                      class="col-md-5 fs-normal fw-500 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      Created At
                    </div>
                    <div
                      class="col-md-7 fs-normal fw-400 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      {{ ticket.createdAt | formatDate }}
                    </div>
                  </div>
                </v-container>
                <v-divider class="mt-4" />

                <p class="ma-0 fs-15 fw-600 pa-3 pb-0">Status</p>
                <div
                  style="overflow-y: scroll !important; height: 40vh !important"
                  class="pa-3"
                >
                  <div v-for="(logs, date, index) in logsFunction" :key="index">
                    <p
                      class="ma-0 pa-0 fs-normal fw-400 fs-14 lh-19 textPrimary--text f-inter"
                    >
                      {{ date }}
                    </p>
                    <v-timeline>
                      <v-timeline-item
                        v-for="(value, key, index) in logs"
                        :key="index"
                        small
                        dense
                        right
                        :color="
                          colorObject[value.status]
                            ? colorObject[value.status]
                            : 'primary'
                        "
                      >
                        <template v-slot:opposite>
                          <span class="f-inter fs-13 fw-400">{{
                            value.createdAt | formatTime
                          }}</span>
                        </template>
                        <template>
                          <span class="f-inter fs-13 fw-500">{{
                            value.status
                          }}</span>
                        </template>
                      </v-timeline-item>
                    </v-timeline>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import FormInput from "../common/FormInput.vue";
import { register } from "vue-advanced-chat";
import ChatBox from "../common/ChatBox.vue";
import XeroForm from "../common/XeroForm.vue";
import XeroFormNew from "../common/XeroFormNew";
import { mapGetters } from "vuex";
import moment from "moment";
import UrlServices from "../../services/Url";
import axios from "axios";
import Dialog from "../common/dialog-box";
import Button from "../common/button";
import lodash from "lodash";
import EventBus from "../../js/EventBus";
import XeroFormFilePreview from "./xero-form/XeroFormFilePreview.vue";
import NoAttachmentIcon from "./icons/noAttachment.vue";
register();
export default {
  components: {
    FormInput,
    ChatBox,
    Dialog,
    Button,
    XeroForm,
    XeroFormNew,
    XeroFormFilePreview,
    NoAttachmentIcon,
  },
  data() {
    return {
      invoiceTypeSelectedValue: "ACCREC",
      invoiceTypeArray: [
        {
          text: "Invoice",
          value: "ACCREC",
        },
        {
          text: "Bill",
          value: "ACCPAY",
        },
      ],
      modalValue: false,
      openXeroForm: true,
      submittedData: false,
      ticketData: {},
      newDraftData: [],
      oldDraftData: [],
      data: false,
      attachment: true,
      file: null,
      selectedFile: {},
      previewFileDialog: {
        dialog: false,
        url: "",
        headingName: "",
      },
      clientHiddenLogs: ["Assign"],
      showpopup: true,
      detailtab: "TicketInfo",
      reviewRequestDialog: false,
      ticketStatus: "",
      namesToBeReplaced: {
        Pending: "Pending",
        InProgress: "In Progress",
        AwaitingResponse: "Awaiting Response",
        ClientResponded: "Responded",
        Complete: "Completed",
        ReviewRequest: "Review Request",
      },
      statusOptions: [
        {
          value: "Posted",
          label: "Posted",
          class: "new-posted-chip",
        },
        {
          value: "Processing",
          label: "Processing",
          class: "new-processing-chip",
        },
        {
          value: "PendingApproval",
          label: "Pending Approval",
          class: "new-pending-approval-chip",
        },
        {
          value: "Saved",
          label: "Saved",
          class: "new-saved-chip",
        },
        {
          value: "Audit",
          label: "Audit",
          class: "audit-chip",
        },
        {
          value: "Rejected",
          label: "Rejected",
          class: "new-rejected-chip",
        },
        {
          value: "Pending",
          label: "Pending",
          class: "new-pending-chip",
        },
      ],
      colorObject: {
        Created: "timeLineCreated",
        PendingApproval: "chipColorNewPendingApprovalBackground",
        Saved: "chipColorNewSavedBackground",
        Posted: "chipColorNewPostedBackground",
        Audit: "timeLineAudit",
        Processing: "chipColorNewProcessingBackground",
        Rejected: "chipColorNewRejectBackground",
      },
    };
  },
  props: {
    ticket: Object,
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      token: "auth/getAuthToken",
      user: "auth/getUser",
    }),
    logsFunction() {
      let obj = {};
      this.ticket?.logs?.forEach((item) => {
        if (
          !item.action ||
          item.action == "change_status" ||
          item.action == "change_request_type" ||
          item.action == "review"
        ) {
          if (!this.clientHiddenLogs.includes(item.status)) {
            item.status = this.namesToBeReplaced[item["status"]]
              ? this.namesToBeReplaced[item["status"]]
              : item["status"];
            if (obj[moment(item.createdAt).format("YYYY MMM DD")] === undefined)
              obj[moment(item.createdAt).format("YYYY MMM DD")] = [item];
            else obj[moment(item.createdAt).format("YYYY MMM DD")].push(item);
          }
        }
      });
      console.log("Object here", obj);
      return obj;
    },
    /**
     * Property to return ticket type
     */
    ticketType() {
      return this.ticket?.draft?.invoices[0]?.type == "ACCREC"
        ? "Invoice"
        : this.ticket?.draft?.invoices[0]?.type == "ACCPAY"
        ? "BILL"
        : this.ticket?.draft?.invoices[0]?.type == "PAID" ||
          this.ticket?.draft?.invoices[0]?.type == "Payment"
        ? "PAYMENT"
        : "";
    },
  },
  mounted() {
    this.ticketData = this.ticket;
    if (this.ticket) {
      console.log(this.ticket.draft?.invoices[0].type, "TD");
      this.invoiceTypeSelectedValue =
        this.ticket?.draft?.invoices[0]?.type == "ACCREC"
          ? // this.ticketData.requestType == "Invoice"
            {
              text: "Invoice",
              value: "ACCREC",
            }
          : this.ticket?.draft?.invoices[0]?.type == "ACCPAY"
          ? //  : this.ticketData.requestType == "Bill"
            {
              text: "Bill",
              value: "ACCPAY",
            }
          : this.ticket?.draft?.invoices[0]?.type == "PAID"
          ? // this.ticket?.draft?.invoices[0]?.type == "Payment"
            // : this.ticketData.requestType == "Payment"
            {
              text: "Payment",
              value: "PAID",
            }
          : "";
    }

    /*
     ** Show attachment
     */
    if (this.ticketData?.attachmentName && !this.file) {
      this.openAttachedDocument(this.ticketData);
      this.attachment = true;
    }
    // if there is no attachment
    else if (!this.ticketData?.attachmentName) {
      this.attachment = false;
    }

    EventBus.$on("old-draft-data", (oldData) => {
      this.oldDraftData = oldData;
    });
    EventBus.$on("new-draft-data", (newData) => {
      this.newDraftData = newData;
    });

    this.appendInArray();
  },
  methods: {
    appendInArray() {
      if (!this.currentWorkspace.isAccountingSoftConnected) return;
      this.invoiceTypeArray.push({
        text: "Payment",
        value: "PAID",
      });
    },

    changeInvoiceType() {
      if (
        this.newDraftData &&
        this.newDraftData.length > 0 &&
        this.newDraftData[0].type
      )
        this.newDraftData[0].type = this.invoiceTypeSelectedValue;
    },
    /**
     * This function compare new draft data and old draft data of all the invoices
     */
    compareDraftData() {
      const isEqual = lodash.isEqual(this.newDraftData, this.oldDraftData);
      return isEqual;
    },
    closeClickedDialogXeroForm() {
      this.modalValue = false;
      this.detailtab = 2;
    },
    saveClickedDialogXeroForm() {
      this.modalValue = false;
      this.openXeroForm = false;
    },
    closeXeroForminTabs() {
      if (!this.openXeroForm || this.currentWorkspace.rewriteInvoice) {
        this.openXeroForm = false;
        return;
      }
      console.log("here we need to compare data in order to show dialog");
      if (this.submittedData || this.compareDraftData())
        return (this.openXeroForm = false);
      else if (!this.submittedData || !this.compareDraftData())
        return (this.modalValue = true);
    },
    reviewRequest() {
      this.$emit("review-request");
      this.reviewRequestDialog = false;
    },
    fileSent() {
      this.$emit("file-sent");
    },
    openPrivateUrl(url) {
      var data = {
        token:
          this.currentWorkspace.source === "slack"
            ? this.currentWorkspace.botToken
            : "test-string",
      };
      data["file"] =
        url.search("files/") > -1
          ? url
          : url.split("https://files.slack.com/")[1];
      var config = {
        method: "post",
        url: UrlServices.slack,
        data: data,
        responseType: "blob",
        // headers: {
        //   "Content-Type": "application/x-www-form-urlencoded",
        // },
      };
      axios(config)
        .then(function (response) {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            this.imageUrl = base64data;
          };

          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = url.split("/")[url.split("/").length - 1];
          link.click();
          link.remove();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async openLinkInNewWindow(url, openInTab = false) {
      this.tokenValidation()
        .then(() => {
          let filePath =
            url.search("files/") > -1
              ? url
              : url.split("https://files.slack.com/")[1];
          let mytoken = this.token.substring(7);
          let check = ["doc", "xls", "docx", "ppt"].includes(
            url.split(".").pop()
          );
          let newUrl = `${process.env.VUE_APP_BACK_END_URL}${UrlServices.slack}/${mytoken}?file=${filePath}`;
          if (!openInTab)
            window.open(
              check
                ? `https://view.officeapps.live.com/op/embed.aspx?src=${newUrl}`
                : newUrl,
              "_blank"
            );
          else {
            this.previewFileDialog.headingName = this.ticket.ticketNo;
            this.previewFileDialog.url = check
              ? `https://view.officeapps.live.com/op/embed.aspx?src=${newUrl}`
              : newUrl;
            this.previewFileDialog.dialog = true;
          }
        })
        .catch((err) => {
          console.log("err ======> openLinkInNewWindow", err);
        });
    },
    async tokenValidation() {
      let configToken = {
        method: "get",
        url: "/user/verify-token",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      return axios(configToken);
    },
    closePreviewFileDialog() {
      this.previewFileDialog = {
        dialog: false,
        url: "",
        headingName: "",
      };
    },
    /*
     ** Preview Functions to get Preview
     */
    async openAttachedDocument(ticket) {
      let obj = {
        _id: ticket._id,
        file: {
          originalname: ticket.attachmentName
            ? ticket.attachmentName
            : `Attached File.${ticket.attachmentUrl.split(".").pop()}`,
        },
        createdAt: ticket.createdAt,
        fileUrl: ticket.attachmentUrl,
      };
      this.selectedFile = obj;
      await this.openPrivateUrl(this.selectedFile.fileUrl);
    },
    async openPrivateUrl(url) {
      this.tokenValidation()
        .then(() => {
          var data = {
            token:
              this.currentWorkspace.source === "slack"
                ? this.currentWorkspace.botToken
                : "test-string",
          };
          data["file"] =
            url.search("files/") > -1
              ? url
              : url.split("https://files.slack.com/")[1];
          let mytoken = this.token;
          mytoken = mytoken.substring(7);
          this.file = `${process.env.VUE_APP_BACK_END_URL}${UrlServices.slack}/${mytoken}?file=${data?.file}`;
          this.data = true;
        })
        .catch((err) => {
          console.log("openPrivateUrl ===> ", err);
        });
    },
    async tokenValidation() {
      let configToken = {
        method: "get",
        url: "/user/verify-token",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      return axios(configToken);
    },
  },
  beforeDestroy() {
    EventBus.$off("old-draft-data");
    EventBus.$off("new-draft-data");
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-tab--hover {
  border-radius: 14px !important;
}

::v-deep .v-tab--active {
  color: none;
}

::v-deep .v-tabs-slider {
  display: none;
  /* background-color: currentColor; */
}
::v-deep .v-chip.v-size--small {
  border-radius: 6px !important;
  justify-content: center !important;
}

.custom-close-icon-latest {
  position: relative;
  // bottom: 0px;
  // padding: 0px;sa ksmnlkam
  // font-size: 26px;
  // border-radius: 3px;
  color: var(--v-textPrimary-base) !important;
  cursor: pointer;
  width: 28px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #e8ebf4;
  border-radius: 4px;
}

.side-action {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 0px;
  border-bottom-style: inset;
  border-width: 1.8px !important;
  border-color: hsl(225, 35%, 93%) !important;
}

// .detail-tab-key {
// font-style: normal;
// font-weight: 500;
// font-size: 14px;
// line-height: 19px;
// color: #40415a;
// }

// .detail-tab-value {
// font-style: normal;
// font-weight: 500;
// font-size: 14px;
// line-height: 19px;
// color: #40415a;
// }

.file-tab-row {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 20px;
    color: #40415a;
    margin-top: 20px;
    margin-bottom: 13px;
  }

  .file {
    display: flex;
    margin-top: 12px;
    background: #ffffff;
    border: 1px solid #e8ebf4;
    border-radius: 8px;
    padding: 11px;
    cursor: pointer;

    .file-action {
      display: none;

      .v-image {
        height: 27px;
        width: 27px;
        margin-left: 10px;
      }
    }
  }

  .file:hover {
    outline: rgba(102, 52, 233, 1) auto 1px;

    .file-action {
      display: flex !important;
      margin-right: 10px !important;
      margin-left: auto !important;
      background: white !important;
      background: linear-gradient(
        269.34deg,
        #ffffff 53.16%,
        rgba(255, 255, 255, 0) 176.03%
      );

      // display: block !important;
      .v-image {
        height: 27px;
        width: 27px;
        margin-left: 10px;
      }
    }
  }

  .file p {
    padding: 0px;
    margin-bottom: 0px !important;
  }

  .file-name {
    margin-left: 10px;
  }

  .file-name .name {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #40415a;
  }

  .file-name .date {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #9d9eab;
  }
}

// .timeline-tab-row {
//   .title {
//     font-style: normal;
//     font-weight: 600;
//     font-size: 15px !important;
//     line-height: 20px;
//     color: #40415a;
//     margin-top: 17px;
//   }

//   .date {
//     margin-top: 29px;
//     font-weight: 500;
//     font-size: 15px;
//     line-height: 20px;
//     color: #40415a;
//   }

//   .time-status {
//     display: flex;
//     justify-content: space-between;
//     margin-top: 12px;
//     background: #ffffff;
//     border: 1px solid #e8ebf4;
//     border-radius: 8px;
//   }

//   .time-status p {
//     padding: 18px;
//     margin-bottom: 0px !important;
//   }

//   .time-status .time {
//     font-style: normal;
//     font-weight: 400;
//     font-size: 13px;
//     line-height: 18px;
//     color: #40415a;
//   }

//   .time-status .status {
//     font-style: normal;
//     font-weight: 600;
//     font-size: 13px;
//     line-height: 18px;
//     color: #40415a;
//   }

//   .logs {
//     height: 70vh;
//     overflow-y: scroll;
//     padding-right: 25px;
//   }
// }

// .all-files {
//   height: 60vh;
//   overflow-y: scroll;
//   padding-right: 25px;
//   padding-left: 2px;
// }
</style>
<style>
::v-deep .vac-room-header {
  display: none !important;
}

::v-deep .room-header {
  display: none !important;
}

.messagebox {
  position: absolute;
  right: 0px;
  margin: 0px;
  top: 0px;
  background: white;
  max-height: 100% !important;
  height: 100vh;
  /* border-top-left-radius: 25px; */
  /* border-bottom-left-radius: 25px; */
  margin-top: 10px !important;
  /* overflow: hidden; */
}

.detail-tabs {
  /* padding-left: 34px; */
  /* height: 88vh; */
  /* border-left-style: outset; */
  border-width: 1.8px !important;
  border-color: hsl(225, 35%, 93%) !important;
}

/* .detail-tabs .v-slide-group__content {
  height: 45px;
  background: #faf9ff;
  padding-inline: 4px;
  color: black;
} */

.detail-tabs .v-tab {
  /* color: black !important; */
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: var(--v-textPrimary-base) !important;
}

.detail-tabs .v-tab--active {
  color: var(--v-lightPurple-base) !important;
  /* color: #6634e9 !important; */
  background: #ffffff;
  box-shadow: 0px 4px 7px rgba(119, 119, 119, 0.08);
  border-radius: 8.64641px;
}

.detail-tabs .detail-tab-row {
  padding-top: 25px;
  height: 50vh;
  overflow-y: scroll;
}

.v-chip__content .v-icon .icon-size-color {
  color: #faad13;
  font-size: 6px;
}

.left-side-message-box {
  border-right-style: inset;
  border-bottom-style: inset;
  padding-left: 0px !important;
  border-width: 1.8px !important;
  border-color: hsl(225, 35%, 93%) !important;
}

/* .messagebox-parent {
  padding-left: 25px !important;
  padding: 6px;
} */
@media only screen and (min-width: 1440px) {
  .v-slide-group__next {
    display: none;
  }
  .v-slide-group__prev {
    display: none;
  }
  .custom-tabs-width {
    width: 340px !important;
  }
}
.custom-border {
  border: 1px solid #e4e4e4;
}
/* .custom-tab-margin {
  margin-top: 4px;
  margin-bottom: 6px;
} */
.app p {
  margin-bottom: 4px !important;
}
</style>
