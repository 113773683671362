import { render, staticRenderFns } from "./XeroFormNew.vue?vue&type=template&id=2c9f0fc2&scoped=true&"
import script from "./XeroFormNew.vue?vue&type=script&lang=js&"
export * from "./XeroFormNew.vue?vue&type=script&lang=js&"
import style0 from "./XeroFormNew.vue?vue&type=style&index=0&id=2c9f0fc2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9f0fc2",
  null
  
)

export default component.exports