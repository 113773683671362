<template>
  <div>
    <Dialog
      :modal="modalValue"
      @close-clicked="modalValue = false"
      @save-clicked="closeChatMiniFunctionForXeroForm"
      v-if="currentWorkspace.rewriteInvoice || user.role == 'client'"
    >
      <template v-slot:header> Confirmation </template>
      <template v-slot:content>
        Are you sure you want to leave, all unsaved changes will be lost
      </template>
      <template v-slot:buttonHeading> Ok </template>
    </Dialog>
    <v-row class="ma-0 pa-0">
      <!-- File Preview -->

      <!-- Open Form -->
      <v-col class="ma-0 pa-0" :cols="12">
        <div
          v-if="!dataLoader && dataDraft?.invoices[0]?.type !== 'PAID'"
          :style="
            paymentField === true
              ? 'min-height: 90vh; max-height: 90vh'
              : 'min-height: 90vh; max-height: 90vh'
          "
          class="container"
        >
          <div class="scrollable-div">
            <!-- Content for the scrollable div -->
            <Form
              :paymentField="paymentField"
              :draft="dataDraft"
              :ticket="ticketData"
              :accounts="accounts"
              @new-invoice-object="setNewInvoiceObject"
              ref="invoiceForm"
              :trackingCategories="trackingCategories"
              :items="items"
            />
            <!-- Add your content here -->
          </div>
          <div
            class="static-div"
            v-if="
              !currentWorkspace.rewriteInvoice &&
              user.role == 'client' &&
              (ticket.draft || ticket.status === 'Complete') &&
              paymentField === false
            "
          >
            <div class="d-flex justify-space-between">
              <div>
                <v-btn
                  v-if="
                    currentWorkspace.isAccountingSoftConnected &&
                    user.role == 'client' &&
                    (ticket.draft || ticket.status === 'Complete')
                  "
                  class="fs-12 mr-2 cursor-pointer primary--text text-capitalize fw-600 custom-btn"
                  style="letter-spacing: -0.2px"
                  @click="openPaymentField()"
                >
                  Add Payment
                </v-btn>
              </div>
              <div>
                <v-btn
                  v-if="
                    user.role == 'client' &&
                    (ticket.draft || ticket.status === 'Complete')
                  "
                  class="fs-12 cursor-pointer primary--text text-capitalize fw-600 custom-btn"
                  style="letter-spacing: -0.2px"
                  @click="submitDraft()"
                  :loading="draftLoader"
                >
                  Save As Draft
                </v-btn>
                <v-btn
                  :loading="loader"
                  v-if="
                    currentWorkspace.isAccountingSoftConnected &&
                    user.role == 'client' &&
                    (ticket.draft || ticket.status === 'Complete')
                  "
                  class="fs-12 mr-2 cursor-pointer primary--text text-capitalize fw-600 custom-btn"
                  style="letter-spacing: -0.2px"
                  @click="submitInvoices()"
                >
                  Approve
                  <v-tooltip top color="numberField" max-width="200px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small class="pl-2"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span class="black--text fs-10 fw-400"
                      >This will directly approve the transaction and record it
                      in the accounting software</span
                    >
                  </v-tooltip>
                </v-btn>
              </div>
            </div>
            <!-- Content for the static div -->
            <!-- This div will be at the bottom of the page -->
          </div>
          <div class="static-div-payment" v-if="paymentField === true">
            <PaymentBar
              @payment-bar-submission="invoiceSubmissionWithPayment"
              :ticket="ticketData"
              :paymentLoader="paymentLoader"
            />
          </div>
        </div>
        <div v-else-if="dataDraft?.invoices[0]?.type === 'PAID'">
          <PaymentForm
            @payment-submitted="closePaymentForm"
            :ticket="ticketData"
            :invoiceItem="dataDraft"
          />
        </div>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <AccountsComparisonDialog
      :accountsToBeReplaced="accountsToBeReplaced"
      :accounts="accounts.accounts"
      :dialog="accountComparisonDialog"
      @state-changed="actionPerformed"
    ></AccountsComparisonDialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "../common/button/index.vue";
import UrlServices from "../../services/Url";
import ApiService from "../../services/Api";
import axios from "axios";
import EventBus from "../../js/EventBus.js";
import Form from "./xero-form/FormInvoices";
import Loader from "./xero-form/Loader.vue";
import NoDraft from "./xero-form/NoDraft.vue";
import Dialog from "../../components/common/dialog-box/index.vue";
import AccountsComparisonDialog from "./AccountComparisonModel.vue";
import { showErrorMessage } from "../../services/SnackBar.js";
import PaymentBar from "./xero-form/PaymentBar.vue";
import PaymentForm from "./xero-form/PaymentForm.vue";
export default {
  name: "XeroForm",
  components: {
    Button,
    Form,
    Loader,
    NoDraft,
    Dialog,
    AccountsComparisonDialog,
    PaymentBar,
    PaymentForm,
  },
  data() {
    return {
      /*
       ** Variables used for previewing Data
       */
      items: [],
      paymentLoader: false,
      accounts: {
        tenantId: null,
        accounts: [],
      },
      overlay: false,
      accountComparisonDialog: false,
      accountsToBeReplaced: [],
      accountsHashMap: null,
      xeroHashMaps: {
        accountsHashmap: { ids: {}, all: [] },
        itemsHashmap: { ids: {}, all: [] },
      },
      ticketData: {},
      loader: false,
      modalValue: false,
      /*
       ** Variables used for xero Form
       */
      dataLoader: true,
      dataDraft: null,
      invoicesArr: [],
      draftArr: [],
      submittedData: false,
      archived: false,
      trackingCategories: [],
      paymentField: false,
      draftLoader: false,
    };
  },
  props: {
    ticket: Object,
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      token: "auth/getAuthToken",
      user: "auth/getUser",
    }),
  },
  async mounted() {
    try {
      this.overlay = true;
      // console.log(this.ticket);
      this.ticketData = this.ticket;
      /*
       ** Get Latest Details on Mount if api is not called
       */
      if (!this.dataDraft) await this.getLatestDetails(this.ticket._id);
      // EventBus.$on("get-xerohashmaps", (xerohashmaps) => {
      //   this.xeroHashMaps = xerohashmaps;
      // });
      await Promise.all([this.getItems(), this.getTrackingCategories()]);
      await this.getAccounts();
    } catch (error) {
      this.$showNotificationBar(
        "error",
        (error?.message ? error.message : "Error Ocurred") +
          ". please refresh data might not be loaded correctly"
      );
    } finally {
      this.overlay = false;
    }
  },
  methods: {
    closePaymentForm() {
      this.dataDraft.invoices[0].type = "ACCREC";
      this.ticketData.status = "Posted";
      this.ticketData.xeroSubmission = true;
    },
    async invoiceSubmissionWithPayment(payment) {
      this.paymentLoader = true;
      this.dataDraft.invoices[0].status = "AUTHORISED";
      const response = await this.submitInvoices(true);
      payment.invoice.invoiceID = response?.data?.responseFromXero;
      this.submitPayment(payment);
    },
    submitPayment(payment) {
      delete payment.account.code;
      ApiService.PostRequest(
        `${UrlServices.submitInvoicePayment}/${this.ticket.workspaceId}`,
        { payment: payment, ticketId: this.ticket._id }
      )
        .then((res) => {
          this.paymentLoader = false;
          this.$showNotificationBar("success", "Payment has been submitted");
          this.paymentField = false;
        })
        .catch((err) => {
          this.paymentLoader = false;
          // this.$showNotificationBar("error", err);
          // this.paymentField = false;
          console.log(err);
        });
    },
    /**
     * Used to open Payment field
     */
    openPaymentField() {
      if (this.paymentField === true) return;
      this.paymentField = true;
    },
    /**
     * this function gets all items
     */
    getItems() {
      ApiService.GetRequest(UrlServices.getAllItems)
        .then((res) => {
          this.items = res.data.data.map((el) => ({
            name: el.itemName,
            id: el._id,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async actionPerformed(payload) {
      this.mapAccountsToLineItems(payload.accountsMappingHashMap);
      this.accountComparisonDialog = false;
      if (payload.action == "post") {
        await this.submitInvoices();
      } else {
        await this.saveDraftForm();
      }
      this.ticketData.tenantId = this.accounts.tenantId;
      this.$emit("submitted-data");
    },
    // createAccountsHashmap() {
    //   const hashmap = {};
    //   this.accounts.accounts.forEach((account) => {
    //     hashmap[account.name.toLowerCase()] = account.code.toLowerCase();
    //   });
    //   return hashmap;
    // },
    // Get tracking categories
    getTrackingCategories() {
      return ApiService.GetRequest(
        `${UrlServices.getCategories}/${this.currentWorkspace.workspaceId}`
      )
        .then((res) => {
          this.trackingCategories = res.data.trackingCategories;
          // check if tracking categoiries object exists in data draft
          if (
            this.dataDraft?.invoices[0]?.lineItems[0]?.tracking.length === 0
          ) {
            // make tracking categoiries object
            const Arr = [];
            this.trackingCategories.forEach((element) => {
              let obj = {
                trackingCategoryID: element.trackingCategoryID,
                trackingOptionID: "",
              };
              Arr.push(obj);
            });
            // append in array for all items
            for (let i = 0; i < this.dataDraft.invoices.length; i++) {
              for (
                let j = 0;
                j < this.dataDraft.invoices[i].lineItems.length;
                j++
              ) {
                this.dataDraft.invoices[i].lineItems[j].tracking = JSON.parse(
                  JSON.stringify(Arr)
                );
              }
            }
          }
          console.log(this.trackingCategories, "==========tracking=========");
        })
        .catch((err) => {
          if (![401, 403].includes(err.response.status)) throw err;
        });
    },
    setFormAccounts() {
      if (
        this.ticket.tenantId != this.accounts.tenantId &&
        Array.isArray(this.dataDraft?.invoices)
      ) {
        const Arr = [];
        this.trackingCategories.forEach((element) => {
          let obj = {
            trackingCategoryID: element.trackingCategoryID,
            trackingOptionID: "",
          };
          Arr.push(obj);
        });
        const draft = this.dataDraft;
        const uniqueAccountsHashMap = {},
          mapAccounts = [];
        for (let i = 0; i < draft.invoices.length; i++) {
          const specificInvoice = draft.invoices[i];
          if (specificInvoice.brandingThemeID)
            specificInvoice.brandingThemeID = "";
          if (Array.isArray(specificInvoice.lineItems)) {
            for (
              let lineItemIndex = 0;
              lineItemIndex < specificInvoice.lineItems.length;
              lineItemIndex++
            ) {
              const currLineitem = specificInvoice.lineItems[lineItemIndex];
              if (
                currLineitem.accountCode?.name &&
                !uniqueAccountsHashMap[currLineitem.accountCode.name]
              ) {
                uniqueAccountsHashMap[currLineitem.accountCode.name] =
                  currLineitem.accountCode.code;
                mapAccounts.push({
                  name: currLineitem.accountCode.name,
                  description: currLineitem.description,
                });
              }
              currLineitem.tracking = JSON.parse(JSON.stringify(Arr));
            }
          }
          if (mapAccounts.length) {
            this.accountsToBeReplaced = mapAccounts;
            this.accountComparisonDialog = true;
          }
        }
      }
    },
    mapAccountsToLineItems(accountsHashMap) {
      const draft = JSON.parse(JSON.stringify(this.dataDraft));
      for (let i = 0; i < draft.invoices.length; i++) {
        const specificInvoice = draft.invoices[i];
        if (Array.isArray(specificInvoice.lineItems)) {
          for (
            let lineItemIndex = 0;
            lineItemIndex < specificInvoice.lineItems.length;
            lineItemIndex++
          ) {
            const currLineitem = specificInvoice.lineItems[lineItemIndex];
            if (
              currLineitem.accountCode.name &&
              accountsHashMap[currLineitem.accountCode.name]
            )
              currLineitem.accountCode =
                accountsHashMap[currLineitem.accountCode.name];
          }
        }
      }
      this.dataDraft = draft;
      this.$refs.invoiceForm.setDataDraft(draft);
    },
    /**
     * This function gets all the accounts and items
     */
    getAccounts() {
      return ApiService.GetRequest(
        `${UrlServices.getAccountsForSpecificWorkspace}/${this.currentWorkspace._id}`
      ).then((res) => {
        this.accounts = res.data.data;
        if (this.currentWorkspace.isAccountingSoftConnected)
          this.setFormAccounts();
      });
    },

    setNewInvoiceObject(invoiceObj) {
      this.dataDraft = invoiceObj;
    },
    closeChatMiniFunctionForXeroForm() {
      this.modalValue = false;
      this.$router.push("/integration");
    },
    /**
     * This function submit invoices on Xero
     */
    async submitInvoices(statusAuthorised = null) {
      // let trackingSelectedItems = this.$refs.invoiceForm.selectedTrackingItems;
      this.loader = true;
      this.archived = false;
      const percentLookupRegex = /^%|%$/g;
      if (!statusAuthorised) {
        this.dataDraft.invoices[0].status = this.currentWorkspace
          .defaultSubmissionStatus
          ? this.currentWorkspace?.defaultSubmissionStatus
          : "DRAFT";
      }
      let inv = JSON.parse(JSON.stringify(this.dataDraft));
      let draft = JSON.parse(JSON.stringify(this.dataDraft));
      let invoices = inv.invoices;
      let draftInvoices = draft.invoices;
      console.log(draftInvoices, "before validations");
      // Declare hashmaps for unique Items and unique Accounts
      // Declare empty arrays for unique Items and unique Accounts
      const uniqueItemsHashMap = {},
        uniqueItems = [];
      for (let i = 0; i < invoices.length; i++) {
        const { subTotal, totalTax, total, ...ele } = invoices[i];
        ele.name = ele.hasOwnProperty("name") ? ele.name : "";

        /**
         * xeroform header fields validations here
         */
        if (!ele.invoiceNumber) {
          this.loader = false;
          return this.$showNotificationBar(
            "error",
            `Invoice number is required`
          );
        }
        if (!ele.lineAmountTypes) {
          this.loader = false;
          return this.$showNotificationBar(
            "error",
            `Line Amount Type is required`
          );
        }
        if (!ele.name) {
          this.loader = false;
          return this.$showNotificationBar("error", `Name is required`);
        }
        if (!ele.date) {
          this.loader = false;
          return this.$showNotificationBar("error", `Date is required`);
        }
        if (!ele.dueDate) {
          this.loader = false;
          return this.$showNotificationBar("error", `Due date is required`);
        }
        /**
         * xeroform footer fields validations here
         */
        if (!ele.currencyCode) {
          this.loader = false;
          return this.$showNotificationBar("error", `Currency is required`);
        }
        // xeroform header fields validations END
        if (Array.isArray(ele.lineItems)) {
          for (
            let lineItemIndex = 0;
            lineItemIndex < ele.lineItems.length;
            lineItemIndex++
          ) {
            const r = ele.lineItems[lineItemIndex];
            /**
             * Remove the duplication of all accounts with user accounts
             * and add the unique accounts in uniqueAccounts array
             */
            // if (
            //   r.accountCode?.name &&
            //   !uniqueAccountsHashMap[r.accountCode.name]
            // ) {
            //   uniqueAccounts.push({
            //     name: r.accountCode.name,
            //     type: r.accountCode.type,
            //   });
            //   uniqueAccountsHashMap[r.accountCode.name] = true;
            // }
            // console.log("uniqueAccounts and uniqueItems>>>", uniqueAccounts);

            // xeroform lineitems accountCode & itemCode validations here
            // if (r.itemCode.id && !r.accountCode) {
            //   r.itemCode.id = "";
            //   //   this.loader = false;
            //   //   return this.$showNotificationBar(
            //   //     "error",
            //   //     `Account code is required`
            //   //   );
            // }

            // xeroform lineitems validations here
            if (!r.description) {
              this.loader = false;
              return this.$showNotificationBar(
                "error",
                `Description is required`
              );
            }
            r.disc == "" ? (r.disc = "0") : r.disc;
            r.taxAmount == "" ? (r.taxAmount = "0") : r.taxAmount;
            if (r.itemCode?.id) {
              if (!r.itemCode.id.startsWith("bot-"))
                r.itemCode.id = `bot-${r.itemCode.id}`;
              if (!uniqueItemsHashMap[r.itemCode.id]) {
                uniqueItems.push({
                  name: r.itemCode.name,
                  code: r.itemCode.id,
                });
                uniqueItemsHashMap[r.itemCode.id] = true;
              }
              r.itemCode = r.itemCode.id;
            } else if (r.itemCode?.id === "") delete r["itemCode.id"];
            if (r.accountCode?.code) r.accountCode = r.accountCode.code;
            r["taxAmount"] =
              typeof r["taxAmount"] === "string"
                ? r["taxAmount"].replace(percentLookupRegex, "")
                : r["taxAmount"];
            if (ele.type == "ACCREC") {
              r["discountRate"] =
                typeof r.disc === "string"
                  ? r.disc.replace(percentLookupRegex, "")
                  : r.disc;
            } else {
              delete r["taxRate"];
              delete r["discountRate"];
              delete r.disc;
            }
          }
        } else ele.lineItems = [];

        // xeroform footer fields validations END
      }
      // let userAccountsHashMapNameToCode = {};
      // // Dispatch createAccountsToXero API here to get user's accounts name to code
      // if (uniqueAccounts.length) {
      //   console.log(uniqueAccounts);
      //   userAccountsHashMapNameToCode = await ApiService.PutRequest(
      //     `${UrlServices.createAccountsToXero}/${this.currentWorkspace.workspaceId}`,
      //     {
      //       accounts: uniqueAccounts,
      //     }
      //   ).then((response) => response.data);
      // }

      // Display snackbar "Accounts are created to Xero"
      // const uniqueItems = [],
      //   uniqueItemsHashMap = {};
      // for (let i = 0; i < invoices.length; i++) {
      //   const { lineItems } = invoices[i];
      //   if (Array.isArray(lineItems)) {
      //     for (
      //       let lineItemIndex = 0;
      //       lineItemIndex < lineItems.length;
      //       lineItemIndex++
      //     ) {
      //       const currentLineItem = lineItems[lineItemIndex];
      //       console.log("curentLineItems>>>", currentLineItem);
      //       /**
      //        * Conversion of accounts code to item names
      //        */
      //       if (currentLineItem.accountCode) {
      //         // const userAccountsNameHashMap =
      //         //   this.$refs.invoiceForm.userAccountsNameHashMap;
      //         // console.log(userAccountsNameHashMap, "refs from invoice");
      //         // if (
      //         //   !userAccountsNameHashMap[currentLineItem.accountCode.name]?.code
      //         // ) {
      //         // archived = await dispatch("accountsHashmap", {
      //         //   name: currentLineItem.accountCode.name,
      //         //   type: currentLineItem.accountCode.type,
      //         // });
      //         // await this.$refs.invoiceForm
      //         //   .checkAccountChange({
      //         //     name: currentLineItem.accountCode.name,
      //         //     type: currentLineItem.accountCode.type,
      //         //   })
      //         //   .then((res) => {
      //         //     console.log(res, "promise result");
      //         //     this.archived = res;
      //         //     this.loader = false;
      //         //   });
      //         // }

      //         currentLineItem.accountCode = currentLineItem.accountCode.code;
      //         // userAccountsHashMapNameToCode[currentLineItem.accountCode.name];
      //       }
      //       /**
      //        * Remove the duplication of all items with user items
      //        * and add the unique items in uniqueItems array
      //        */
      //       if (
      //         currentLineItem.itemCode.id &&
      //         !uniqueItemsHashMap[currentLineItem.itemCode.id]
      //       ) {
      //         console.log(this.xeroHashMaps.itemsHashmap.ids);
      //         uniqueItems.push({
      //           name: this.xeroHashMaps.itemsHashmap.ids[
      //             currentLineItem.itemCode.id
      //           ]?.name,
      //           code: `bot-${currentLineItem.itemCode.id}`,
      //         });
      //         uniqueItemsHashMap[currentLineItem.itemCode.id] = true;
      //         // Fixed 'price List Item Code must not be more than than 30 characters long'
      //         if (currentLineItem?.itemCode.id?.includes("bot-bot-")) {
      //           currentLineItem.itemCode.id =
      //             currentLineItem.itemCode.id.slice(4);
      //         }
      //       }
      //       /**
      //        * Conversion of `itemCode` to `bot-itemCode`
      //        */
      //       if (currentLineItem.itemCode.id)
      //         currentLineItem.itemCode.id = `bot-${currentLineItem.itemCode.id}`;
      //       // Fixed 'price List Item Code must not be more than than 30 characters long'
      //       if (currentLineItem?.itemCode.id?.includes("bot-bot-")) {
      //         currentLineItem.itemCode.id =
      //           currentLineItem.itemCode.id.slice(4);
      //       }
      //     }
      //   }
      // }

      // if (this.archived) {
      //   return this.$showNotificationBar(
      //     "error",
      //     `${currentLineItem.accountCode.name} account is archived in Xero`
      //   );
      // }
      // Fixed 'price List Item Code must not be more than than 30 characters long'
      // uniqueItems.forEach((ele) => {
      //   if (ele?.code?.includes("bot-bot-")) {
      //     ele.code = ele.code.slice(4);
      //   }
      // });

      // trim the contact name in the invoices
      // as xero does not accept whitespace from both sides of a contact name
      invoices.forEach((e) => {
        let trimContactName = "";
        trimContactName = typeof e.name == 'object' ? e.name.name.trim() : e.name.trim();
        e.name = trimContactName;
        if (e.brandingThemeID?.brandingThemeID)
          e.brandingThemeID = e.brandingThemeID?.brandingThemeID;

        // Here sets the formats of the lineItem fields
        e.lineItems.forEach((el) => {
          if (el.taxType?.taxType) el.taxType = el.taxType.taxType;
          el.quantity = parseFloat(el.quantity);
          el.unitAmount = parseFloat(el.unitAmount);
          el.disc = parseFloat(el.disc);
          el.discountRate = parseFloat(el.discountRate);
          el.taxAmount = parseFloat(el.taxAmount);
          el.lineAmount = parseFloat(el.lineAmount);
        });
        e.subTotal = parseFloat(e.subTotal);
        e.totalTax = parseFloat(e.totalTax);
        e.total = parseFloat(e.total);
      });
      this.setXeroKeys(invoices);
      this.setDraftKeys(draftInvoices);
      // return promise to submit invoices on Xero
      // TESTING 1
      return ApiService.PostRequest(
        `${UrlServices.submitInvoices}${this.currentWorkspace.workspaceId}`,
        {
          invoices: this.invoicesArr,
          ticketId: this.ticket._id,
          draft: { invoices: this.draftArr },
          items: uniqueItems,
          organizationId: this.accounts.tenantId,
        }
      )
        .then((res) => {
          this.loader = false;
          this.submittedData = true;
          this.$emit("submitted-data", this.submittedData);
          this.$showNotificationBar("success", `Transaction has been posted`);
          return res;
        })
        .catch((e) => {
          console.log("err", e);
          this.loader = false;
          return showErrorMessage(e);
        });
    },

    /**
     * This function submit invoices on Xero
     */
    async submitDraft() {
      // let trackingSelectedItems = this.$refs.invoiceForm.selectedTrackingItems;
      this.draftLoader = true;
      this.archived = false;
      const percentLookupRegex = /^%|%$/g;
      // Setting up status in draft object
      this.dataDraft.invoices[0].status = this.currentWorkspace
        .defaultSubmissionStatus
        ? this.currentWorkspace?.defaultSubmissionStatus
        : "DRAFT";

      let inv = JSON.parse(JSON.stringify(this.dataDraft));
      let draft = JSON.parse(JSON.stringify(this.dataDraft));
      let invoices = inv.invoices;
      let draftInvoices = draft.invoices;
      console.log(draftInvoices, "before validations");
      // Declare hashmaps for unique Items and unique Accounts
      // Declare empty arrays for unique Items and unique Accounts
      const uniqueItemsHashMap = {},
        uniqueItems = [];
      for (let i = 0; i < invoices.length; i++) {
        const { subTotal, totalTax, total, ...ele } = invoices[i];
        ele.name = ele.hasOwnProperty("name") ? ele.name : "";
        /**
         * xeroform header fields validations here
         */
        if (!ele.invoiceNumber) {
          this.draftLoader = false;
          return this.$showNotificationBar(
            "error",
            `Invoice number is required`
          );
        }
        if (!ele.lineAmountTypes) {
          this.draftLoader = false;
          return this.$showNotificationBar(
            "error",
            `Line Amount Type is required`
          );
        }
        if (!ele.name) {
          this.draftLoader = false;
          return this.$showNotificationBar("error", `Name is required`);
        }
        if (!ele.date) {
          this.draftLoader = false;
          return this.$showNotificationBar("error", `Date is required`);
        }
        if (!ele.dueDate) {
          this.draftLoader = false;
          return this.$showNotificationBar("error", `Due date is required`);
        }
        /**
         * xeroform footer fields validations here
         */
        if (!ele.currencyCode) {
          this.draftLoader = false;
          return this.$showNotificationBar("error", `Currency is required`);
        }
        // xeroform header fields validations END
        if (Array.isArray(ele.lineItems)) {
          for (
            let lineItemIndex = 0;
            lineItemIndex < ele.lineItems.length;
            lineItemIndex++
          ) {
            const r = ele.lineItems[lineItemIndex];
            if (!r.description) {
              this.draftLoader = false;
              return this.$showNotificationBar(
                "error",
                `Description is required`
              );
            }
            r.disc == "" ? (r.disc = "0") : r.disc;
            r.taxAmount == "" ? (r.taxAmount = "0") : r.taxAmount;
            if (r.itemCode?.id) {
              if (!r.itemCode.id.startsWith("bot-"))
                r.itemCode.id = `bot-${r.itemCode.id}`;
              if (!uniqueItemsHashMap[r.itemCode.id]) {
                uniqueItems.push({
                  name: r.itemCode.name,
                  code: r.itemCode.id,
                });
                uniqueItemsHashMap[r.itemCode.id] = true;
              }
              r.itemCode = r.itemCode.id;
            } else if (r.itemCode?.id === "") delete r["itemCode.id"];
            if (r.accountCode?.code) r.accountCode = r.accountCode.code;
            r["taxAmount"] =
              typeof r["taxAmount"] === "string"
                ? r["taxAmount"].replace(percentLookupRegex, "")
                : r["taxAmount"];
            if (ele.type == "ACCREC") {
              r["discountRate"] =
                typeof r.disc === "string"
                  ? r.disc.replace(percentLookupRegex, "")
                  : r.disc;
            } else {
              delete r["taxRate"];
              delete r["discountRate"];
              delete r.disc;
            }
          }
        } else ele.lineItems = [];
      }
      invoices.forEach((e) => {
        let trimContactName = "";
        trimContactName = e.name.trim();
        e.name = trimContactName;
        if (e.brandingThemeID?.brandingThemeID)
          e.brandingThemeID = e.brandingThemeID?.brandingThemeID;

        // Here sets the formats of the lineItem fields
        e.lineItems.forEach((el) => {
          if (el.taxType?.taxType) el.taxType = el.taxType.taxType;
          el.quantity = parseFloat(el.quantity);
          el.unitAmount = parseFloat(el.unitAmount);
          el.disc = parseFloat(el.disc);
          el.discountRate = parseFloat(el.discountRate);
          el.taxAmount = parseFloat(el.taxAmount);
          el.lineAmount = parseFloat(el.lineAmount);
        });
        e.subTotal = parseFloat(e.subTotal);
        e.totalTax = parseFloat(e.totalTax);
        e.total = parseFloat(e.total);
      });
      // this.setXeroKeys(invoices);
      this.setDraftKeys(draftInvoices);
      this.saveDraftForm();
    },
    /**
     * Method to save draft from invoices
     */
    async saveDraftForm() {
      try {
        await ApiService.PostRequest(
          `${UrlServices.saveTicketDraft}/${this.ticket._id}`,
          {
            data: this.dataDraft,
            organizationId: this.accounts.tenantId,
          }
        );
        this.$showNotificationBar("success", "Draft has been saved");
        this.draftLoader = false;
        this.submittedData = true;
        this.$emit("submitted-data", this.submittedData);
        // EventBus.$emit("show-snackbar", {
        //   text: "Draft has been saved",
        //   type: "success",
        // });
      } catch (error) {
        console.log("error =>", error);
        showErrorMessage(error);
      }
    },
    /**
     * get Latest Ticket Details
     */
    async getLatestDetails(ticketId) {
      try {
        let config = {
          method: "get",
          url: `${UrlServices.getTicketDetails}${ticketId}`,
          data: {
            status: status,
          },
          headers: {
            Authorization: this.token,
          },
        };
        let response = await axios(config);
        this.dataLoader = false;
        if (response?.data?.ticket?.draft)
          this.dataDraft = response?.data?.ticket?.draft;
        if (this.ticket?.draft) {
          this.dataDraft = this.setFieldValuesToNumberField(this.dataDraft);
          this.setLineItemsAndCalculations();
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    /**
     * This function sets the formats of the lineItem fields at the time of get draft data
     * @param {*} draftObj
     */
    setFieldValuesToNumberField(draftObj) {
      draftObj.invoices.forEach((e) => {
        e.lineItems.forEach((el) => {
          el.quantity = parseFloat(el.quantity);
          el.unitAmount = parseFloat(el.unitAmount);
          el.disc = parseFloat(el.disc);
          el.discountRate = parseFloat(el.discountRate);
          el.taxAmount = parseFloat(el.taxAmount);
          el.lineAmount = parseFloat(el.lineAmount);
        });
        e.subTotal = parseFloat(e.subTotal);
        e.totalTax = parseFloat(e.totalTax);
        e.total = parseFloat(e.total);
      });
      console.log("inside function", draftObj);
      return draftObj;
    },

    /**
     * This function sets lineItem fields and calculations according to them
     */
    setLineItemsAndCalculations() {
      this.dataDraft.invoices.forEach((e) => {
        e.lineItems.forEach((el) => {
          el.disc = el.disc ? el.disc : 0;
          el.discountRate = el.discountRate ? el.discountRate : 0;
          el.taxAmount = el.taxAmount ? el.taxAmount : 0;
          el.taxType = el.taxType ? el.taxType : "";
        });
      });
      this.calcTotalAmount(this.dataDraft.invoices);
      this.calcSubTotal(this.dataDraft.invoices);
      this.calcTotalTax(this.dataDraft.invoices);
      this.calcTotal(this.dataDraft.invoices);
    },

    /**
     * This function sets the permanent payload keys for Xero
     */
    setXeroKeys(invoices) {
      this.invoicesArr = [];
      for (let index = 0; index < invoices.length; ++index) {
        const invoiceObj = {};
        const invoice = invoices[index];

        invoiceObj["address"] = invoice["address"] ? invoice["address"] : "";
        invoiceObj["name"] = invoice["name"] ? invoice["name"] : "";
        invoiceObj["invoiceNumber"] = invoice["invoiceNumber"]
          ? invoice["invoiceNumber"]
          : "";
        invoiceObj["email"] = invoice["email"] ? invoice["email"] : "";
        invoiceObj["date"] = invoice["date"] ? invoice["date"] : "";
        invoiceObj["dueDate"] = invoice["dueDate"] ? invoice["dueDate"] : "";
        invoiceObj["expectedPaymentDate"] = invoice["expectedPaymentDate"]
          ? invoice["expectedPaymentDate"]
          : "";
        invoiceObj["plannedPaymentDate"] = invoice["plannedPaymentDate"]
          ? invoice["plannedPaymentDate"]
          : "";
        invoiceObj["type"] = invoice["type"] ? invoice["type"] : "";
        invoiceObj["invType"] = invoice["invType"] ? invoice["invType"] : "";
        invoiceObj["brandingThemeID"] = invoice["brandingThemeID"]
          ? invoice["brandingThemeID"]
          : "";
        invoiceObj["lineAmountTypes"] = invoice["lineAmountTypes"]
          ? invoice["lineAmountTypes"]
          : "";
        invoiceObj["status"] = invoice["status"] ? invoice["status"] : "";
        invoiceObj["lineItems"] = invoice["lineItems"]
          ? invoice["lineItems"]
          : "";
        invoiceObj["currencyCode"] = invoice["currencyCode"]
          ? invoice["currencyCode"]
          : "";
        invoiceObj["subTotal"] = invoice["subTotal"] ? invoice["subTotal"] : "";
        invoiceObj["totalTax"] = invoice["totalTax"] ? invoice["totalTax"] : "";
        invoiceObj["total"] = invoice["total"] ? invoice["total"] : "";
        invoiceObj["reference"] = invoice["reference"]
          ? invoice["reference"]
          : "";
        if (invoice["url"]) invoiceObj["url"] = invoice["url"];

        invoiceObj["lineItems"] = invoice.lineItems.map((ele) => {
          if (invoiceObj["type"] == "ACCREC") {
            return {
              itemCode: ele.itemCode ? ele.itemCode : "",
              description: ele.description ? ele.description : "",
              quantity: ele.quantity ? ele.quantity : 0,
              unitAmount: ele.unitAmount ? ele.unitAmount : 0,
              disc: ele.disc ? ele.disc : 0,
              discountRate: ele.discountRate ? ele.discountRate : 0,
              taxAmount: ele.taxAmount ? ele.taxAmount : 0,
              accountCode: ele.accountCode ? ele.accountCode : "",
              taxType: ele.taxType ? ele.taxType : "",
              lineAmount: ele.lineAmount ? ele.lineAmount : 0,
              tracking: ele.tracking ? ele.tracking : [],
            };
          } else {
            return {
              itemCode: ele.itemCode ? ele.itemCode : "",
              description: ele.description ? ele.description : "",
              quantity: ele.quantity ? ele.quantity : 0,
              unitAmount: ele.unitAmount ? ele.unitAmount : 0,
              taxAmount: ele.taxAmount ? ele.taxAmount : 0,
              accountCode: ele.accountCode ? ele.accountCode : "",
              taxType: ele.taxType ? ele.taxType : "",
              lineAmount: ele.lineAmount ? ele.lineAmount : 0,
              tracking: ele.tracking ? ele.tracking : [],
            };
          }
        });

        invoiceObj["lineItems"].map((item) => {
          item.tracking.map((ele) => {
            console.log(ele);
            delete ele.trackingOptionID.name;
            delete ele.trackingOptionID.status;
            ele.trackingOptionID = ele.trackingOptionID.trackingOptionID;
          });
        });
        this.invoicesArr.push(invoiceObj);
      }
    },
    setDraftKeys(invoices) {
      this.draftArr = [];
      for (let index = 0; index < invoices.length; ++index) {
        const invoiceObj = {};
        const invoice = invoices[index];

        invoiceObj["address"] = invoice["address"] ? invoice["address"] : "";
        invoiceObj["name"] = invoice["name"] ? invoice["name"] : "";
        invoiceObj["invoiceNumber"] = invoice["invoiceNumber"]
          ? invoice["invoiceNumber"]
          : "";
        invoiceObj["email"] = invoice["email"] ? invoice["email"] : "";
        invoiceObj["date"] = invoice["date"] ? invoice["date"] : "";
        invoiceObj["dueDate"] = invoice["dueDate"] ? invoice["dueDate"] : "";
        invoiceObj["expectedPaymentDate"] = invoice["expectedPaymentDate"]
          ? invoice["expectedPaymentDate"]
          : "";
        invoiceObj["plannedPaymentDate"] = invoice["plannedPaymentDate"]
          ? invoice["plannedPaymentDate"]
          : "";
        invoiceObj["type"] = invoice["type"] ? invoice["type"] : "";
        invoiceObj["invType"] = invoice["invType"] ? invoice["invType"] : "";
        invoiceObj["brandingThemeID"] = invoice["brandingThemeID"]
          ? invoice["brandingThemeID"]
          : "";
        invoiceObj["lineAmountTypes"] = invoice["lineAmountTypes"]
          ? invoice["lineAmountTypes"]
          : "";
        invoiceObj["status"] = invoice["status"] ? invoice["status"] : "";
        invoiceObj["lineItems"] = invoice["lineItems"]
          ? invoice["lineItems"]
          : "";
        invoiceObj["currencyCode"] = invoice["currencyCode"]
          ? invoice["currencyCode"]
          : "";
        invoiceObj["subTotal"] = invoice["subTotal"] ? invoice["subTotal"] : "";
        invoiceObj["totalTax"] = invoice["totalTax"] ? invoice["totalTax"] : "";
        invoiceObj["total"] = invoice["total"] ? invoice["total"] : "";
        invoiceObj["reference"] = invoice["reference"]
          ? invoice["reference"]
          : "";
        if (invoice["url"]) invoiceObj["url"] = invoice["url"];

        invoiceObj["lineItems"] = invoice.lineItems.map((ele) => {
          if (invoiceObj["type"] == "ACCREC") {
            return {
              itemCode: ele.itemCode ? ele.itemCode : "",
              description: ele.description ? ele.description : "",
              quantity: ele.quantity ? ele.quantity : 0,
              unitAmount: ele.unitAmount ? ele.unitAmount : 0,
              disc: ele.disc ? ele.disc : 0,
              discountRate: ele.discountRate ? ele.discountRate : 0,
              taxAmount: ele.taxAmount ? ele.taxAmount : 0,
              accountCode: ele.accountCode ? ele.accountCode : "",
              taxType: ele.taxType ? ele.taxType : "",
              lineAmount: ele.lineAmount ? ele.lineAmount : 0,
              tracking: ele.tracking ? ele.tracking : [],
            };
          } else {
            return {
              itemCode: ele.itemCode ? ele.itemCode : "",
              description: ele.description ? ele.description : "",
              quantity: ele.quantity ? ele.quantity : 0,
              unitAmount: ele.unitAmount ? ele.unitAmount : 0,
              taxAmount: ele.taxAmount ? ele.taxAmount : 0,
              accountCode: ele.accountCode ? ele.accountCode : "",
              taxType: ele.taxType ? ele.taxType : "",
              lineAmount: ele.lineAmount ? ele.lineAmount : 0,
              tracking: ele.tracking ? ele.tracking : [],
            };
          }
        });
        this.draftArr.push(invoiceObj);
      }
    },

    /**
     * This function calculates the total lineAmount in every lineitem
     * @param {*} invoiceItem
     */
    calcTotalAmount(invoiceItem) {
      let total = 0;
      invoiceItem.forEach((inv) => {
        inv.lineItems.forEach((el) => {
          if (isNaN(el.quantity)) el.quantity = 0;
          if (isNaN(el.unitAmount)) el.unitAmount = 0;
          // el.discountRate = 0;
          // el.taxAmount = 0;
          if (typeof el.discountRate === "string")
            el.discountRate = el.discountRate.replace(/^%|%$/g, "");
          if (typeof el.taxAmount === "string")
            el.taxAmount = el.taxAmount.replace(/^%|%$/g, "");
          let x =
            invoiceItem.type === "ACCPAY"
              ? 1
              : (100 - Math.round(el.discountRate * 100) / 100) / 100;
          total = +el.quantity * +el.unitAmount * x;
          el.lineAmount = Math.round(total * 100) / 100;
        });
      });
    },

    /**
     * This function calculates the subTotal in footer
     * @param {*} invoiceItem
     */
    calcSubTotal(invoiceItem) {
      invoiceItem.forEach((inv) => {
        let invoiceSubTotal = 0;
        inv.lineItems.forEach((el) => {
          let totalLineAmount = Number(el.lineAmount);
          invoiceSubTotal += totalLineAmount;
        });
        inv["subTotal"] = Math.round(invoiceSubTotal * 100) / 100;
      });
    },

    /**
     * This function calculates the totalTax in footer
     * @param {*} invoiceItem
     */
    calcTotalTax(invoiceItem) {
      invoiceItem.forEach((inv) => {
        let invoiceTotalTax = 0;
        if (inv.lineAmountTypes == "Exclusive") {
          inv.lineItems.forEach((el) => {
            if (!el.taxAmount && el.taxAmount == undefined) el.taxAmount = 0;
            let totalTaxAmount = Number(el.taxAmount);
            invoiceTotalTax += totalTaxAmount;
          });
          inv["totalTax"] = Math.round(invoiceTotalTax * 100) / 100;
        } else {
          inv["totalTax"] = Math.round(invoiceTotalTax * 100) / 100;
        }
      });
    },

    /**
     * This function calculates final total(subTotal+totalTax) in footer
     * @param {*} invoiceItem
     */
    calcTotal(invoiceItem) {
      invoiceItem.forEach((inv) => {
        let total = 0;
        total = Number(inv.subTotal) + Number(inv.totalTax);
        inv["total"] = Math.round(total * 100) / 100;
      });
    },
  },

  // component beforeDestroy hook
  beforeDestroy() {
    console.log("checking if destroy is working or not ");
    // this.modalValue = true;
    // console.log(this.modalValue)

    // let text = "Press a button!\nEither OK or Cancel.";
    // if (confirm(text) == true) {
    //   text = "You pressed OK!";
    // } else {
    //   text = "You canceled!";
    // }
  },
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;

  margin: 0px;
  padding: 0px;
}

.scrollable-div {
  flex-grow: 1; /* Allow this div to grow and take remaining space */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  padding-left: 20px;
}

.static-div {
  /* background-color: white; */
  text-align: center; /* Optional centering content */
  border-top: 1px solid #dbdadf;
  overflow-y: scroll;
  padding: 10px;
}

.static-div-payment {
  /* background-color: red; */
  text-align: center; /* Optional centering content */
  border-top: 1px solid #dbdadf;
  overflow-y: scroll;
}

.custom-btn {
  background-color: rgba(53, 16, 167, 0.05) !important;
  border-radius: 8px;
  min-height: 35px;
  line-height: 19px;
  color: var(--v-lightPurple-base) !important;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  margin-left: 10px;
}
</style>
