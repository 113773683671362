var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"content-class":"messagebox","width":"90%","persistent":""},model:{value:(_vm.showpopup),callback:function ($$v) {_vm.showpopup=$$v},expression:"showpopup"}},[_c('v-row',{staticClass:"header"},[_c('v-col',{staticClass:"left-side",attrs:{"md":"8"}},[(_vm.selectedFile)?_c('div',{staticClass:"ml-2"},[(
              [
                'jpg',
                'png',
                'jpeg',
                'pdf',
                'docs',
                'doc',
                'xls',
                'xlsx',
                'csv',
                'docx',
                'zip',
              ].includes(_vm.selectedFile.file.originalname.split('.').pop())
            )?_c('v-img',{staticClass:"file-type",attrs:{"max-width":"40","src":require(`@/assets/dashboard/${_vm.selectedFile.file.originalname
                .split('.')
                .pop()}.svg`)}}):_c('v-img',{staticClass:"file-types",attrs:{"max-width":"26","max-height":"33","src":require("@/assets/dashboard/other.svg")}})],1):_vm._e(),(_vm.selectedFile)?_c('div',{staticClass:"detail"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.selectedFile.file.originalname))]),_c('p',{staticClass:"date"},[_vm._v(" Shared Date "+_vm._s(_vm._f("formatDate")(_vm.selectedFile.createdAt))+" ")])]):_vm._e()]),_c('v-col',{staticClass:"right-side",attrs:{"md":"4"}},[_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"md":"10"}},[_c('p',{staticClass:"fs-18 fw-600 mb-0"},[_vm._v(" Ticket ID "),_c('span',[_vm._v(_vm._s(_vm.ticket.ticketNo))])]),_c('p',{staticClass:"sub-title mb-0"},[_vm._v("Recent Documents")])]),_c('v-col',{staticClass:"pl-0 pr-10",attrs:{"md":"1"}},[_c('span',{staticClass:"material-icons custom-close-icon",on:{"click":function($event){return _vm.$emit('close-popup')}}},[_vm._v(" close ")])])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"documents-view",attrs:{"md":"8"}},[(_vm.selectedFile)?_c('div',{staticClass:"file-viewer-side"},[(
              ['jpg', 'png', 'jpeg', 'svg'].includes(
                _vm.selectedFile.file.originalname.split('.').pop()
              ) && _vm.data
            )?_c('img',{attrs:{"src":_vm.file}}):_vm._e(),(
              ['doc', 'docx', 'pptx', 'ppt', 'xlsx', 'xls'].includes(
                _vm.selectedFile.file.originalname.split('.').pop()
              ) && _vm.data
            )?_c('VueDocPreview',{attrs:{"value":_vm.file,"type":"office"}}):_vm._e(),(
              ['pdf'].includes(
                _vm.selectedFile.file.originalname.split('.').pop()
              ) && _vm.data
            )?_c('VuePdfEmbed',{attrs:{"source":_vm.file}}):_vm._e()],1):_c('div',{staticClass:"file-not-found-document"},[_c('v-img',{attrs:{"src":require("@/assets/dashboard/file.svg"),"width":"200"}}),_c('div',{staticClass:"mt-5 fw-400 fs-20 lh-27 f-inter fs-normal"},[_vm._v(" No File Selected ")])],1)]),_c('v-col',{staticClass:"side-action",attrs:{"md":"4"}},[_c('div',{staticClass:"documents-list"},[(_vm.ticket.attachmentUrl)?_c('div',[_c('div',{staticClass:"documents",class:_vm.selectedFile._id == _vm.ticket._id ? 'active' : '',on:{"click":function($event){return _vm.openAttachedDocument(_vm.ticket)}}},[(
                  _vm.ticket.previewFileName &&
                  _vm.ticket.previewFileName !== 'type not supported'
                )?_c('v-img',{staticClass:"preview-image",attrs:{"src":_vm.backendURL + _vm.ticket.previewFileName,"width":"304px","height":"111px","cover":""}}):_c('v-img',{staticClass:"preview-image",attrs:{"src":require("@/assets/general-format.jpeg"),"width":"304px","height":"111px","contain":""}}),_c('div',{staticClass:"description"},[(
                    [
                      'jpg',
                      'png',
                      'jpeg',
                      'pdf',
                      'docs',
                      'doc',
                      'xls',
                      'xlsx',
                      'csv',
                      'docx',
                      'zip',
                    ].includes(_vm.ticket.attachmentUrl.split('.').pop())
                  )?_c('v-img',{staticClass:"file-type",attrs:{"max-width":"26","max-height":"33","src":require(`@/assets/dashboard/${_vm.ticket.attachmentUrl
                      .split('.')
                      .pop()}.svg`)}}):_c('v-img',{staticClass:"file-types",attrs:{"max-width":"26","max-height":"33","src":require("@/assets/dashboard/other.svg")}}),_c('div',{staticClass:"detail"},[(_vm.ticket.attachmentName)?_c('p',{staticClass:"file-name"},[_vm._v(" "+_vm._s(_vm._f("minimizeFileName")(_vm.ticket.attachmentName))+" ")]):_c('p',{staticClass:"file-name"},[_vm._v("Attached File")]),_c('p',{staticClass:"file-date"},[_vm._v(" Shared Date "+_vm._s(_vm._f("formatDate")(_vm.ticket.createdAt))+" ")])])],1)],1)]):_vm._e(),_vm._l((_vm.ticket.files),function(item,index){return _c('div',{key:index},[(item.file.originalname.split('.').pop() !== 'wav')?_c('div',{staticClass:"documents",class:_vm.selectedFile._id == item._id ? 'active' : '',on:{"click":function($event){return _vm.selectFile(item)}}},[(
                  item.previewFileName &&
                  item.previewFileName !== 'type not supported'
                )?_c('v-img',{staticClass:"preview-image",attrs:{"src":_vm.backendURL + item.previewFileName,"width":"304px","height":"111px","cover":""}}):_c('v-img',{staticClass:"preview-image",attrs:{"src":require("@/assets/general-format.jpeg"),"width":"304px","height":"111px","contain":""}}),_c('div',{staticClass:"description"},[(
                    [
                      'jpg',
                      'png',
                      'jpeg',
                      'pdf',
                      'docs',
                      'doc',
                      'xls',
                      'xlsx',
                      'csv',
                      'docx',
                      'zip',
                    ].includes(item.file.originalname.split('.').pop())
                  )?_c('v-img',{staticClass:"file-type",attrs:{"max-width":"26","max-height":"33","src":require(`@/assets/dashboard/${item.file.originalname
                      .split('.')
                      .pop()}.svg`)}}):_c('v-img',{staticClass:"file-types",attrs:{"max-width":"26","max-height":"33","src":require("@/assets/dashboard/other.svg")}}),_c('div',{staticClass:"detail"},[_c('p',{staticClass:"file-name"},[_vm._v(" "+_vm._s(_vm._f("minimizeFileName")(item.file.originalname))+" ")]),_c('p',{staticClass:"file-date"},[_vm._v(" Shared Date "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")])])],1)],1):_vm._e()])})],2)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }