<template>
  <div class="mt-4">
    <!-- <div class="mt-4"> -->
    <Dialog :modal="modalValue" @close-clicked="modalValue = false" @save-clicked="closeChatMiniFunctionForXeroForm"
      v-if="!currentWorkspace.rewriteInvoice || user.role == 'client'">
      <template v-slot:header> Confirmation </template>
      <template v-slot:content>
        Are you sure you want to leave, all unsaved changes will be lost
      </template>
      <template v-slot:buttonHeading> Ok </template>
    </Dialog>

    <approve-reject-confirmation ref="Confirmation" @resetSelected="selected = []" />
    <publish-invoices ref="Publish" @resetSelected="selected = []" />

    <v-row class="border-b pt-1">
      <v-col class="pa-0 pl-8" :class="$vuetify.breakpoint.xs && 'mt-8 text-end'"
        :cols="!$vuetify.breakpoint.lgAndUp ? 12 : 6">


        <v-btn v-show="!user.isEmployee && selected.length > 0" class="custom-btn f-inter fw-600 fs-12 ms-0"
          @click="publishInvoices()"><span class="fw-600 fs-12 f-inter text-none">Publish </span></v-btn>
        <v-btn v-show="!user.isEmployee && selected.length > 0" class="custom-btn f-inter fw-600 fs-12 ms-5"
          @click="updateAuditRequest('approved')" :loading="approvedLoader"><span
            class="fw-600 fs-12 f-inter text-none">Approve</span></v-btn>
        <v-btn v-show="!user.isEmployee && selected.length > 0" class="custom-btn f-inter fw-600 fs-12 ms-5"
          @click="updateAuditRequest('rejected')"><span class="fw-600 fs-12 f-inter text-none">Reject</span></v-btn>
      </v-col>
      <v-col class="pa-0" col="6" :class="$vuetify.breakpoint.xs ? 'pa-0' : ''">
        <div class="d-flex flex-row justify-end align-center mb-3">
          <!-- <FilterButton/> -->
          <v-btn id="v-step-2" class="custom-btn f-inter fw-600 fs-22"
            @click="$router.push({ path: 'generate-request' })"><v-img src="@/assets/ticket/receipt.svg"></v-img><span
              style="letter-spacing: 0" class="fw-600 fs-12 f-inter ml-2 text-none">Record Now
            </span></v-btn>
          <div class="text-center">
            <v-menu offset-y :close-on-content-click="false" v-model="menu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                  <SettingsButton />
                </v-btn>
              </template>
              <v-container :style="$vuetify.breakpoint.xs && 'width: 310px !important'" class="settings">
                <v-sheet class="f-inter fs-14 fw-600 mt-2 mb-3" style="color: var(--v-lightPurple-base)">Default</v-sheet>
                <!-- Top checkbox content -->
                <v-row no-gutters>
                  <v-col cols="6" v-for="(item, index) in optionalHeaders" :key="index" v-show="item.showInDefault">
                    <v-checkbox class="settings-checkbox mt-0 mb-3" :label="item.text" hide-details v-model="item.default"
                      :ripple="false"></v-checkbox>
                  </v-col>
                </v-row>
                <v-divider class="border-opacity-25"></v-divider>
                <v-sheet class="f-inter fs-14 fw-600 mt-3 mb-3" style="color: var(--v-lightPurple-base)">More
                  Columns</v-sheet>
                <!-- Bottom checkbox content -->
                <!-- v-for="(item, index) in (retainedValue ? optionalHeaders : moreColumnsRetainedValue)" -->
                <v-row no-gutters>
                  <v-col cols="6" v-for="(item, index) in optionalHeaders" :key="index"
                    v-show="item.showInList && !item.showInDefault">
                    <v-checkbox class="settings-checkbox mt-0 mb-3" :label="item.text" hide-details v-model="item.default"
                      :ripple="false"></v-checkbox>
                  </v-col>
                </v-row>
                <div class="d-flex flex-row-reverse mt-3">
                  <v-btn class="custom-btn text-none ml-3" @click="addModelHeaders"><span
                      class="fs-12 fw-500 f-inter lightPurple--text">Save</span></v-btn>
                  <v-btn class="custom-cancel-btn ml-3 text-none" @click="resetCheckbox"><span
                      class="fs-12 fw-500 f-inter subHeading--text">Cancel</span></v-btn>
                </div>
              </v-container>
            </v-menu>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- This code insert in DOM when application open in mobile -->
    <div v-if="!$vuetify.breakpoint.lgAndUp" class="mt-3 row action header">
      <div class="w-100" cols="12" col-sm="12" col-md="6" col-lg="6">
        <!-- show all filters which have been applied -->
        <div class="d-flex justify-space-between">
          <p class="mt-3 mr-2 mb-0 dark--text fw-12 fw-400" v-if="clearFilterValue">
            Filter By:
          </p>
          <p>
            <v-btn v-if="clearFilterValue" class="text-capitalize clear-btn mr-3 mt-6 mb-0" color="primary" depressed
              width="108" height="38" text @click="clearFilter()"><span class="fw-600 fs-13 black--text mb-0">Clear Filter
              </span></v-btn>
          </p>
        </div>
        <ShowFilters :filtersData="filtersData" v-if="clearFilterValue" />
      </div>
    </div>
    <v-data-table id="v-step-5" :headers="mainHeaders" :items="tickets" :hide-default-footer="true" :loading="loader"
      fixed-header height="73vh" :itemsPerPage="paginate.itemsPerPage"
      :class="$vuetify.breakpoint.lgAndUp ? 'table mt-5' : 'mt-2'" show-select item-key="_id"
      :single-select="singleSelect" v-model="selected" :checkbox-color="'lightPurple'" class="text-no-wrap fs-12">

      <template v-for="(header, index) in mainHeaders" v-slot:[`header.${header.value}`]="{ header }">
        <th class="fs-14 fw-700 f-inter totalBlack--text" :key="index">
          {{ header.text }}
        </th>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        <span class="textPrimary--text fw-400 fs-12 f-inter">
          {{ item.createdAt | formatISODate }}
        </span>
      </template>

      <template v-slot:[`item.message`]="{ item }">
        <div class="cursor-pointer ticket-description textPrimary--text fw-600 f-inter fs-13" @click="
          $vuetify.breakpoint.lgAndUp
            ? openMessageDialog(item)
            : openMobileMessageDialog(item)
          ">
          {{ item.message }}
        </div>
      </template>

      <template v-slot:[`item.ticketNo`]="{ item }">
        <div class="cursor-pointer textPrimary--text fw-400 fs-12 f-inter" @click="
          $vuetify.breakpoint.lgAndUp
            ? openMessageDialog(item)
            : openMobileMessageDialog(item)
          ">
          <span @click="copyText(item.ticketNo)">{{ item.ticketNo }}</span>

        </div>
      </template>

      <template v-slot:[`item.requestType`]="{ item }">
        <div class="cursor-pointer textPrimary--text fw-400 fs-12 f-inter" @click="
          $vuetify.breakpoint.lgAndUp
            ? openMessageDialog(item)
            : openMobileMessageDialog(item)
          ">
          {{ changeStringToSentenceCase(item.requestType) }}
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="d-flex" @click="
          $vuetify.breakpoint.lgAndUp
            ? openMessageDialog(item)
            : openMobileMessageDialog(item)
          ">
          <v-chip v-for="status in statusOptions" :key="status.value"
            :class="`status-chip px-5 f-inter fw-400 fs-12 ${status.class}`" small v-show="item.status == status.value">
            {{ status.label }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <!-- if screen is lg then these actions will be show  -->
        <div class="actions d-flex justify-start flex-row">
          <v-badge v-if="item.unreadBy &&
            item.unreadBy[user._id] !== undefined &&
            item.unreadBy[user._id] > 0
            " :content="item.unreadBy[user._id]" class="message-icon cursor-pointer" bordered color="error" overlap
            @click.native="
              $vuetify.breakpoint.lgAndUp
                ? openMessageDialog(item)
                : openMobileMessageDialog(item)
              ">
            <v-img class="cursor-pointer" src="@/assets/dashboard/message.png" max-width="26" size="small"></v-img>
          </v-badge>
          <v-img v-else class="cursor-pointer" @click="
            $vuetify.breakpoint.lgAndUp
              ? openMessageDialog(item)
              : openMobileMessageDialog(item)
            " src="@/assets/ticket/chat-dots.svg" max-width="16" size="small"></v-img>

          <v-img class="cursor-pointer ml-2" src="@/assets/ticket/paper-clip.svg" @click="
            $vuetify.breakpoint.lgAndUp
              ? openFilesDialog(item)
              : openMobileFilesDialog(item)
            " max-width="16" size="small"></v-img>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img v-show="item.xeroSubmission && item.status == 'Posted'" class="ml-2" max-height="16" max-width="16"
                size="small" src="../../assets/xero-buttons/xero-icon.svg" @click="reRouteToXeroLink(item)" v-bind="attrs"
                v-on="on" />
            </template>
            <span>Submitted on Xero</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <div class="pagination-border px-2">
      <pagination class="mt-3" :itemsPerPage="paginate.itemsPerPage" :page="paginate.page" :pageCount="paginate.lastPage"
        :totalCount="paginate.total" @next-page-change="getTickets($event)" @prev-page-change="getTickets($event)"
        @page-number-changed="getTickets($event)" @items-per-page-change="getTickets($event)"></pagination>
    </div>

    <MessageBox :ticket="ticketDetails" @close-popup="closeChat" @file-sent="openMessageDialog(ticketDetails)"
      @review-request="reviewRequest(ticketDetails)" @audit-request="(val) => auditRequest(val, ticketDetails)"
      v-if="messageBoxDialog"></MessageBox>
    <DocumentViewer :ticket="ticketDetails" @close-popup="filesDialog = false" v-if="filesDialog"></DocumentViewer>

    <!-- for mobile -->

    <TicketChat v-if="mobileMessageDialog" @close-message-dialog="closeChat" :ticket="mobileTicket"
      @audit-request="(val) => auditRequest(val, ticketDetails)" @review-request="reviewRequest(ticketDetails)">
    </TicketChat>
    <FilesDetail v-if="mobileFilesDialog" @close-file-dialog="mobileFilesDialog = false"
      :mobileFilesDialog="mobileFilesDialog" :ticket="ticketDetails"></FilesDetail>
  </div>
</template>

<script>
import FilterButton from "../../components/common/FilterButton.vue";
import ShowFilters from "../../components/common/ShowFilters.vue";
import TicketChat from "../../components/mobile/generate_requests/TicketChat.vue";
import FilesDetail from "../../components/mobile/generate_requests/FilesDetail.vue";
import MessageBox from "../../components/common/MessageBox.vue";
import DocumentViewer from "../../components/common/DocumentViewer.vue";
import Pagination from "../../components/common/Pagination.vue";
import { mapGetters } from "vuex";
import UrlServices from "../../services/Url";
import axios from "axios";
import EventBus from "../../js/EventBus";
import socket from "../../services/SocketIo";
import Vue from "vue";
import ApiService from "../../services/Api";
import { isMobile } from "mobile-device-detect";
import Dialog from "../../components/common/dialog-box";
import requestHistoryHeaderList from "../../constants/requestHistoryHeadersList.js";
import SettingsButton from "../../assets/svg-icons/transaction-page-setings.vue";
import ApproveRejectConfirmation from "../../components/generate_reports/modals/ApproveRejectConfirmation.vue";
import PublishInvoices from "../../components/generate_reports/modals/PublishInvoices.vue";

export default {
  components: {
    MessageBox,
    Pagination,
    DocumentViewer,
    FilterButton,
    ShowFilters,
    TicketChat,
    FilesDetail,
    Dialog,
    SettingsButton,
    ApproveRejectConfirmation,
    PublishInvoices,
  },
  props: {
    childDrawer: Boolean,
  },
  data() {
    return {
      selectedTicketIds: [],
      auditStatus: "",
      retainedValue: true,
      moreColumnsRetainedValue: [],
      menu: false,
      singleSelect: false,
      selected: [],
      modelHeaders: [],
      allHeadersWithShowInList: [],
      mainHeaders: [],
      allHeaders: requestHistoryHeaderList,
      currentDevice: isMobile,
      mobileMessageDialog: false,
      mobileTicket: {},
      clearFilterValue: false,
      status: "All",
      tickets: [],
      loader: false,
      ticketDetails: {},
      createTicketDialog: false,
      messageBoxDialog: false,
      filesDialog: false,
      mobileFilesDialog: false,
      filtersData: [],
      dialog: {
        show: true,
        title: "Record Now",
        dialogType: "form",
        for: "create-ticket",
        inputs: [
          {
            name: "ticket_type",
            label: "Request type",
            placeholder: "Select request type",
            type: "select",
            options: ["Bill", "Voucher"],
            cols: 12,
            col_md: 12,
          },
          {
            name: "description",
            label: "Description",
            placeholder: "Enter Your Message Here...",
            type: "textarea",
            cols: 12,
            col_md: 12,
          }
        ]
      },
      items: [
        {
          label: "All",
          value: "All",
        },
        {
          label: "Complete",
          value: "Complete",
        },
        {
          label: "Pending",
          value: "Pending",
        },
        {
          label: "In Progress",
          value: "InProgress",
        },
        {
          label: "Awaiting Response",
          value: "AwaitingResponse",
        },
        {
          label: "Responded",
          value: "ClientResponded",
        },
        {
          label: "Audit",
          value: "Audit",
        },
        {
          label: "Rejected",
          value: "Rejected",
        }
      ],
      statusOptions: [
        { value: "Posted", label: "Posted", class: "new-posted-chip" },
        {
          value: "Processing",
          // label: "In Progress",
          label: "Processing",
          class: "new-processing-chip",
        },
        {
          value: "PendingApproval",
          label: "Pending Approval",
          class: "new-pending-approval-chip",
        },
        {
          value: "Saved",
          label: "Saved",
          class: "new-saved-chip",
        },
        { value: "Audit", label: "Audit", class: "audit-chip" },
        { value: "Rejected", label: "Rejected", class: "new-rejected-chip" },
        { value: "Pending", label: "Pending", class: "new-pending-chip" },
      ],
      modalValue: false,
      showHighAlert: false,
      approvedLoader: false,
    };
  },
  watch: {

  },
  created() {
    // employee tickets
    socket.on("employee-tickets", (body) => {
      if (
        this.token &&
        body.clientDetail._id === this.user._id &&
        this.currentWorkspace._id === body.workspace._id
      ) {
        this.getTickets(null);
      }
    });
    socket.on("ticket-status-update", (body) => {
      if (
        this.token &&
        this.currentWorkspace.workspaceId === body.workspaceId
      ) {
        console.log(body, "TICKET BODY in if");
        let index = this.tickets.findIndex((item) => item._id === body._id);
        console.log(this.tickets, "tickets", index);
        if (index !== -1) Vue.set(this.tickets, index, body);
      }
    });
    // for multiple tickets update using hash map
    socket.on("ticket-status-update-multiple", (body) => {
      if (this.token && this.currentWorkspace._id === body.workspaceId) {
        const bodyHashMap = body.tickets.reduce((carry, item) => {
          const { _id } = item;
          if (!carry[_id]) {
            carry[_id] = item;
          }
          return carry;
        }, {});

        this.tickets.map((ticket) => {
          const newStatus = bodyHashMap[ticket._id]?.status;
          const newInvoiceUrl = bodyHashMap[ticket._id]?.xeroTicketUrl;
          const submitted = bodyHashMap[ticket._id]?.xeroSubmission;
          newStatus && (ticket.status = newStatus);
          newInvoiceUrl && (ticket.xeroTicketUrl = newInvoiceUrl);
          submitted && (ticket.xeroSubmission = submitted);

          return ticket;
        });
      }
    });
    socket.on("update-tickets-in-employee-owner", (body) => {
      if (
        this.token &&
        body.clientDetail._id === this.user._id &&
        this.currentWorkspace._id === body.workspace._id
      ) {
        this.getTickets(null);
      }
    });
    socket.on("comment", (body) => {
      if (
        this.currentWorkspace._id === body.workspaceId
        // && body.userId !== this.user._id
      ) {
        let index = this.tickets.find((item) => item._id == body.ticketId);
        if (index !== undefined) {
          if (
            (this.messageBoxDialog || this.mobileMessageDialog) &&
            this.ticketDetails._id === body.ticketId
          )
            return;
          else {
            if (index.unreadBy && index.unreadBy[this.user._id] !== undefined)
              index.unreadBy[this.user._id] =
                index?.unreadBy[this.user._id] + 1;
            else {
              index["unreadBy"] = {};
              index.unreadBy[this.user._id] = 1;
            }
          }
        }
      }
    });
    EventBus.$on("ticket-for-audit", (ticket) => {
      if (this.currentDevice) {
        this.openMobileMessageDialog(ticket);
      } else {
        this.openMessageDialog(ticket);
      }
    });
    EventBus.$on("advance-search", () => {
      this.clearFilterValue = true;
      this.resetSomeData();
      this.getTickets(null);
    });
    EventBus.$on("global-search", () => {
      //this.resetSomeData();
      this.getTickets(null);
    });
    EventBus.$on("advance-search-filters-data", (data) => {
      this.clearFilterValue = true;
      this.filtersData = [];
      data.requestType.forEach((element) => {
        if (element.length > 1) {
          this.filtersData.push(element);
        }
      });
      data.searchStatus.forEach((ele) => {
        if (ele.length > 1) {
          this.filtersData.push(ele);
        }
      });

      data.dateRange.length > 0 ? this.filtersData.push(data.dateRange) : "";
      data.messageFilter.length > 0
        ? this.filtersData.push(data.messageFilter)
        : "";
    });
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      paginate: "ticket/getPaginate",
      user: "auth/getUser",
      token: "auth/getAuthToken",
      advancedSearch: "ticket/getAdvancedSearch",

      workspaces: "auth/getUserWorkSpaces",
    }),
    optionalHeaders() {
      return this.allHeaders.filter(
        (item) => item.hasOwnProperty("showInList") && item.showInList === true
      );
    },
  },

  mounted() {
    this.addModelHeaders();
    this.resetSomeData();
    this.getTickets(null);
    this.checkPendingTickets();
    // if (localStorage.getItem("advanceRoute") == "true") this.clearFilterValue = true;
    /**
     * This below event calls only
     * when filtersData has minimum one object
     * clearFilterValue is true
     */
    EventBus.$on("clear-filters", () => {
      if (this.filtersData && this.clearFilterValue) {
        this.$router.go(this.$router.currentRoute);
        this.clearFilterValue = false;
      }
    });
  },
  beforeDestroy() {
    socket.off("employee-tickets");
    socket.off("update-tickets-in-employee-owner");
    socket.off("ticket-status-update");
    EventBus.$off("global-search");
    EventBus.$off("ticket-for-audit");
    this.resetSomeData();
    this.clearFilter(true);
  },
  methods: {
    resetCheckbox() {
      for (let header of this.allHeaders) {
        if (header.showInList && !header.showInDefault) {
          header.default = false;
        } else if (header.showInList && header.showInDefault) {
          header.default = true;
        }
      }
      this.addModelHeaders();
    },
    addModelHeaders() {
      this.allHeadersWithShowInList = this.allHeaders.filter(
        (item) => item.default && !item.showInList
      );
      const newData = this.allHeaders.filter(
        (item) => item.showInList && item.default
      );
      this.modelHeaders = newData.map((item) => item.value);
      this.modifyMainHeaders(newData);
      this.menu = false;
      this.retainedValue = true;
    },
    resetRetainedColumnValues() {
      this.moreColumnsRetainedValue = this.allHeaders;
      this.menu = false;
      this.retainedValue = false;
    },

    changeMainHeader() {
      const newData = this.allHeaders.filter((option) =>
        this.modelHeaders.includes(option.value)
      );
      this.modifyMainHeaders(newData);
    },
    modifyMainHeaders(newData) {
      this.mainHeaders = [...this.allHeadersWithShowInList, ...newData];
      let lastIndex = this.mainHeaders.length - 1;
      for (let i = 0; i < lastIndex; i++) {
        if (this.mainHeaders[i].value === "status") {
          const statusItem = this.mainHeaders.splice(i, 1)[0];
          this.mainHeaders.push(statusItem);
          break; // Stop loop once staus is moved to the end
        }
      }
      for (let i = 0; i < lastIndex; i++) {
        if (this.mainHeaders[i].value === "action") {
          const actionItem = this.mainHeaders.splice(i, 1)[0];
          this.mainHeaders.push(actionItem);
          break; // Stop loop once action is moved to the end
        }
      }
    },
    // method to approve or reject multiple tickets
    updateAuditRequest(status) {
      if (this.selected.length > 0) {
        this.$refs.Confirmation.openDialog(status, this.selected);
      }
    },

    publishInvoices() {
      if (this.selected.length > 0) {
        this.$refs.Publish.openDialog(this.selected);
      }
    },
    /**
     * Check the status of tickets after 1 minute on mounted,
     * if there is any ticket with pending status, show high alert icon
     */
    checkPendingTickets() {
      setTimeout(() => {
        ApiService.GetRequest(
          `ticket/by-workspace-id/${this.currentWorkspace.workspaceId}?ticketNo=&page=1&dateRange=&message=&size=10&status[]=Pending`
        )
          .then((res) => {
            if (res.data.tickets.length > 0) {
              this.showHighAlert = true;
            }
          })
          .catch((err) => {
            console.log(err.response.data);
            this.showHighAlert = false;
          });
      }, 100);
      // 60000
    },
    copyText(text) {
      navigator.clipboard.writeText(text);

      this.$showNotificationBar("success", "Ticket No. has been copied!");
    },
    closeChat(payload) {
      if (!payload) return this.closeChatMiniFunctionForXeroForm();
      this.getTickets(null);
      const {
        key1: xeroFormValue,
        key2: getComparisonValueBoolean,
        key3: submittedData,
      } = payload;
      if (xeroFormValue) {
        if (submittedData || getComparisonValueBoolean) {
          this.mobileMessageDialog = false;
          this.messageBoxDialog = false;
        } else if (!submittedData || !getComparisonValueBoolean)
          this.modalValue = true;
      } else {
        this.closeChatMiniFunctionForXeroForm();
      }
    },
    closeChatMiniFunctionForXeroForm() {
      this.modalValue = false;
      this.mobileMessageDialog = false;
      this.messageBoxDialog = false;
      this.mobileTicket = {};
      this.$store.dispatch(
        "ticket/setTicketAsReadable",
        this.ticketDetails,
        this.page
      );
      this.ticketDetails = {};
    },
    async reviewRequest(ticket) {
      this.reviewTicket(ticket);
    },
    changeStringToSentenceCase(item) {
      const arr = item.toLowerCase().split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return arr.join(" ");
    },
    seachFilter() {
      EventBus.$emit("search-filter");
    },
    clearFilter(val) {
      const obj = {
        dateRange: "",
        messageFilter: "",
        searchStatus: [],
        requestType: [],
      };
      this.$store.commit("ticket/setAdvancedSearch", obj);
      this.clearFilterValue = false;
      // localStorage.removeItem("advanceRoute");
      val ? "" : this.$router.go(this.$router.currentRoute);
    },
    async reviewTicket(ticket) {
      console.log(ticket._id);

      axios
        .get(`${UrlServices.reviewTicket}${ticket._id}`, {
          headers: {
            Authorization: this.token,
          },
        })
        .then((response) => {
          if (this.currentDevice) {
            this.openMobileMessageDialog(ticket);
          } else {
            this.openMessageDialog(ticket);
          }

          let index = this.tickets.findIndex(
            (item) => item._id === response.data.ticket._id
          );
          if (index !== -1) {
            Vue.set(this.tickets, index, response.data.ticket);
          }
          //this.$store.commit('ticket/updateSpecificTicket', response.data.ticket)
        })
        .catch((error) => {
          this.$showNotificationBar("error", error);
        });
    },
    async openMessageDialog(ticket) {
      try {
        if (ticket.isSeen == false || ticket.unreadBy[this.user._id] > 0) {
          // ticket message
          let newTicket = { ...ticket };
          if (
            newTicket.unreadBy &&
            newTicket.unreadBy[this.user._id] !== undefined &&
            newTicket.unreadBy[this.user._id] > 0
          ) {
            newTicket.unreadBy[this.user._id] = 0;
            let index = this.tickets.findIndex(
              (item) => item._id === ticket._id
            );
            if (index !== -1) Vue.set(this.tickets, index, newTicket);
          }
          // end Tickets
          this.$store
            .dispatch("ticket/setTicketAsReadable", ticket, this.page)
            .then((res) => {
              console.log("res here =>", res);
            })
            .catch((e) => console.log(e));
        }
        // let response = await ApiService.GetRequest(
        //   UrlServices.getTicketDetails + ticket._id
        // );
        let response = await ApiService.GetRequest(
          UrlServices.getTicketDetails + ticket._id
        );

        if (response.data && response.data.ticket)
          this.ticketDetails = response.data.ticket;

        if (this.currentDevice) {
          this.mobileMessageDialog = true;
        } else {
          this.messageBoxDialog = true;
        }
      } catch (error) {
        console.log("error in retrieving data", error.message);
      }
    },
    async openMobileMessageDialog(ticket) {
      try {
        if (ticket.isSeen == false || ticket.unreadBy[this.user._id] > 0) {
          // ticket message
          let newTicket = { ...ticket };
          if (
            newTicket.unreadBy &&
            newTicket.unreadBy[this.user._id] !== undefined &&
            newTicket.unreadBy[this.user._id] > 0
          ) {
            newTicket.unreadBy[this.user._id] = 0;
            let index = this.tickets.findIndex(
              (item) => item._id === ticket._id
            );
            if (index !== -1) Vue.set(this.tickets, index, newTicket);
          }
          // end Tickets
          this.$store
            .dispatch("ticket/setTicketAsReadable", ticket, this.page)
            .then((res) => {
              console.log("res here =>", res);
            })
            .catch((e) => console.log(e));
        }
        let response = await ApiService.GetRequest(
          UrlServices.getTicketDetails + ticket._id
        );
        if (response.data && response.data.ticket)
          this.ticketDetails = response.data.ticket;
        // this.messageBoxDialog = true;
        this.mobileMessageDialog = true;
        EventBus.$emit("open-chat-dialog", this.ticketDetails);
        this.mobileTicket = this.ticketDetails;
      } catch (error) {
        console.log("error in retrieving data", error.message);
      }
    },
    async openFilesDialog(ticket) {
      try {
        if (ticket.isSeen == false || ticket.unreadBy[this.user._id] > 0) {
          this.$store
            .dispatch("ticket/setTicketAsReadable", ticket, this.page)
            .then((res) => (this.tickets = res.data.tickets))
            .catch((e) => console.log(e, "er1"));
        }
        let response = await ApiService.GetRequest(
          UrlServices.getTicketDetails + ticket._id
        );
        if (response.data && response.data.ticket)
          this.ticketDetails = response.data.ticket;
        this.filesDialog = true;
      } catch (error) {
        console.log("error in retrieving data", error.message);
      }
    },
    async openMobileFilesDialog(ticket) {
      try {
        if (ticket.isSeen == false || ticket.unreadBy[this.user._id] > 0) {
          this.$store
            .dispatch("ticket/setTicketAsReadable", ticket, this.page)
            .then((res) => (this.tickets = res.data.tickets))
            .catch((e) => console.log(e, "er1"));
        }
        // let response = await ApiService.GetRequest(
        //   UrlServices.getTicketDetails + ticket._id
        // );
        let response = await ApiService.GetRequest(
          UrlServices.getTicketDetails + ticket._id
        );

        if (response.data && response.data.ticket)
          this.ticketDetails = response.data.ticket;
        this.mobileFilesDialog = true;
      } catch (error) {
        console.log("error in retrieving data", error.message);
      }
    },
    getTickets(pgNo) {
      this.loader = true;
      this.$store.dispatch("ticket/getTasks", pgNo).then((res) => {
        this.tickets = res.data.tickets;
        this.loader = false;
      });
    },
    getTicketsbyStatus() {
      this.$store.commit("ticket/setStatus", this.status);
      this.getTickets(null);
    },
    resetSomeData() {
      this.$store.commit("ticket/setStatus", "All");
      this.$store.commit("ticket/setPaginate", {
        itemsPerPage: 10,
        page: 1,
        lastPage: 10,
        total: 100,
      });
    },
    reRouteToXeroLink(ticket) {
      if (ticket.xeroTicketUrl) window.open(ticket?.xeroTicketUrl, "_blank");
    },
  },
};
</script>

<style lang="scss">
.create-btn {
  min-width: 150px !important;
}

.v-badge__badge {
  border-radius: 10px;
  color: #ffffff;
  display: inline-block;
  font-size: 9px;
  height: 13px;
  letter-spacing: 0;
  line-height: 1;
  padding: 2px 4px;
  min-width: 5px;
}
</style>

<style scoped>
:deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  font-size: 12px !important;
  font-weight: 400;
}

.pagination-border {
  border-top: 1px solid #d9d9d9;
  height: 58px;
}

.custom-btn {
  background-color: rgba(53, 16, 167, 0.05) !important;
  border-radius: 8px;
  min-height: 35px;
  line-height: 19px;
  color: var(--v-lightPurple-base) !important;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  margin-left: 10px;
}

.custom-cancel-btn {
  border: 1px solid var(--v-borderColor-base);
  background-color: white !important;
  border-radius: 8px;
  min-height: 35px;
  line-height: 19px;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  margin-left: 10px;
}

.theme--light.v-data-table.v-data-table--fixed-header thead th {
  box-shadow: none !important;
}

:deep .settings-checkbox label {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 1) !important;
}

.settings {
  width: 342px;
  height: 480px;
  background-color: white;
}

.border-b {
  border-bottom: 1px solid #d9d9d9;
}
::v-deep .v-chip.v-size--small {
  border-radius: 6px !important;
  justify-content: center !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 0px !important;
  border-radius: 8px !important;
}

.v-chip-group .v-slide-group__content {
  padding: 0px !important;
}

.w-100 {
  width: 100% !important;
}
</style>
